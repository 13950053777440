import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

export default function LinearProgressWithLabel(props) {
  const theme = createMuiTheme({
    palette: {
      primary: {
        500: '#5c2483',
      },
    },
  });

  const styles = {
    linearProgress: {
      borderRadius: 12,
      height: 5
    },
    label: {
      fontSize: 12,
      fontFamily: 'Sofia-Pro',
      color: '#000000'
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress style={styles.linearProgress} variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" style={styles.label}>
          {props.value}
          {'%'}
        </Typography>
      </Box>
    </Box>
    </MuiThemeProvider>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};
