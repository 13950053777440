import React from 'react';

import { Button, Grid, Hidden } from '@material-ui/core';

import Title from '../../../../components/Title';

import styles from './styles.module.css';

import { ReactComponent as PdfIcon } from '../../../../assets/pdfIcon.svg';
import { ReactComponent as BarCodeIcon } from '../../../../assets/barcode.svg';

function Header({
  onClickBankTicketDownload,
  onClickNFeDownload,
}) {
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.headerContainer}
    >
      <Grid item xs={12} sm={12} md={6} lg={6} >
        <Title>Detalhes da fatura</Title>
      </Grid>

      <Grid item justifyContent='flex-end'>
        <Hidden xsDown>
          <Button
            startIcon={<BarCodeIcon className={styles.buttonIcon} />}
            onClick={onClickBankTicketDownload}
          >
            Baixar boleto
          </Button>
        </Hidden>

        <Hidden xsDown>
          <Button
            startIcon={<PdfIcon className={styles.buttonIcon} />}
            onClick={onClickNFeDownload}
          >
            Baixar Nota Fiscal
          </Button>
        </Hidden>
      </Grid>
    </Grid>
  );
}

export default Header;
