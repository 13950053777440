import React from 'react';
import Lottie from 'react-lottie';

import animation from '../../lotties/somapayLogo.json';

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function LogoLoading() {
  return (
    <div style={{ marginTop: '19vh' }}>
      <Lottie
        options={animationOptions}
        width={200}
        height={200}
      />
    </div>
  );
}

export default LogoLoading;
