import React from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
  TextField,
  LinearProgress,
} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import styles from './styles.module.css';

import { isMobile } from '../../utils/breakpoints';
import formatOnlyNumbers from '../../utils/formatOnlyNumbers';
import Form from '../Form';

function PrimaryModal({
  open,
  onCloseDialog,
  title,
  text,
  confirmButtonText,
  cancelButtonText,
  onConfirmClicked,
  onCancelButtonClicked,
  loading,
  progressLoading,
  progress,
  textMarginBottom,
  codeValue,
  codeVerification,
  handleChangeCodeValue,
  errorMessage,
  pinOnConfirmPayroll,
  sendPinStatus,
  countdownTime,
  countdownHasStarted,
  handleTrySendCodeBySms,
  maxWidth = 'xs',
  expressPayment,
}) {
  const useStyles = makeStyles({
    root: {
      minWidth: isMobile ? '140px' : '213px',
      position: 'relative',
      bottom: '38px',
      marginBottom: '-50px',
    },
  });

  const StyledLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: '#EF8E74',
      height: 38,
      borderRadius: 6,
    },
    barColorPrimary: {
      backgroundColor: '#E54217',
      height: 38,
      borderRadius: '4px 0px 0px 4px',
    },
  })(LinearProgress);

  function ProgressBar({ progress }) {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <StyledLinearProgress variant="determinate" value={progress} />
      </div>
    );
  }

  return (
    <Dialog open={open} onClose={onCloseDialog} fullWidth maxWidth={maxWidth}>
      <div className={styles.container}>
        <Typography className={styles.title}>{title}</Typography>

        <Box mb={textMarginBottom}>
          <Typography
            className={progressLoading ? styles.textProgress : styles.text}
          >
            {text}
          </Typography>
        </Box>

        {codeVerification && pinOnConfirmPayroll && (
          <Box mb={5}>
            <Typography className={styles.bigText}>
              {sendPinStatus === 'SMS_SENT'
                ? 'Digite o código enviado por SMS.'
                : false}
              {sendPinStatus === 'EMAIL_SENT'
                ? 'Digite o código enviado para seu e-mail.'
                : false}
            </Typography>
            <TextField
              value={formatOnlyNumbers(codeValue)}
              placeholder="Código"
              variant="outlined"
              fullWidth
              onChange={handleChangeCodeValue}
              disabled={progressLoading}
              inputProps={{ maxLength: 6 }}
            />
            <Typography className={styles.helperText}>
              {sendPinStatus === 'SMS_SENT'
                ? 'Não recebeu o SMS? Tente realizar novamente a autorização.'
                : false}
              {sendPinStatus === 'EMAIL_SENT'
                ? 'Não recebeu o e-mail? Verifique as pastas Spam e Lixeira'
                : false}
            </Typography>

            <Button
              fullWidth
              color="primary"
              style={{ marginTop: '2rem' }}
              onClick={handleTrySendCodeBySms}
              disabled={countdownHasStarted}
            >
              {countdownHasStarted
                ? `Enviar código por SMS em: ${countdownTime}`
                : 'Enviar código por SMS'}
            </Button>

            {errorMessage && (
              <Typography className={styles.errorText}>
                {errorMessage}
              </Typography>
            )}

            {expressPayment}
          </Box>
        )}

        <div className={styles.buttonsGroup}>
          {cancelButtonText && (
            <Button
              onClick={onCancelButtonClicked}
              className={styles.closeButton}
              disabled={progressLoading}
              style={progressLoading ? { opacity: 0.6 } : null}
            >
              {cancelButtonText}
            </Button>
          )}

          <Button
            onClick={onConfirmClicked}
            className={styles.confirmButton}
            disabled={
              progressLoading ||
              (pinOnConfirmPayroll &&
                (!codeValue || (codeVerification && codeValue?.length < 4)))
            }
          >
            {loading ? (
              <CircularProgress className={styles.loader} />
            ) : (
              <Typography className={styles.confirmButtonText}>
                {confirmButtonText}
              </Typography>
            )}
          </Button>
        </div>

        {progressLoading ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ProgressBar progress={progress} />
          </div>
        ) : null}
      </div>
    </Dialog>
  );
}

export default PrimaryModal;
