import React from 'react';

import { Button, Typography } from '@material-ui/core';

import styles from './styles.module.css';

function WelcomePOCard({
  welcomeImage,
  title,
  subTitle,
  lastCard,
  onConfirmClicked,
}) {
  return (
    <div className={styles.cardContainer}>
      {welcomeImage}
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.subtitle}>{subTitle}</Typography>
      {lastCard && (
        <Button onClick={onConfirmClicked} className={styles.button}>
          Pronto
        </Button>
      )}
    </div>
  );
}

export default WelcomePOCard;
