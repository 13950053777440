import React from 'react';

import {
  MuiThemeProvider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import { FakeTable, EnhancedTableHead } from './UI';

import { ReactComponent as DeleteIcon } from '../../../../assets/removeItem.svg';
import textCapitalize from '../../../../utils/textCapitalize';

import { useStyles, theme } from './styles';

const TablePointOnlineEmployeesList = ({
  loading,
  pageSize,
  totalSize,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  handleRequestSort,
  rows,
  stableSort,
  getComparator,
  TablePaginationActions,
  handleOpenModalDeleteEmployee,
}) => {
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          />
          <TableBody>
            {!loading ? (
              stableSort(rows, getComparator).map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1}>
                    <TableCell
                      scope="row"
                      padding="none"
                      align="left"
                      className={classes.defaultCell}
                      key={row.code}
                    >
                      {row?.companies[0]?.registration}
                    </TableCell>
                    <TableCell align="left" className={classes.defaultCell}>
                      {row?.cpf}
                    </TableCell>
                    <TableCell align="left" className={classes.defaultCell}>
                      {row?.pontoGoRegisterDate
                        ? moment(row?.pontoGoRegisterDate).format('L')
                        : '-'}
                    </TableCell>
                    <TableCell align="left" className={classes.defaultCell}>
                      {row?.pontoGoStatus ? row?.pontoGoStatus : '-'}
                    </TableCell>
                    <TableCell align="left" className={classes.defaultCell}>
                      {row?.pontoGoCancelationDate
                        ? moment(row?.pontoGoCancelationDate).format('L')
                        : '-'}
                    </TableCell>
                    <TableCell align="left" className={classes.defaultCell}>
                      {textCapitalize(row.name)}
                    </TableCell>
                    <TableCell align="right" className={classes.defaultCell}>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {row?.pontoGoStatus === 'Ativo' ? (
                          <IconButton
                            onClick={() =>
                              handleOpenModalDeleteEmployee(row.cpf)
                            }
                          >
                            <Tooltip title="Cancelar colaborador do ponto">
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        ) : (
                          ''
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <FakeTable />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalSize}
                rowsPerPage={pageSize}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  );
};

export default TablePointOnlineEmployeesList;
