import peoplesAnimation from '../lotties/peoples.json';

const configsLottie = {
  loop: false,
  autoplay: true,
  animationData: peoplesAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export { configsLottie };
