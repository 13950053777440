import React from 'react';
import { Typography } from '@material-ui/core';
import currencyFormatter from '../../../../../../utils/currencyFormatter';

import Checkbox from '../../../../../../components/Checkbox/PurpleCheckbox';

import styles from './styles.module.css';

function StepOne({ valuePlanPayment, isChecked, onChangeCheckbox }) {
  return (
    <div className={styles.containerStepOne}>
      <Typography variant="h6" className={styles.title}>
        Ativação Ponto Online
      </Typography>
      <Typography variant="h6" className={styles.subtitle}>
        Entenda as principais informações:
      </Typography>
      <Typography variant="h6" className={styles.titleInfo}>
        • Plano de serviços com pagamento pós-pago;
      </Typography>
      <Typography variant="h6" className={styles.titleInfo}>
        • Cobrança da tarifa via boleto bancário;
      </Typography>
      <Typography variant="h6" className={styles.titleInfo}>
        • Será cobrado valor fixo de{' '}
        <span className={styles.value}>
          {valuePlanPayment
            ? `${currencyFormatter(valuePlanPayment)}*`
            : 'nenhuma até o momento*'}
        </span>{' '}
        por funcionário;
      </Typography>
      <Typography variant="h6" className={styles.extraTitle}>
        *Tarifa exclusiva por funcionário ativo do mês faturado.
      </Typography>
      <div className={styles.checkContainer}>
        <div className={styles.check}>
          <Checkbox checked={isChecked} onChange={onChangeCheckbox} />
          <Typography className={styles.checkText}>
            Li e aceito o{' '}
            <a
              href="https://somapay.com.br/termos/contrato_ponto_online_PJ.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contrato Ponto Online Somapay.
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default StepOne;
