import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  RadioGroup,
  FormControlLabel,
  Hidden,
  Typography,
  withStyles,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import AddIcon from '@material-ui/icons/PlaylistAdd';
import { api } from '../../../services/api';
import ProgressToolBar from '../../../components/ProgressToolBar';
import { styles, theme } from './styles';
import style from './style.module.css';
import LogoLoading from '../../../components/LogoLoading';
import SuccessAlert from './UI/SucccessAlert';
import ModalAddAccount from './UI/ModalAddAccount';
import ModalTedData from './UI/ModalTedData';

import { ReactComponent as DollarSign } from '../../../assets/DollarSign.svg';

import { isMobile } from '../../../utils/breakpoints';

import EmptyList from '../../../components/EmptyList';

const PurpleRadio = withStyles({
  root: {
    color: '#6200EE',
    '&$checked': {
      color: '#6200EE',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const NewBillingDeposit = () => {
  const [openModalAddAccount, setOpenModalAddAccount] = useState(false);
  const [openModalTed, setOpenModalTed] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [dataTed, setDataTed] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jumpingLoading, setJumpingLoading] = useState(false);
  const [value, setValue] = useState([]);
  const [accountsList, setAccountsList] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const history = useHistory();

  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const companyCodeValueStorage = sessionStorage.getItem('currentCompanyCode');
  const companyCode = companyCodeValueStorage || companies[0].code;

  const editDepositInformData = JSON.parse(
    sessionStorage.getItem('editDepositInform'),
  );
  const originId = editDepositInformData ? editDepositInformData.origin.id : '';

  useEffect(() => {
    getAccountsList();
    sessionStorage.removeItem('accountDataTed');

    if (
      window.history.state?.state?.byScreen === undefined ||
      !window.history.state?.state?.byScreen
    ) {
      history.push('/');
    }
  }, []);

  const getAccountsList = () => {
    setJumpingLoading(true);
    api
      .get(`/accountCashIn/${companyCode}`)
      .then(({ data }) => {
        setAccountsList(data.content);

        if (editDepositInformData) {
          const list = data.content;
          const item = list.find(item => item.id === originId);

          if (item) {
            sessionStorage.setItem('accountDataTed', JSON.stringify([item]));
            setSelectedBank(item.bankCode);
            setValue(originId.toString());
          }
        }
      })
      .finally(() => {
        setJumpingLoading(false);
        setLoading(false);
      });
  };

  const onNextClicked = () => {
    history.push('/cash-in/new/billing/ted-set-data');
  };

  const onBackClicked = () => {
    history.push(`/cash-in`);
  };

  const handleClickCard = item => {
    sessionStorage.setItem('accountDataTed', JSON.stringify([item]));
    setSelectedBank(item.bankCode);
    setValue(item.id.toString());

    if (isMobile) {
      sessionStorage.setItem(
        'useTopBar',
        JSON.stringify({
          name: 'Registro de transferência',
          step: 2,
          route: history.location.pathname,
        }),
      );
      onNextClicked();
    }
  };

  const handleConfirm = () => {
    setOpenModalAddAccount(false);
    setShowSuccessAlert(true);
    getAccountsList();
    setTimeout(() => setShowSuccessAlert(false), 3000);
  };

  const handleTEDConfirm = () => {
    setOpenModalTed(false);
  };

  const handleBankAccountList = accountsList =>
    accountsList.length > 0 ? (
      <div className={style.listBank}>
        <Hidden xsDown>
          <div className={style.listBankTitle}>
            <Typography className={style.biggerTextStyle}>
              Contas cadastradas
            </Typography>
            <Button
              variant="outlined"
              className={style.listBankTitleButton}
              startIcon={<AddIcon />}
              onClick={() => setOpenModalAddAccount(true)}
            >
              Adicionar uma conta
            </Button>
          </div>
        </Hidden>

        <Hidden smUp>
          <div className={style.listBankTitle}>
            <Button
              variant="outlined"
              className={style.listBankTitleButton}
              startIcon={<AddIcon />}
              onClick={() => setOpenModalAddAccount(true)}
            >
              Nova conta
            </Button>
          </div>
        </Hidden>

        <div className={style.formGroup}>
          {accountsList.map(item => (
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              className={style.radioGroupStyle}
            >
              <Card
                className={
                  item.id === value ? style.cardActiveStyle : style.cardStyle
                }
                key={item.id}
                onClick={() => handleClickCard(item)}
              >
                <div className={style.cardContent}>
                  <Hidden xsDown>
                    <FormControlLabel
                      value={item.id.toString()}
                      control={<PurpleRadio />}
                      disabled={loading}
                    />
                  </Hidden>
                  <div className={style.cardContentInfo}>
                    <Typography className={style.cardContentTitle}>
                      {item.bankName}
                    </Typography>
                    <Typography className={style.cardContentTitleText}>
                      Nome: {item.name}
                    </Typography>
                    <div className={style.cardContentBox}>
                      <Typography className={style.cardContentText}>
                        C.C.: {item.account}
                      </Typography>
                      <Typography className={style.cardContentText}>
                        Agência: {item.agency}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Card>
            </RadioGroup>
          ))}
        </div>
      </div>
    ) : (
      <EmptyList
        icon={<DollarSign width={120} height={120} />}
        title="Nenhuma conta cadastrada"
        description="Cadastre uma conta e agilize seus depósitos por transferência."
        buttonText="Adicionar nova conta"
        onClick={() => setOpenModalAddAccount(true)}
      />
    );

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <div>
          {jumpingLoading ? (
            <LogoLoading />
          ) : (
            <>
              <Hidden xsDown>
                <div className={style.titleArea}>
                  <Typography style={styles.titleStyle}>
                    Novo registro de transferência (TED)
                  </Typography>
                  <Typography style={styles.subtitleStyle}>
                    Escolha a conta que será utilizada para fazer a
                    transferência.
                  </Typography>
                </div>
              </Hidden>
              {handleBankAccountList(accountsList)}
              <Hidden xsDown>
                <ProgressToolBar
                  loading={loading}
                  numberOfSteps={0}
                  currentStep={0}
                  onBackClicked={onBackClicked}
                  onNextClicked={onNextClicked}
                  nextButtonText="Continuar"
                  backButtonText="Voltar"
                  disabled={!selectedBank}
                />
              </Hidden>
            </>
          )}
        </div>

        <ModalAddAccount
          open={openModalAddAccount}
          onClose={() => setOpenModalAddAccount(false)}
          handleConfirm={handleConfirm}
        />
        <ModalTedData
          open={openModalTed}
          onClose={() => setOpenModalTed(false)}
          handleConfirm={() => handleTEDConfirm()}
          rows={dataTed}
        />
        {showSuccessAlert && (
          <SuccessAlert message="Conta incluída com sucesso." />
        )}
      </MuiThemeProvider>
    </div>
  );
};

export default NewBillingDeposit;
