const styles = {
  marginStyle: {
    marginLeft: '9px',
  },
  alertStyle: {
    color: '#1A1A1A',
    fontFamily: 'DM Sans',
  },
  paidStyle: {
    color: '#1A1A1A',
    fontFamily: 'DM Sans',
  },
  confirmedStyle: {
    color: '#1A1A1A',
    fontFamily: 'DM Sans',
  },
  cancelledStyle: {
    color: '#1A1A1A',
    fontFamily: 'DM Sans',
  },
  inProcessVerificationStyle: {
    color: '#1A1A1A',
    fontFamily: 'DM Sans',
  },
  waitingStyle: {
    color: '#1A1A1A',
  },
  displayStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButtonOverduePayment: {
    opacity: '.3',
    cursor: 'initial',
  },
  blueIcon: {
    color: '#2D54B9',
    width: 20,
    height: 20,
  },
  orangeIcon: {
    color: '#E54217',
    width: 20,
    height: 20,
  },
  greenIcon: {
    color: '#19797F',
    width: 20,
    height: 20,
  },
  tableHeadLabel: {
    fontSize: 14,
    fontWeight: 500,
  },
};

export default styles;
