import React from 'react';
import Lottie from 'react-lottie';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import ModalErrorPlanActive from '../../../../components/PrimaryModal';
import ModalErrorRedirectUrlPontoGO from '../../../../components/PrimaryModal';
import ModalNotPermission from '../../../../components/PrimaryModal';

import ModalOnboardingPlanActive from '../ModalOnboardingPlanActive';

import { isMobile } from '../../../../utils/breakpoints';
import { configsLottie } from '../../../../mocks/configsLottie';
import styles from './styles.module.css';

function WrapperResumePlan({
  situationPlan,
  modalActivePlan,
  handleCloseModalSucess,
  errorActivePlan,
  setErrorActivePlan,
  handleManagePointOnline,
  loadingManagePointOnline,
  errorRedirectUrlPontoGO,
  setErrorRedirectUrlPontoGO,
  handleClosedStepModal,
  nameUser,
  valuePlanPayment,
  textFieldFocusedChargeEmail,
  setTextFieldFocusedChargeEmail,
  textFieldFocusedAccessEmail,
  setTextFieldFocusedAccessEmail,
  isChecked,
  onChangeCheckbox,
  handleOpenModalActivePlan,
  modalNotPermission,
  setModalNotPermission,
}) {
  return (
    <div className={styles.container}>
      {situationPlan ? (
        <>
          <div className={styles.logoArea}>
            <Lottie
              options={configsLottie}
              width={288}
              height={188}
              style={{ marginBottom: 42 }}
            />
          </div>
          <div className={styles.containerInfo}>
            <Typography className={styles.titleInfo}>
              Ponto online está ativo!
            </Typography>

            <Typography className={styles.subTitleInfo}>
              Para começar, clique em <br />
              gerenciar ponto online!
            </Typography>
            {isMobile && (
              <Button
                className={styles.buttonInfo}
                onClick={handleManagePointOnline}
              >
                {loadingManagePointOnline ? (
                  <CircularProgress
                    size={16}
                    className={styles.circularLoadingStyle}
                  />
                ) : (
                  <span>Gerenciar ponto online</span>
                )}
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className={styles.containerPlanInactive}>
          <InfoOutlinedIcon className={styles.iconInfo} />
          <Typography className={styles.titlePlanInactive}>
            Seu plano do ponto precisa ser ativado
          </Typography>

          <Typography className={styles.subTitlePlanInactive}>
            Para ativar seu plano, é necessário que você <br />
            realize o pagamento da assinatura.
          </Typography>

          <Button
            className={styles.activateButton}
            onClick={handleOpenModalActivePlan}
          >
            <span>Ativar agora</span>
          </Button>
        </div>
      )}

      <ModalErrorPlanActive
        open={!!errorActivePlan}
        title="Atenção!"
        text={
          !!errorActivePlan && errorActivePlan[0].errorCode === 99
            ? 'Empresa não consta email, por favor verificar seus dados.'
            : !!errorActivePlan &&
              errorActivePlan[0].errorReason === 'COMPANY_PHONE_NUMBER_IS_BLANK'
            ? 'Empresa não consta telefone, por favor verificar seus dados.'
            : 'Empresa já cadastrada no ponto online.'
        }
        confirmButtonText="Entendi"
        onCloseDialog={() => setErrorActivePlan(false)}
        onConfirmClicked={() => setErrorActivePlan(false)}
      />

      <ModalErrorRedirectUrlPontoGO
        open={!!errorRedirectUrlPontoGO}
        title="Atenção!"
        text={
          errorRedirectUrlPontoGO &&
          errorRedirectUrlPontoGO[0]?.errorReason === 'USER_NOT_AUTHORIZED'
            ? 'Apenas o gestor do Ponto Online informado no momento da ativação do produto poderá acessar a área de gestão.'
            : 'Não foi possivel autenticar sua empresa para o gerenciamento de ponto, tente novamente.'
        }
        confirmButtonText="Entendi"
        onCloseDialog={() => setErrorRedirectUrlPontoGO(false)}
        onConfirmClicked={() => setErrorRedirectUrlPontoGO(false)}
      />

      <ModalOnboardingPlanActive
        open={modalActivePlan}
        handleCloseModalSucess={handleCloseModalSucess}
        cancelButtonText="Fechar"
        confirmButtonText="Próximo"
        onCancelButtonClicked={handleClosedStepModal}
        nameUser={nameUser}
        valuePlanPayment={valuePlanPayment}
        textFieldFocusedChargeEmail={textFieldFocusedChargeEmail}
        setTextFieldFocusedChargeEmail={setTextFieldFocusedChargeEmail}
        textFieldFocusedAccessEmail={textFieldFocusedAccessEmail}
        setTextFieldFocusedAccessEmail={setTextFieldFocusedAccessEmail}
        isChecked={isChecked}
        onChangeCheckbox={onChangeCheckbox}
      />

      <ModalNotPermission
        open={modalNotPermission}
        title="Atenção!"
        text="Apenas o Administrador possui permissão para ativar o produto Ponto Online."
        confirmButtonText="Entendi"
        onCloseDialog={() => setModalNotPermission(false)}
        onConfirmClicked={() => setModalNotPermission(false)}
      />
    </div>
  );
}

export default WrapperResumePlan;
