import React, { useState, useCallback } from 'react';
import styles from './styles.module.css';
import { useHistory } from 'react-router-dom';
import {
    Typography,
    Button,
    CircularProgress,
  } from '@material-ui/core';
import ProgressToolBar from '../../../../components/ProgressToolBar';
import { ReactComponent as IconFile } from '../../../../assets/fileText.svg';
import PublishIcon from '@material-ui/icons/Publish';
import { useDropzone } from 'react-dropzone';

export default function ImportFile() {
    const history = useHistory();
    const [file, setFile] = useState([]);
    const [fileName, setFileName] = useState('');
    const [loading, setLoading] = useState(false);

    const onNextClicked = () => {
      setLoading(true);
      setTimeout(() => history.push(`/payroll/register-erp-employee-list`), 3000);
    }

    const onBackClicked = () => history.push(`/payroll/menu`);

    const onRemoveFileClicked = () =>  setFile([]);

    const onDrop = useCallback(acceptedFiles => {
      const value = acceptedFiles[0].name.substring(
        acceptedFiles[0].name.lastIndexOf('.') + 1
      );

      if (value === 'xls' || value === 'xlsx') {
        setFile(acceptedFiles);
        setFileName(acceptedFiles[0].name);
      }
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <div>
            <div>
                <Typography className={styles.textBigger}>Cadastrar via arquivo ERP Somapay</Typography>
            </div>
            <div>
                <Typography className={styles.textMedium}>Os funcionários serão cadastrados nesta empresa:</Typography>
                <div className={styles.companyData}>
                    <div>
                        <Typography className={styles.label}>Matrícula</Typography>
                        <Typography className={styles.textSmall}>803692</Typography>
                    </div>
                    <div>
                        <Typography className={styles.label}>Grupo Empresarial</Typography>
                        <Typography className={styles.textSmall}>Atitude Serviços</Typography>
                    </div>
                    <div>
                        <Typography className={styles.label}>Filial</Typography>
                        <Typography className={styles.textSmall}>Aldeota</Typography>
                    </div>
                </div>
            </div>
            <div>
                <Typography className={styles.textMedium}>Em seguida, envie o arquivo preenchido.</Typography>
                <Typography className={styles.textSmall}>Com o arquivo preenchido, envie o arquivo utilizando o botão abaixo</Typography>
                <Button
                  {...getRootProps()}
                  className={file.length === 0 ? styles.importButton : styles.fileDropzone}
                >
                  {
                    isDragActive ?
                    <Typography>Soltar</Typography> :
                      (file.length === 0 ? (
                        <div>
                          <input {...getInputProps()} />
                          <Typography>
                            <PublishIcon className={styles.iconButton} /> Importar arquivo
                          </Typography>
                        </div>
                      ) : (
                        loading ? (
                          <div className={styles.loadingContainer}>
                            <CircularProgress style={{ margin: '0 auto' }} />
                            <Typography className={styles.loadingText}>
                              Estamos lendo seu arquivo... Por favor, aguarde alguns instantes.
                            </Typography>
                          </div>
                        ) : (
                          <div className={styles.fileDropzoneContainer}>
                            <div className={styles.fileDropzoneName}>
                              <IconFile className={styles.iconFile}/>
                              <Typography style={{ fontSize: 18 }}>{fileName}</Typography>
                            </div>
                            <Button
                              className={styles.fileDropzoneRemoveFle}
                              onClick={onRemoveFileClicked}
                            >
                              Remover
                            </Button>
                          </div>
                        )
                      ))
                  }
                </Button>
                <Typography className={styles.label}>
                    Você pode arrastar ou clicar na caixa para selecionar o arquivo.
                </Typography>
            </div>
            <ProgressToolBar
                numberOfSteps={3}
                currentStep={1}
                onBackClicked={onBackClicked}
                onNextClicked={onNextClicked}
                nextButtonText="processar"
                disabled={file.length === 0}
            />
        </div>
    )
}
