import React, { useState } from 'react';
import styles from './styles.module.css';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ProgressToolBar from '../../../../components/ProgressToolBar';
import Accordion from './UI/Accordion';
import AccordionError from './UI/AccordionError';
import ModalConfirm from './UI/ModalConfirm';

const employees = [
  'Ana Lúcia',
  'Videira da Silva',
  'Maria Luzanira',
  'Gilberto Moraes',
  'Francisco Anderson',
  'Ana Lúcia',
  'Videira da Silva',
  'Maria Luzanira',
  'Gilberto Moraes',
  'Francisco Anderson'
]

const objectError = [
  'Nome Completo em branco - coluna x',
  'Telefone em branco - coluna x',
  'CPF com quantidade de caracteres errada - coluna x'
]

export default function EmployeeList() {
    const [modalConfirm, setModalConfirm] = useState(false);
    const [payrollError, setPayrollError] = useState(false);
    const history = useHistory();

    const onNextClicked = () => setModalConfirm(true);

    const onBackClicked = () => history.push(`/payroll/register-erp-file`);

    const requestCards = () => history.push(`/payroll/register-erp-success`);

    return (
        <div className={styles.container}>
            <div>
                <Typography className={styles.textBigger}>Cadastrar via arquivo ERP Somapay</Typography>
            </div>
            <div>
                <Typography className={styles.textMedium}>Identificamos {employees.length} registro(s):</Typography>
                {
                  payrollError && (
                    <AccordionError
                      title="Funcionários Identificados com sucesso"
                      objectError={objectError}
                    />
                  )
                }
                <Accordion
                    title="Funcionários Identificados com sucesso"
                    employees={employees}
                />
            </div>
            <ModalConfirm
                open={modalConfirm}
                onCloseDialog={() => setModalConfirm(false)}
                requestCards={requestCards}
                title={
                  payrollError
                  ? 'Tem certeza que deseja ignorar os erros'
                  : 'Deseja confirmar a solicitação de cartões?'
                }
                description={
                  payrollError
                  ? 'Se continuar agora, apenas os funcionários identificados corretamente terão seus cartões solicitados'
                  : 'Caso confirme a solicitação, os cartões serão produzidos para os funcionários que não possuem conta Somapay.'
                }
                alert={!payrollError && 'Atenção: essa alteração não poderá ser desfeita.'}
                textButtonConfirm={
                  payrollError
                  ? 'Salvar e solicitar cartões'
                  : 'Solicitar cartões'
                }
            />
            <ProgressToolBar
                numberOfSteps={3}
                currentStep={2}
                onBackClicked={onBackClicked}
                onNextClicked={onNextClicked}
                nextButtonText={payrollError ? 'Ignorar erros e continuar' : 'Salvar e solicitar cartões'}
            />
        </div>
    )
}
