const styles = {
  title: {
    fontWeight: 700,
    fontSize: 20,
    color: '#000000'
  },
  text: {
    marginTop: '-12px',
    fontSize: '14px',
    color: '#545255'
  },
  confirmButtonStyle: {
    color: '#FFFFFF',
    backgroundColor: '#E54217',
    marginRight: '15px',
    marginBottom: '10px',
    marginLeft: '10px'
  },
  disabledConfirmButtonStyle: {
    color: '#FFFFFF',
    background: '#E54217',
    marginRight: '15px',
    marginBottom: '10px',
    marginLeft: '10px',
    opacity: .3,
  },
  inputLabelStyle: {
    marginTop: '1em',
    marginBottom: '1em',
    fontSize: '18px',
    color: '#1B181C',
    letterSpacing: '0.1px',
    fontFamily: 'DM Sans',
  },
  circularLoadingStyle: {
    color: '#FFFFFF',
    width: '20px',
    height: '20px',
    marginLeft: '25px',
    marginRight: '25px',
  },
  errroMessage: {
    color: 'rgb(255, 42, 51)',
    marginTop: '18px',
    marginBottom: '-12px',
    padding: '5px 5px',
    borderRadius: '10px',
  },
  contentDialog: {
    overflow: 'hidden'
  },
  cancelButtonStyle: {
    marginBottom: 10
  }
};

export default styles;
