import React from 'react';
import Lottie from 'react-lottie';

import { Typography, Button } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { isMobile } from '../../../../utils/breakpoints';
import { configsLottie } from '../../../../mocks/configsLottie';
import styles from './styles.module.css';

function WrapperPlanActive({ situationPlan, handleAddEmployees }) {
  return (
    <div className={styles.container}>
      {situationPlan ? (
        <>
          <div className={styles.logoArea}>
            <Lottie
              options={configsLottie}
              width={288}
              height={188}
              style={{ marginBottom: 42 }}
            />
          </div>
          <div className={styles.containerInfo}>
            <Typography className={styles.titleInfo}>
              Ponto online está ativo!
            </Typography>

            <Typography className={styles.subTitleInfo}>
              Para começar, clique em incluir <br />
              funcionários!
            </Typography>
            {isMobile && (
              <Button
                className={styles.buttonInfo}
                onClick={handleAddEmployees}
              >
                <span>Incluir funcionários</span>
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className={styles.containerPlanInactive}>
          <InfoOutlinedIcon className={styles.iconInfo} />
          <Typography className={styles.titlePlanInactive}>
            Seu plano do ponto precisa ser ativado
          </Typography>

          <Typography className={styles.subTitlePlanInactive}>
            Para ativar seu plano, é necessário que você <br />
            realize o pagamento da assinatura.
          </Typography>

          <Button className={styles.activateButton} onClick={() => { }}>
            <span>Ativar agora</span>
          </Button>
        </div>
      )}
    </div>
  );
}

export default WrapperPlanActive;
