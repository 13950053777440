import React from 'react';

import { Box } from '@material-ui/core';

import Title from '../../../../components/Title';

export default function Header() {
  return (
    <Box mb={5}>
      <Title>
        Confirme o agendamento do novo pagamento
      </Title>
    </Box>
  )
}
