import React from 'react';
import { ReactComponent as Image1 } from '../assets/welcome-carousel-1.svg';
import { ReactComponent as Image2 } from '../assets/welcome-carousel-2.svg';
import { ReactComponent as Image3 } from '../assets/welcome-carousel-3.svg';
import { ReactComponent as Image4 } from '../assets/welcome-carousel-4.svg';
import { ReactComponent as Image5 } from '../assets/welcome-carousel-5.svg';
import { ReactComponent as Image6 } from '../assets/welcome-carousel-1.svg';
import { ReactComponent as Image7 } from '../assets/welcome-carousel-3.svg';

export const welcomeModalData = [
  {
    key: 1,
    title: 'Ponto Online',
    image: <Image1 />,
    subtitle: 'Solução para registro e controle de pontos.',
  },
  {
    key: 2,
    title: 'Acesse de qualquer lugar',
    image: <Image2 />,
    subtitle:
      'Acesse de onde estiver, pelo celular, tablet ou pela web para controlar os pontos.',
  },
  {
    key: 3,
    title: 'Seguro juridicamente',
    image: <Image3 />,
    subtitle:
      'Em concordância com as exigências do MTE e as portarias 1510 e 373 referentes as jornadas de trabalho.',
  },
  {
    key: 4,
    title: 'Acabe com as fraudes',
    image: <Image4 />,
    subtitle:
      'Verifique localização e horário dos pontos e acompanhe em tempo real as jornadas de trabalho.',
  },
  {
    key: 5,
    title: 'Relatórios completos',
    image: <Image5 />,
    subtitle:
      'Controle facilmente ajustes, férias, abonos e feriados, visualizando a folha de ponto em tempo real.',
  },
  {
    key: 6,
    title: 'Banco de horas',
    image: <Image6 />,
    subtitle:
      'Controle facilmente de forma simples e objetiva o banco de horas de seus colaboradores.',
  },
  {
    key: 7,
    title: 'Suporte online',
    image: <Image7 />,
    subtitle:
      'Tenha todo o apoio de nossa equipe de atendimento para tirar todas suas dúvidas.',
  },
];
