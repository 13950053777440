import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { api } from '../../services/api';
import { Header, ResumePlan, WrapperResumePlan } from './UI';

import LogoLoading from '../../components/LogoLoading';
import WelcomeModal from '../../components/WelcomeModal';

import { hasAuthorization, profiles } from '../../utils/hasAuthorization';
import { ActionsPointOnline } from '../../redux/store/PointOnline';

function OnlinePointPlan() {
  const dispatch = useDispatch();

  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));
  const userJobRole = JSON.parse(sessionStorage.getItem('userJobRole'));
  const isTutorialModalOpen = userJobRole?.viewedComponents?.some(
    item => item.component === 'BATCH_AUTHORIZATION_MODAL_ONLINEPOINT',
  );

  const { situationPointPlan, pontoGoActivationDate } = useSelector(
    state => state.situationPointPlan.data,
  );

  const companyCode = sessionStorage.getItem('currentCompanyCode');

  const [openTutorialModal, setOpenTutorialModal] = useState(
    !isTutorialModalOpen,
  );
  const [loading, setLoading] = useState(false);
  const [situationPlan, setSituationPlan] = useState(situationPointPlan);
  const [datePlan, setDatePlan] = useState(pontoGoActivationDate);
  const [valuePlanPayment, setValuePlanPayment] = useState(0);
  const [billingMethod, setBillingMethod] = useState('Pós-paga');
  const [modalActivePlan, setModalActivePlan] = useState(false);
  const [errorActivePlan, setErrorActivePlan] = useState(null);
  const [loadingManagePointOnline, setLoadingManagePointOnline] = useState(
    false,
  );
  const [errorRedirectUrlPontoGO, setErrorRedirectUrlPontoGO] = useState(null);
  const [
    textFieldFocusedChargeEmail,
    setTextFieldFocusedChargeEmail,
  ] = useState('');
  const [
    textFieldFocusedAccessEmail,
    setTextFieldFocusedAccessEmail,
  ] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [modalNotPermission, setModalNotPermission] = useState(false);

  useEffect(() => {
    getConfigCompany();
    getFeeOnlinePoint();
  }, []);

  function getConfigCompany() {
    setLoading(true);
    api
      .get(`companies/${companyCode}/search`)
      .then(({ data }) => {
        const pontoGoProduct = data?.pontoGoProduct;
        const pontoGoActivationDate = data?.pontoGoCompany
          ? data?.pontoGoCompany?.pontoGoActivationDate
          : data?.pontoGoCompany;

        const emailNfe = data?.generalData
          ? data.generalData?.emailNfe
          : data?.generalData;

        sessionStorage.setItem('pontoGoProduct', pontoGoProduct);
        sessionStorage.setItem('pontoGoActivationDate', pontoGoActivationDate);

        setSituationPlan(pontoGoProduct);
        setDatePlan(pontoGoActivationDate);

        dispatch(ActionsPointOnline.setEmailNfe(emailNfe));

        dispatch({
          type: 'SITUATION_POINT_PLAN',
          data: {
            situationPointPlan: pontoGoProduct,
            pontoGoActivationDate,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleManagePointOnline() {
    setLoadingManagePointOnline(true);

    api
      .get(`/pontoGO/authenticatedRoute/${companyCode}`)
      .then(({ data }) => {
        window.open(data?.redirectUrl);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorRedirectUrlPontoGO(errors);
        },
      )
      .finally(() => {
        setLoadingManagePointOnline(false);
      });
  }

  function getFeeOnlinePoint() {
    api.get(`/pontoGO/fee/${companyCode}`).then(({ data }) => {
      setValuePlanPayment(data?.value);
    });
  }

  function onConfirmTutorial() {
    const arr = userJobRole?.viewedComponents;
    const requestBody = {
      component: 'BATCH_AUTHORIZATION_MODAL_ONLINEPOINT',
    };

    api.post('auth/viewedComponents', requestBody).finally(() => {
      arr.push({ component: requestBody.component });
      sessionStorage.setItem('userJobRole', JSON.stringify(userJobRole));
      setOpenTutorialModal(false);
    });
  }

  function handleCloseModalSucess() {
    getFeeOnlinePoint();
    getConfigCompany();
    setSituationPlan(true);
    handleClosedStepModal();
  }

  function handleClosedStepModal() {
    setIsChecked(false);
    setModalActivePlan(false);
    dispatch(ActionsPointOnline.setStepToActivePlanModal('one'));
    dispatch(
      ActionsPointOnline.setResetErrorOnActivePlanPointOnline({
        hasError: false,
        message: '',
      }),
    );
  }

  function onChangeCheckbox() {
    setIsChecked(!isChecked);
  }

  function handleOpenModalActivePlan() {
    const permissionUser = hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN]);

    if (permissionUser) {
      setModalActivePlan(true);
    } else {
      setModalNotPermission(true);
    }
  }

  return (
    <>
      {loading ? (
        <LogoLoading />
      ) : (
        <>
          <Header
            loadingManagePointOnline={loadingManagePointOnline}
            handleManagePointOnline={handleManagePointOnline}
            situationPlan={situationPlan}
          />
          <ResumePlan
            situationPlan={situationPlan}
            datePlan={datePlan}
            valuePlanPayment={valuePlanPayment}
            billingMethod={billingMethod}
          />
          <WrapperResumePlan
            situationPlan={situationPlan}
            modalActivePlan={modalActivePlan}
            setModalActivePlan={setModalActivePlan}
            handleCloseModalSucess={handleCloseModalSucess}
            errorActivePlan={errorActivePlan}
            setErrorActivePlan={setErrorActivePlan}
            loadingManagePointOnline={loadingManagePointOnline}
            handleManagePointOnline={handleManagePointOnline}
            errorRedirectUrlPontoGO={errorRedirectUrlPontoGO}
            setErrorRedirectUrlPontoGO={setErrorRedirectUrlPontoGO}
            handleClosedStepModal={handleClosedStepModal}
            nameUser={userJobRole}
            valuePlanPayment={valuePlanPayment}
            textFieldFocusedChargeEmail={textFieldFocusedChargeEmail}
            setTextFieldFocusedChargeEmail={setTextFieldFocusedChargeEmail}
            textFieldFocusedAccessEmail={textFieldFocusedAccessEmail}
            setTextFieldFocusedAccessEmail={setTextFieldFocusedAccessEmail}
            isChecked={isChecked}
            onChangeCheckbox={onChangeCheckbox}
            handleOpenModalActivePlan={handleOpenModalActivePlan}
            modalNotPermission={modalNotPermission}
            setModalNotPermission={setModalNotPermission}
          />
          <WelcomeModal
            open={openTutorialModal}
            onConfirmClicked={onConfirmTutorial}
          />
        </>
      )}
    </>
  );
}

export default OnlinePointPlan;
