import React from 'react'
import Skeleton from 'react-loading-skeleton';

import { Grid, TableCell, TableRow } from '@material-ui/core';

export default function FakeRowsTable() {
	function renderFakeRow() {
		return (
			<>
				<TableRow>
					<TableCell component="th" scope="row">
						<Skeleton animation="wave" variant="text"/>
					</TableCell>

					<TableCell>
						<Skeleton animation="wave" variant="text"/>
					</TableCell>

					<TableCell>
						<Skeleton animation="wave" variant="text"/>
					</TableCell>

					<TableCell>
						<Skeleton animation="wave" variant="text"/>
					</TableCell>

					<TableCell>
						<Skeleton animation="wave" variant="text" />
					</TableCell>

					<TableCell>
						<Skeleton animation="wave" variant="text" />
					</TableCell>
					
					<TableCell>
						<Grid container direction="row" spacing={2} justifyContent="flex-end" >
							<Grid item style={{ marginRight: "1em"}}>
								<Skeleton variant="circular" width={24} height={24} />
							</Grid>
						</Grid>
					</TableCell>
				</TableRow>
			</>
		);
	}

	return (
		<>
			{renderFakeRow()}
			{renderFakeRow()}
			{renderFakeRow()}
			{renderFakeRow()}
			{renderFakeRow()}
			{renderFakeRow()}
			{renderFakeRow()}
			{renderFakeRow()}
			{renderFakeRow()}
			{renderFakeRow()}
		</>
	)
}