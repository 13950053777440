import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = {
  titleStyle: {
    fontSize: 34,
    fontWeight: 700,
    marginBottom: 40,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 20,
  },
  contentStyle: {
    marginBottom: 80
  },
  mobileContentStyle: {
    height: '40vh',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  biggerTextStyle: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-0.5px',
  },
  smallerTextStyle: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  buttonStyle: {
    height: '19vh',
    width: '100%',
  },
  padding: {
    padding: 10,
    marginTop: 10,
  },
  primaryTextSTyle: {
    fontSize: 18,
    fontWeight: 700,
    textTransform: 'none',
    marginBottom: 2
  },
  secondaryTextStyle: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textTransform: 'none',
  },
  radioButtonStyle: {
    color: '#6200EE',
  },
  loadingButtonStyle: {
    color: '#5C2483',
  },
  borderCard: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
    border: '1px solid #7500D1',
    borderRadius: 6,
    boxSizing: 'borderBox'
  },
  notBorderCard: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
    border: '1px solid transparent',
    borderRadius: 6,
    boxSizing: 'borderBox'
  },
  paper: {
    marginTop: 20,
    height: 121,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  card: {
    marginTop: 20,
    marginBottom: 20,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
    border: '1px solid #ffffff',
    borderRadius: 6,
    boxSizing: 'borderBox'
  },
};

export default styles;

export const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      display: 'flex',
      marginTop: '30px',
      marginLeft: '-22px',
      marginRight: '-22px',
      wordBreak: 'break-all',
    },
  })
);
