import React, { useEffect, useState } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  List,
  Typography,
} from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles.module.css';

import Checkbox from '../Checkbox/PurpleCheckbox';

import { isMobile } from '../../utils/breakpoints';
import currencyFormatter from '../../utils/currencyFormatter';
import FakeInformations from './FakeInformations';

const useStyles = makeStyles(() => ({
  paper: { minWidth: '726px' },
}));

function PrimaryModal({
  open,
  onConfirmClicked,
  loading,
  planInformations,
  loadingContract,
}) {
  const [isChecked, setIsChecked] = useState(false);

  const classes = useStyles();

  const {
    feeByEmployee,
    feeExpressPayment,
    planName,
    signatureAmount,
  } = planInformations;

  function onChangeCheckbox(event) {
    setIsChecked(!isChecked);
  }

  function isTherePlansValues() {
    if (
      signatureAmount === null ||
      feeByEmployee === null ||
      feeExpressPayment === null
    ) {
      return styles.descriptionContainerUndefinedPlan;
    }
    return styles.descriptionContainer;
  }

  function hideOrSeekContract() {
    if (
      signatureAmount === null ||
      feeByEmployee === null ||
      feeExpressPayment === null
    ) {
      return styles.contractTypeContainerNone;
    }
    return styles.contractTypeContainer;
  }

  useEffect(() => {
    isTherePlansValues();
    hideOrSeekContract();
  }, []);

  return (
    <Dialog
      open={open}
      classes={isMobile ? {} : { paper: classes.paper }}
      fullWidth
    >
      <div className={styles.container}>
        <Typography className={styles.title}>
          <WarningRoundedIcon className={styles.titleIcon} />
          Atualização do Contrato
        </Typography>

        {loadingContract ? (
          <FakeInformations />
        ) : (
          <div className={styles.cardsContainer}>
            <div className={isTherePlansValues()}>
              <Typography className={styles.descriptionTitle}>
                Entenda as principais alterações:
              </Typography>
              <Typography className={styles.descriptionText}>
                <List>• Plano de serviços com assinatura pré-paga;</List>
                <List>
                  • Cobrança da assinatura + tarifas via débito em conta ou
                  depósito; depósito;
                </List>
                <List>• Franquia de até 9 funcionários gratuitos*;</List>
                <List>
                  • Tarifa exclusiva por funcionário ativo no mês excedente a
                  franquia **;
                </List>

                <div className={styles.divider} />
                <span>
                  * Franquia mensal e não cumulativa.{' '}
                  <br className={styles.breakLine} />
                  ** Funcionário que recebe pagamento no mês não importanto a
                  quantidade de lançamentos.
                </span>
              </Typography>
            </div>

            <div className={hideOrSeekContract()}>
              <Typography className={styles.typeTitle}>
                {/* Plano <b>Clássico</b> */}
                {planName?.slice(0, 5)} <b>{planName?.slice(6)}</b>
              </Typography>

              <Typography className={styles.typeSubtitle}>
                Este plano inclui:
              </Typography>

              <Typography className={styles.optionsTitle}>
                Conta Pejota Somapay
              </Typography>

              <Typography className={styles.fontItems}>
                <List className={styles.listItems}>• Depósito via TED;</List>
                <List className={styles.listItems}>• Depósito via Boleto;</List>
                <List className={styles.listItems}>
                  • Gestão de Folha de Pagamento;
                </List>
                <Typography className={styles.fontItems}>
                  <List className={styles.listSubitems}>
                    ◦{' '}
                    <b>
                      {signatureAmount
                        ? currencyFormatter(signatureAmount)
                        : 'R$ 0,00'}
                    </b>{' '}
                    por mês.
                  </List>
                  <List className={styles.listSubitems}>
                    ◦{' '}
                    <b>
                      {feeByEmployee
                        ? currencyFormatter(feeByEmployee)
                        : 'R$ 0,00'}
                    </b>{' '}
                    por funcionário ativo adicional.
                  </List>
                  <List className={styles.listSubitems}>
                    ◦{' '}
                    <b>
                      {feeExpressPayment
                        ? currencyFormatter(feeExpressPayment)
                        : 'R$ 0,00'}
                    </b>{' '}
                    por liberação imedidata.
                  </List>
                </Typography>
              </Typography>

              <Typography className={styles.fontFooter}>
                Conta digital Somapay para colaboradores.
              </Typography>
            </div>
          </div>
        )}

        <div className={styles.checkContainer}>
          <Typography className={styles.checkContainerTitle}>
            Contrato de adesão
          </Typography>

          <div className={styles.check}>
            <Checkbox checked={isChecked} onChange={onChangeCheckbox} />
            <Typography className={styles.checkText}>
              Li e aceito o{' '}
              <a
                href="https://www.somapay.com.br/termos/convenio_de_adesao.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Convênio de Adesão ao Sistema Somapay.
              </a>
            </Typography>
          </div>
        </div>

        <div className={styles.buttonsGroup}>
          <Button
            onClick={onConfirmClicked}
            className={styles.confirmButton}
            disabled={!isChecked || loading}
          >
            {loading && isChecked ? (
              <CircularProgress className={styles.loader} />
            ) : (
              'Confirmar'
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default PrimaryModal;
