import React, { useState, useEffect } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import styles from './styles.module.css';

import ResultNotFound from '../../../../components/ResultNotFound';
import ErrorModal from '../../../../components/PrimaryModal';
import ModalAddEmployeeLoading from '../../../../components/ProgressModal';
import ModalMobileList from './UI/ModalMobileList';
import PisModal from './UI/PisModal';
import Snackbar from '../../../../components/Snackbar';

import { situationOfEmployees } from '../../../../mocks/situationOfEmployees';
import verifyNumberInString from '../../../../utils/verifyNumberInString';
import { pusherStatus } from '../../../../enums/pusher';
import { pusher } from '../../../../utils/pusher';
import { isMobile } from '../../../../utils/breakpoints';
import formatCPF from '../../../../utils/formatCPF';

import EmployeesTable from '../EmployeesTable';

import { api } from '../../../../services/api';

function EmployeesListModal({
  open,
  onClose,
  selected,
  setSelected,
  setOpenEmployeesListModal,
  getEmployeesOnlinePoint,
  isItemDeleted,
}) {
  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const [textFieldFocused, setTextFieldFocused] = useState('');

  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [employees, setEmployees] = useState([]);
  const [ignoredEmployees, setIgnoredEmployees] = useState([]);
  // const [isSelectAllClicked, setIsSelectAllClicked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [activeButton, setActiveButton] = useState(6);
  const [searchCostAndCenter, setSearchCostAndCenter] = useState('');
  const [searchSituation, setSearchSituation] = useState('');
  const [costAndCenter, setCostAndCenter] = useState([]);
  const [cpfOrName, setCpfOrName] = useState('');
  const [openFiredEmployeesModal, setOpenFiredEmployeesModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openModalError, setOpenModalError] = useState(false);
  const [channelData, setChannelData] = useState({});
  const [modalEmployeeLoading, setModalEmployeeLoading] = useState(false);
  const [modalPis, setModalPis] = useState(false);
  const [pisValue, setPisValue] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [savePisButtonLoading, setSavePisButtonLoading] = useState(false);
  const [snackbarPis, setSnackbarPis] = useState(false);
  const [journeyRules, setJourneyRules] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [journeyRulesSelected, setJourneyRulesSelected] = useState('');
  const [shiftSelected, setShiftSelected] = useState('');
  const [jobTitleSelected, setJobTitleSelected] = useState('');

  const {
    PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_SUCCESS,
    PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_ERROR,
    PROCESSING,
  } = pusherStatus;

  useEffect(() => {
    getEmployees();
  }, [
    page,
    rowsPerPage,
    cpfOrName,
    searchSituation,
    searchCostAndCenter,
    isItemDeleted,
  ]);

  useEffect(() => {
    getRules();
  }, []);

  useEffect(() => {
    getCostAndCenter();
  }, []);

  function getEmployees() {
    const isNumber = verifyNumberInString(cpfOrName);
    const requestOptions = {
      params: {
        page,
        size: rowsPerPage,
        cpf: isNumber ? cpfOrName : '',
        name: !isNumber ? cpfOrName : '',
        status: searchSituation,
        location: searchCostAndCenter,
      },
    };

    setIsLoading(true);

    api
      .get(`/employee/search/${currentCompanyCode}`, requestOptions)
      .then(({ data }) => {
        setTotalSize(data.totalSize);
        setEmployees(data.content);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getCostAndCenter() {
    api.get(`companies/${currentCompanyCode}/locations`).then(({ data }) => {
      setCostAndCenter(data);
    });
  }

  function handleFilterSituation(valueActiveButton, situationSearch) {
    setActiveButton(valueActiveButton);
    setSearchSituation(situationSearch);
  }

  function handleChangeCostAndCenter(costCenterSearch) {
    setSearchCostAndCenter(costCenterSearch);
  }

  function handleClearFilters() {
    setCpfOrName('');
    setSearchCostAndCenter('');
    setSearchSituation('');
    setActiveButton(6);
  }

  function handleChangeCpfOrName(event) {
    setCpfOrName(event.target.value);
  }

  function confirmIncludeEmployees() {
    onSubscribePusherChannel();
    setOpenModalError(false);

    const employeesSelecteds = selected.map(item => {
      return item?.cpf.toString().replace(/\.|-/gm, '');
    });

    const requestOptions = {
      params: {
        journeyRuleId: journeyRulesSelected,
        shiftId: shiftSelected,
        jobTittleId: jobTitleSelected,
      },
    };

    setModalEmployeeLoading(true);

    api
      .post(
        `/pontoGO/registerEmployee/${currentCompanyCode}`,
        employeesSelecteds,
        requestOptions,
      )
      .then(data => {
        getEmployees();
        setSelected([]);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setOpenModalError(true);
        },
      )
      .finally(() => {
        setOpenFiredEmployeesModal(false);
        setOpenEmployeesListModal(false);
      });
  }

  function verifyIncludeEmployees() {
    setChannelData({});
    setIsLoadingButton(true);
    onSubscribePusherChannel();

    const employeesSelecteds = selected.map(item => {
      return item?.cpf.toString().replace(/\.|-/gm, '');
    });

    api
      .post(
        `/pontoGO/registerEmployee/validate/${currentCompanyCode}`,
        employeesSelecteds,
      )
      .then(() => {
        setModalEmployeeLoading(true);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          if (errors[0].errorsDescription.some(value => value.isPis === true)) {
            setEmployeesList(errors[0].errorsDescription);
            setModalPis(true);
            setErrorMessage(errors);
            return;
          }
          setOpenModalError(true);
          setErrorMessage(errors);
        },
      )
      .finally(() => {
        setIsLoadingButton(false);
      });
  }

  function includeEmployees(selected) {
    if (selected.find(item => item.employeeStatus === 'Demitido')) {
      setOpenFiredEmployeesModal(true);
      return;
    }

    verifyIncludeEmployees();
  }

  function onSubscribePusherChannel() {
    const channel = pusher.subscribe(currentCompanyCode);

    channel.bind('PONTO_GO_EMPLOYEE_REGISTER', function(data) {
      setChannelData(data);
    });
  }

  function onCancelClicked() {
    setOpenEmployeesListModal(false);
  }

  function handlePisInput(event, index) {
    pisValue[index] = event.target.value;
  }

  function onConfirmButtonClicked() {
    setSavePisButtonLoading(true);
    const body = pisValue.map((data, index) => {
      return { cpf: employeesList[index].cpf, pis: data };
    });
    api
      .post('pontoGO/employees/pis', body)
      .then(data => {})
      .catch(error => {})
      .finally(() => {
        setSavePisButtonLoading(false);
        setModalPis(false);
        setSnackbarPis(true);
      });
  }

  function getRules() {
    api
      .get(`pontoGO/companies/rules/${currentCompanyCode}`)
      .then(({ data }) => {
        setJourneyRules(data?.journeyRules);
        setShifts(data?.shifts);
        setJobTitles(data?.jobTitles);
      })
      .finally(() => {});
  }

  function onJourneyRulesSelected(value) {
    setJourneyRulesSelected(value);
  }

  function onShiftSelected(value) {
    setShiftSelected(value);
  }

  function onJobTitleSelected(value) {
    setJobTitleSelected(value);
  }

  const getAddModalMessage = () => {
    if (channelData?.status === PROCESSING)
      return 'O processamento pode levar alguns minutos. Aguarde o carregamento.';

    if (channelData?.status === PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_ERROR)
      return channelData?.data?.map(item => {
        return (
          <>
            <div>
              <p style={{ color: '#252525' }}>
                Houve um problema com o colaborador: ({item.cpf})
              </p>
              <p style={{ color: '#ee3e38' }}> Motivo: {item.error}.</p>
            </div>
            <hr style={{ margin: '.5rem 0 .5rem 0' }} />
          </>
        );
      });

    if (
      channelData?.status === PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_SUCCESS ||
      !channelData?.status
    )
      return '';

    return 'Serviço indisponível.';
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <Grid
          container
          direction="column"
          className={styles.employeesListModalContainer}
        >
          <Grid item md={12} lg={12}>
            {!isMobile && (
              <div className={styles.headerFilters}>
                {situationOfEmployees.map(item => {
                  if (item.text !== 'Demitidos') {
                    return (
                      <Button
                        onClick={() =>
                          handleFilterSituation(item.value, item.search)
                        }
                        className={
                          activeButton === item.value
                            ? styles.buttonFilterActive
                            : styles.buttonFilter
                        }
                      >
                        {item.text}
                      </Button>
                    );
                  }
                })}

                <FormControl
                  variant="outlined"
                  className={styles.textFieldStyleCostAndCenter}
                  size="small"
                >
                  <InputLabel id="payment-type-select-box-label">
                    Centro de custo
                  </InputLabel>
                  <Select
                    labelId="payment-type-select-box-label"
                    id="payment-type-select-box"
                    label="Centro de custo"
                    value={searchCostAndCenter}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={({ target: { value } }) =>
                      handleChangeCostAndCenter(value)
                    }
                  >
                    {costAndCenter?.map((item, index) => (
                      <MenuItem key={item.code} value={item.description}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}

            <div className={styles.collaboratorsSettings}>
              <FormControl
                variant="outlined"
                className={styles.textFieldStyleRules}
                size="small"
              >
                <InputLabel id="journey-rules-label">
                  Regras de jornada
                </InputLabel>
                <Select
                  labelId="journey-rules-select-box"
                  label="Regras de jornada"
                  value={journeyRulesSelected}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  onChange={({ target: { value } }) =>
                    onJourneyRulesSelected(value)
                  }
                >
                  {journeyRules?.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                className={styles.textFieldStyleRules}
                size="small"
              >
                <InputLabel id="shifts-label">Turnos</InputLabel>
                <Select
                  labelId="shifts-box"
                  id="shifts-select-box"
                  label="Turnos"
                  value={shiftSelected}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  onChange={({ target: { value } }) => onShiftSelected(value)}
                >
                  {shifts?.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                className={styles.textFieldStyleRules}
                size="small"
              >
                <InputLabel id="job-titles-label">Cargos</InputLabel>
                <Select
                  labelId="job-titles-label"
                  id="job-titles-select-box"
                  label="Cargos"
                  value={jobTitleSelected}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  onChange={({ target: { value } }) =>
                    onJobTitleSelected(value)
                  }
                >
                  {jobTitles?.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <TextField
              value={cpfOrName}
              onChange={handleChangeCpfOrName}
              className={styles.employeesListModalSearchInput}
              variant="outlined"
              placeholder="Pesquise por nome ou CPF"
              onFocus={() =>
                setTimeout(() => setTextFieldFocused('cpfOrName'), 200)
              }
              onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
              InputProps={{
                style: {
                  height: 40,
                  borderRadius: 6,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search className={styles.iconColor} />
                  </InputAdornment>
                ),
                endAdornment: cpfOrName?.length > 0 &&
                  textFieldFocused === 'cpfOrName' && (
                    <IconButton
                      className={styles.endAdornment}
                      onClick={() => setCpfOrName('')}
                    >
                      <CloseRoundedIcon className={styles.endAdornmentIcon} />
                    </IconButton>
                  ),
              }}
            />
          </Grid>

          {!isMobile && employees.length > 0 ? (
            <EmployeesTable
              employees={employees}
              isLoading={isLoading}
              totalSize={totalSize}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selected={selected}
              setSelected={setSelected}
              ignoredEmployees={ignoredEmployees}
              setIgnoredEmployees={setIgnoredEmployees}
            />
          ) : isMobile && employees.length > 0 ? (
            <ModalMobileList
              employees={employees}
              loading={isLoading}
              setPageSize={setTotalSize}
              pageSize={totalSize}
              ignoredEmployees={ignoredEmployees}
              selected={selected}
              setSelected={setSelected}
              setIgnoredEmployees={setIgnoredEmployees}
            />
          ) : (
            <ResultNotFound
              text="Nenhum Resultado Encontrado"
              alternativeText="Verifique os dados pesquisados e tente novamente."
              buttonText="Limpar Filtros"
              onClick={handleClearFilters}
            />
          )}

          <Grid
            container
            justify={
              selected.length > 0 && !isLoading && !isMobile
                ? 'space-between'
                : 'flex-end'
            }
            alignItems="center"
          >
            {selected.length > 0 && !isLoading && !isMobile && (
              <Grid item className={styles.employeesListSelecteds}>
                <div className={styles.employeesListSelectedsCount}>
                  {selected.length}
                </div>

                <Typography className={styles.employeesListSelectedsText}>
                  {selected.length > 1 ? 'Selecionados' : 'Selecionado'}
                </Typography>
              </Grid>
            )}

            <Grid item>
              <Grid container>
                <Grid item>
                  <Button
                    onClick={onCancelClicked}
                    className={styles.employeesListModalCloseButton}
                  >
                    Cancelar
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    onClick={() => includeEmployees(selected)}
                    className={styles.employeesListModalConfirmButton}
                    disabled={
                      !(
                        employees.length > 0 &&
                        selected.length > 0 &&
                        journeyRulesSelected !== '' &&
                        shiftSelected !== '' &&
                        jobTitleSelected !== ''
                      )
                    }
                  >
                    {isLoadingButton ? (
                      <CircularProgress
                        size={16}
                        className={styles.circularProgress}
                      />
                    ) : (
                      'Incluir'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>

      <ErrorModal
        title={
          errorMessage !== null && errorMessage[0].errorsDescription !== null
            ? 'Atenção!'
            : 'Tivemos um problema no cadastro'
        }
        text={
          errorMessage !== null &&
          errorMessage[0].errorsDescription !== null ? (
            <span>
              Você selecionou funcionários que estão com dados que precisam ser
              revistos para ser cadastrado no ponto online.
              <br />
              <br />
              {errorMessage[0].errorsDescription.map(item => (
                <Typography className={styles.errorListLabel}>
                  • {formatCPF(item.cpf)} - {item.error}
                </Typography>
              ))}
            </span>
          ) : (
            <span>Tente novamente!</span>
          )
        }
        confirmButtonText="Ok"
        cancelButtonText="Fechar"
        onConfirmClicked={() => setOpenModalError(false)}
        onCancelButtonClicked={() => setOpenModalError(false)}
        open={openModalError}
      />

      <PisModal
        open={modalPis}
        employeesList={employeesList}
        title="Tivemos um problema no cadastro"
        onClose={() => setModalPis(prevState => !prevState)}
        onCancelButtonClicked={() => setModalPis(prevState => !prevState)}
        onConfirmButtonClicked={onConfirmButtonClicked}
        pisValue={pisValue}
        handlePisInput={handlePisInput}
        loading={savePisButtonLoading}
      />

      <ModalAddEmployeeLoading
        open={modalEmployeeLoading}
        maxWidth="sm"
        title={
          channelData?.status === PROCESSING
            ? 'Envio de colaboradores em processamento...'
            : channelData?.status ===
                PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_SUCCESS &&
              channelData?.data.length >= 1
            ? `${selected.length > 1 ? 'Colaboradores' : 'Colaborador'} ${
                selected.length > 1 ? 'cadastrados' : 'cadastrado'
              } com sucesso! Mas houve problema com outro(s) colaborador(es)`
            : channelData?.status ===
                PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_SUCCESS &&
              channelData?.data.length === 0
            ? `${selected.length > 1 ? 'Colaboradores' : 'Colaborador'} ${
                selected.length > 1 ? 'cadastrados' : 'cadastrado'
              } com sucesso!`
            : (channelData?.status ===
                PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_ERROR &&
                channelData?.data.length >= 1) ||
              channelData?.data?.error != null
            ? 'Não foi possivel enviar os colaboradores'
            : `${selected.length > 1 ? 'Colaboradores' : 'Colaborador'} ${
                selected.length > 1 ? 'foram' : 'foi'
              }  ${selected.length > 1 ? 'selecionados' : 'selecionado'}.`
        }
        text={getAddModalMessage()}
        confirmButtonText={
          channelData?.status === PROCESSING
            ? `${channelData?.percentage || '0'} %`
            : channelData?.status ===
              PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_SUCCESS
            ? 'Fechar'
            : channelData?.status === PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_ERROR
            ? 'Entendi'
            : 'Cadastrar'
        }
        onConfirmClicked={
          channelData?.status ===
            PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_SUCCESS ||
          channelData?.status === PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_ERROR
            ? () => {
                setModalEmployeeLoading(false);
                getEmployeesOnlinePoint();
              }
            : () => {
                confirmIncludeEmployees();
              }
        }
        cancelButtonText="Cancelar"
        onCancelButtonClicked={
          channelData?.status === PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_SUCCESS
            ? () => {
                setModalEmployeeLoading(false);
                getEmployeesOnlinePoint();
              }
            : () => {
                setModalEmployeeLoading(false);
              }
        }
        progressLoading={channelData?.status === PROCESSING}
        progress={channelData?.percentage}
      />

      <Snackbar
        open={snackbarPis}
        severity="success"
        message="PIS cadastrados com sucesso!"
        close={() => setSnackbarPis(false)}
      />
    </>
  );
}

export default EmployeesListModal;
