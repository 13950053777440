import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import {
	Header, Table,
	Resume, Charge,
	TablePaginationActions
} from './UI';

import LogoLoading from '../../components/LogoLoading';
import ProgressToolBar from '../../components/ProgressToolBar';

import { api } from '../../services/api';
import openNewTab from '../../utils/openNewTab';
import convertBytesToBlobDowload from '../../utils/convertBytesToBlobDowload';

function OnlinePointChargesDetailed(props) {

	const history = useHistory();

	const [loading, setLoading] = useState(true);

	const [id, setId] = useState(null);

	const [totalSize, setTotalSize] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);

	const [charge, setCharge] = useState({})

	const loadingOff = () => setLoading(false);

	const goBack = () => history.push("/online-point-charges")

	function getCharge(id) {
		if(id) {
			api.get(`/pontoGO/charges/${id}`)
				.then(({ data }) => {
					if(!data) {
						goBack()
					} else {
						setCharge(data)
						setTotalSize(data.employeeQuantity)
					}
				})
				.finally(() => loadingOff())
		}
	}
  
	function getDownloadNfe(url) {
		if(url)
		  openNewTab(url)
	  }
	  
	function getDownloadBankTicket(id) {
	if(id) {
		api.get(`/pontoGO/charges/${id}/bankTicket`, {
				responseType: 'blob',
			})
			.then(({ data }) => {
				const nameDocument = `boleto_${id}`;
				convertBytesToBlobDowload(data, nameDocument);
			});
	}
	}

	const handlePageChange = (_, newPage) => setCurrentPage(newPage);

	const handleRowsPerPageChange = event => {
		setPageSize(parseInt(event.target.value, 10));
		setCurrentPage(0);
	}

	useEffect(() => {
		setId(props.match.params?.id);
		getCharge(props.match.params?.id);
	}, []);

	return(
		<>
			{loading ? (
				<LogoLoading />
			) : (
				<>
					<Header
						onClickNFeDownload={() => getDownloadNfe(charge.invoicePdfUrl)}
						onClickBankTicketDownload={() => getDownloadBankTicket(id)}
					/>

					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Resume charge={charge}/>
						</Grid>
						
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Charge charge={charge}/>
						</Grid>
					</Grid>

					<Table
						data={charge.employees}
						loading={loading}
						totalSize={totalSize}
						pageSize={pageSize}
						currentPage={currentPage}
						handlePageChange={handlePageChange}
						handleRowsPerPageChange={handleRowsPerPageChange}
						TablePaginationActions={TablePaginationActions}
					/>

					<ProgressToolBar
						onBackClicked={goBack}
						disableNext
						backButtonText="Voltar"
					/>
				</>
			)}
		</>
	);
}

export default OnlinePointChargesDetailed;