import React, { useEffect, useState, useRef, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Hidden, IconButton, Button } from '@material-ui/core';

import RegisterCardIcon from '@material-ui/icons/GroupAdd';
import PersonalRegisterCardIcon from '@material-ui/icons/PersonAddRounded';
import TedCardIcon from '@material-ui/icons/Receipt';
import ManualPaymentCardIcon from '@material-ui/icons/ListRounded';
import PaymentCardIcon from '@material-ui/icons/DescriptionRounded';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import moment from 'moment';

import NiceModal from '@ebay/nice-modal-react';
import {
  Balances,
  Extract,
  Slider,
  MenuCard,
  PaymentChartDashboard,
} from './UI';

import AlreadyUploadModal from '../../components/PrimaryModal';
import FloatButton from '../../components/FloatButton';
import ContractTermsModal from '../../components/ContractTermsModal';
import SuccessModal from '../../components/SuccessModal';
import PrimaryModal from '../../components/PrimaryModal';
import FeedbackModal from '../../components/FeedbackModal';
import OnlinePointChargeModal from '../../components/OnlinePointChargeModal';

import styles from './styles.module.css';

import { actions } from './actions';
import { pusherStatus } from '../../enums/pusher';
import screenScrollSize from '../../enums/screenScrollSize';

import { hasAuthorization, profiles } from '../../utils/hasAuthorization';
import { isSubsidiaryCompanie } from '../../utils/isSubsidiaryCompanie';
import { isMobile } from '../../utils/breakpoints';

import { api } from '../../services/api';

import { ReactComponent as ReceiptLongIcon } from '../../assets/receiptLong.svg';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import { ReactComponent as PixIcon } from '../../assets/pix.svg';
import { useLazyGetLocationsQuery } from '../../redux/store/Employees/services/approvedEmployees.api';
import { ActionsEmployeesGeneral } from '../../redux/store/Employees';
import { handleRequestError } from '../../utils/handleRequestError';

import { HomeModals } from './components/HomeModals';

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { drawer } = useSelector(state => state.openAndClosedDrawer);

  const { hasToUpdateIncomeInfo, wasUpdated } = useSelector(state => {
    const { hasToUpdateIncomeInfo, wasUpdated } = state.account;
    return { hasToUpdateIncomeInfo, wasUpdated };
  });

  const { showBeeValePopup, showRaPrizePopup } = useSelector(state => {
    const { showBeeValePopup, showRaPrizePopup } = state.userReducer.user;
    return { showBeeValePopup, showRaPrizePopup };
  });

  const lazyGetLocations = useLazyGetLocationsQuery();

  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const currentCompanyIndex =
    sessionStorage.getItem('currentCompanyIndex') || 0;
  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const acceptedContract = companies[currentCompanyIndex]?.acceptedContract;
  const { payrollProduct, safeProduct } = companies[currentCompanyIndex || 0];

  const userStorage = sessionStorage.getItem('userJobRole');
  const userCpf = sessionStorage.getItem('cpf');

  const uploadDataPayroll = JSON.parse(
    localStorage.getItem(`uploadDataPayroll-${currentCompanyCode}`),
  );
  const uploadDataEmployees = JSON.parse(
    localStorage.getItem(`uploadDataEmployees-${currentCompanyCode}`),
  );
  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));

  const formatYearAndMouth =
    moment().format('YYYY-MM') !== 'Data inválida'
      ? moment().format('YYYY-MM')
      : '';

  const {
    EXTRA_SMALL_SCROLL,
    SMALL_SCROLL,
    MEDIUM_SCROLL,
    LARGE_SCROLL,
    EXTRA_LARGE_SCROLL,
    EXTRA_SMALL_OFFSET,
    SMALL_OFFSET,
    MEDIUM_OFFSET,
    SCROLL_PERCENTAGE,
    PERCENTAGE,
  } = screenScrollSize;

  const [alreadyUploadModal, setAlreadyUploadModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(0);
  const [openContractTermsModal, setOpenContractTermsModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFeedbackSuccessModal, setOpenFeedbackSuccessModal] = useState(
    false,
  );
  const [openPrimaryModal, setOpenPrimaryModal] = useState(false);
  const [titlePrimaryModal, setTitlePrimaryModal] = useState('');
  const [textPrimaryModal, setTextPrimaryModal] = useState('');
  const [loading, setLoading] = useState('');
  const [planInformations, setPlanInformations] = useState({});
  const [loadingContract, setLoadingContract] = useState(true);
  const [visibleButtonLeft, setVisibleButtonLeft] = useState(false);
  const [visibleButtonRight, setVisibleButtonRight] = useState(true);
  const [activeButton, setActiveButton] = useState(1);
  const [openMenuOptions, setOpenMenuOptions] = useState(null);
  const [openMenuEvent, setOpenMenuEvent] = useState(null);
  const [openMenuEventTopFive, setOpenMenuEventTopFive] = useState(null);
  const [openMenuEventAnotherData, setOpenMenuEventAnotherData] = useState(
    null,
  );
  const [initialDateDash, setInitialDateDash] = useState('');
  const [finalDateDash, setFinalDateDash] = useState('');
  const [initialDateAnotherData, setInitialDateAnotherData] = useState('');
  const [finalDateAnotherData, setFinalDateAnotherData] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [lastMonths, setLastMonths] = useState(0);
  const [lastDays, setLastDays] = useState(0);
  const [totalPayments, setTotalPayments] = useState([]);
  const [mounthPayments, setMounthPayments] = useState([]);
  const [anotherData, setAnotherData] = useState([]);
  const [topFiveAmounts, setTopFiveAmounts] = useState([]);
  const [topFive, setTopFive] = useState([]);
  const [loadingDashPayments, setLoadingDashPayments] = useState(true);
  const [animationChartPayment, setAnimationChartPayment] = useState(true);
  const [animationTopFive, setAnimationTopFive] = useState(true);
  const [loadingAnotherData, setLoadingAnotherData] = useState(true);
  const [loadingTopFive, setLoadingTopFive] = useState(true);
  const [noContentPayments, setNoContentPayments] = useState(false);
  const [textErrorDashPayments, setTextErrorDashPayments] = useState('');
  const [textErrorTopFive, setTextErrorTopFive] = useState('');
  const [noContentTopFive, setNoContentTopFive] = useState(false);
  const [yearMouthTopFive, setYearMouthTopFive] = useState(formatYearAndMouth);
  const [lastYearMouthTopFive, setLastYearMouthTopFive] = useState('');
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openOnlinePointChargeModal, setOnlinePointChargeModal] = useState(
    false,
  );
  const [charges, setCharges] = useState([]);
  const [selectedValue, setSelectedValue] = useState('10');
  const [loadingFeedbackModal, setLoadingFeedbackModal] = useState(false);
  const feedbackTextField = useRef();

  const PERMISSION_TO_VIEW_BALANCE = hasAuthorization(ACCESS_PROFILES, [
    profiles.ADMIN,
    profiles.FINANCIAL,
    profiles.AUTHORIZER,
  ]);

  const isSubsidiary = isSubsidiaryCompanie();
  const carousel = useRef();

  const { PROCESSING, CANCELED } = pusherStatus;

  const isAdminAndAuthorizer =
    ACCESS_PROFILES.includes(profiles.ADMIN) &&
    ACCESS_PROFILES.includes(profiles.AUTHORIZER);

  const isAdminOrFinancial =
    ACCESS_PROFILES.includes(profiles.ADMIN) ||
    ACCESS_PROFILES.includes(profiles.FINANCIAL);

  useEffect(() => {
    if (hasToUpdateIncomeInfo && isAdminOrFinancial) {
      NiceModal.show('revenue-modal');
    }
  }, [hasToUpdateIncomeInfo]);

  useEffect(() => {
    if (showBeeValePopup) {
      NiceModal.show('bee-vale-popup');
    }
  }, [showBeeValePopup]);

  useEffect(() => {
    if (showRaPrizePopup) {
      NiceModal.show('ra-prize-popup');
    }
  }, [showRaPrizePopup]);

  useEffect(() => {
    (async () => {
      try {
        const locations = await lazyGetLocations[0](currentCompanyCode);

        dispatch(ActionsEmployeesGeneral.setLocations(locations.data));
      } catch (error) {
        handleRequestError(
          error,
          'Houve um erro ao buscar os centros de custo',
        );
      }
    })();

    return () => {
      lazyGetLocations[0]().abort();
    };
  }, [currentCompanyCode]);

  useEffect(() => {
    if (isAdminAndAuthorizer && !acceptedContract) {
      getPlanInformations();
      setOpenContractTermsModal(true);
    }
  }, [currentCompanyCode]);

  useEffect(() => {
    setLoadingContract(true);
  }, [currentCompanyCode]);

  useEffect(() => {
    if (lastDays && !openMenuEvent) getPaymentsByDays();
    if ((lastMonths || !lastDays) && !openMenuEvent) getPaymentsByMonth();
  }, [lastDays, lastMonths, currentCompanyCode]);

  useEffect(() => {
    getTopFive();
  }, [currentCompanyCode]);

  useEffect(() => {
    getAnotherDataPayments();
  }, [currentCompanyCode]);

  useEffect(() => {
    const user = JSON.parse(userStorage);

    api
      .get(`/opinion-avaliation/${currentCompanyCode}/${userCpf}`)
      .then(({ data }) => {
        if (data?.makeAvaliation) {
          NiceModal.show('nps-modal', {
            currentCompanyCode,
            userName: user?.name,
          });
        }
      });

    if (hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN, profiles.FINANCIAL]))
      checkIfCompanyHasOnlinePointChargesOverdue();
  }, [currentCompanyCode]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('showScdPopup'))) {
      NiceModal.show('somapay-scd-presentation');
    }
  }, []);

  useEffect(() => {
    if (wasUpdated) NiceModal.show('revenue-confirm-modal');
  }, [wasUpdated]);

  const onChangeInitialDateDash = value => {
    setInitialDateDash(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : '',
    );
  };

  const onChangeFinalDateDash = value => {
    setFinalDateDash(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : '',
    );
  };

  const onChangeInitialDateAnotherData = value => {
    setInitialDateAnotherData(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : '',
    );
  };

  const onChangeFinalDateAnotherData = value => {
    setFinalDateAnotherData(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : '',
    );
  };

  const onChangeYearMouthTopFive = value => {
    setLastYearMouthTopFive(yearMouthTopFive);
    setYearMouthTopFive(
      moment(value).format('YYYY-MM') !== 'Data inválida'
        ? moment(value).format('YYYY-MM')
        : '',
    );
  };

  function onButtonFilterDashboard(value, index) {
    if (index <= 1) {
      setLastDays(0);
      setLastMonths(value);
    } else {
      setLastMonths(0);
      setLastDays(value);
    }
    setActiveButton(value);
    setOpenMenuOptions(null);
  }

  function onButtonFilterMounthInDays() {
    setActiveButton(null);
    getPaymentsByDays();
    setOpenMenuEvent(null);
  }

  function onButtonFilterAnotherData() {
    setOpenMenuEventAnotherData(null);
    getAnotherDataPayments();
  }

  function onButtonFilterTopFive() {
    setOpenMenuEventTopFive(null);
    getTopFive();
  }

  function onHandleCleanFilters() {
    setOpenMenuEvent(null);
    setOpenMenuEventAnotherData(null);
    setOpenMenuEventTopFive(null);
    setFinalDateDash('');
    setInitialDateDash('');
    setFinalDateAnotherData('');
    setInitialDateAnotherData('');
    setYearMouthTopFive(lastYearMouthTopFive || formatYearAndMouth);
  }

  function handleVerifyUpload() {
    setButtonClicked(1);

    if (
      uploadDataPayroll &&
      uploadDataPayroll?.status === PROCESSING &&
      uploadDataPayroll?.companyCode === currentCompanyCode
    ) {
      setAlreadyUploadModal(true);
    } else {
      history.push('/payroll/menu');
    }
  }

  function handleVerifyUploadEmployee() {
    setButtonClicked(2);

    if (
      uploadDataEmployees &&
      uploadDataEmployees?.status === PROCESSING &&
      uploadDataEmployees?.companyCode === currentCompanyCode
    ) {
      setAlreadyUploadModal(true);
    } else {
      history.push('/employees/register-batch/menu');
    }
  }

  function goToProcess() {
    if (
      uploadDataPayroll &&
      uploadDataPayroll?.status !== CANCELED &&
      uploadDataPayroll?.companyCode === currentCompanyCode &&
      buttonClicked === 1
    ) {
      history.push('/payroll/menu/info-payroll/register-batch');
    }

    if (
      uploadDataEmployees &&
      uploadDataEmployees?.status !== CANCELED &&
      uploadDataEmployees?.companyCode === currentCompanyCode &&
      buttonClicked === 2
    ) {
      history.push('/employees/register-batch/menu/select-company/excel-form');
    }
  }

  function handleAcceptContract() {
    setLoading(true);

    api
      .post(`/companies/${currentCompanyCode}/contracts`)
      .then(() => {
        updateStorage();
        setOpenContractTermsModal(false);
        setOpenSuccessModal(true);
      })
      .catch(() => {
        setOpenContractTermsModal(false);
        setTitlePrimaryModal('Ocorreu um erro na atualização do contrato.');
        setTextPrimaryModal('Por favor, tente novamente mais tarde.');
        setOpenPrimaryModal(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function updateStorage() {
    const filteredArray = companies?.filter(
      item => item.code === currentCompanyCode,
    );

    filteredArray[0].acceptedContract = true;

    sessionStorage.setItem('companies', JSON.stringify(companies));
  }

  function getPlanInformations() {
    api
      .get(`/signature/info/${currentCompanyCode}`)
      .then(({ data }) => {
        setPlanInformations(data);
      })
      .finally(() => setLoadingContract(false));
  }

  function handleLeftClick() {
    setAnimationChartPayment(false);
    setAnimationTopFive(false);

    const screenSizeCalculation =
      carousel.current.offsetWidth * (SCROLL_PERCENTAGE / PERCENTAGE);
    carousel.current.scrollLeft -= screenSizeCalculation;
    if (carousel.current.scrollLeft <= MEDIUM_SCROLL) {
      setVisibleButtonLeft(false);
    }
    setVisibleButtonRight(true);
  }

  function handleRightClick() {
    setVisibleButtonLeft(true);
    setAnimationChartPayment(false);
    setAnimationTopFive(false);

    const screenSizeCalculation =
      carousel.current.offsetWidth * (SCROLL_PERCENTAGE / PERCENTAGE);
    carousel.current.scrollLeft += screenSizeCalculation;
    const { offsetWidth } = carousel.current;
    const { scrollLeft } = carousel.current;

    if (
      scrollLeft >= EXTRA_LARGE_SCROLL ||
      (scrollLeft >= SMALL_SCROLL && offsetWidth >= SMALL_OFFSET) ||
      (scrollLeft >= EXTRA_SMALL_SCROLL && offsetWidth >= MEDIUM_OFFSET) ||
      (scrollLeft >= LARGE_SCROLL && offsetWidth >= EXTRA_SMALL_OFFSET)
    ) {
      setVisibleButtonRight(false);
    }
  }

  const handleChangeTab = (_, newValue) => {
    switch (newValue) {
      case 0:
        setActiveTab(0);
        break;
      case 1:
        setActiveTab(1);
        break;
      case 2:
        setActiveTab(2);
        break;
    }
  };

  async function getPaymentsByMonth() {
    setLoadingDashPayments(true);
    setAnimationChartPayment(true);
    setNoContentPayments(false);
    setTextErrorDashPayments('');

    const requestOptions = {
      params: {
        lastMonths: lastMonths || null,
      },
    };

    const response = await api.get(
      `/dashboard/paymentsByMonth/${currentCompanyCode}`,
      requestOptions,
    );

    try {
      if (response) {
        const newValuesMonths = response.data.map(item => {
          return Object.keys(item)[0];
        });
        const newValuesTotal = response.data.map(item => {
          return Object.values(item)[0]?.totalAmount;
        });

        if (response.data.length === 0) {
          setNoContentPayments(true);
        }

        setTotalPayments(newValuesTotal);
        setMounthPayments(newValuesMonths);
      }
    } catch (error) {
      setTextErrorDashPayments('Não foi possível carregar as informações');
    } finally {
      setLoadingDashPayments(false);
    }
  }

  async function getPaymentsByDays() {
    setLoadingDashPayments(true);
    setAnimationChartPayment(true);
    setNoContentPayments(false);
    setTextErrorDashPayments('');

    const requestOptions = {
      params: {
        lastDays: lastDays || null,
        initialDate: initialDateDash || null,
        finalDate: finalDateDash || null,
      },
    };

    const response = await api.get(
      `/dashboard/paymentsByDay/${currentCompanyCode}`,
      requestOptions,
    );

    try {
      if (response) {
        const newValuesMonths = response.data.map(item => {
          return item.scheduleDate;
        });
        const newValuesTotal = response.data.map(item => {
          return item.amount;
        });

        if (response.data.length === 0) {
          setNoContentPayments(true);
        }

        setTotalPayments(newValuesTotal);
        setMounthPayments(newValuesMonths);
      }
    } catch (error) {
      setTextErrorDashPayments('Não foi possível carregar as informações');
    } finally {
      setLoadingDashPayments(false);
      setInitialDateDash('');
      setFinalDateDash('');
    }
  }

  async function getTopFive() {
    setLoadingTopFive(true);
    setAnimationTopFive(true);
    setNoContentTopFive(false);
    setTextErrorTopFive('');

    const requestOptions = {
      params: {
        yearMonth: yearMouthTopFive,
      },
    };

    const response = await api.get(
      `/dashboard/topFive/${currentCompanyCode}`,
      requestOptions,
    );

    try {
      if (response) {
        if (response.data?.topFivePayments.length === 0) {
          setNoContentTopFive(true);
        }

        const amountsTopFive = response.data?.topFivePayments.map(item => {
          return item.amount;
        });

        setTopFive(response.data);
        setTopFiveAmounts(amountsTopFive);
      }
    } catch (error) {
      setTextErrorTopFive('Não foi possível carregar as informações');
    } finally {
      setLoadingTopFive(false);
    }
  }

  async function getAnotherDataPayments() {
    setLoadingAnotherData(true);

    const requestOptions = {
      params: {
        initialDate: initialDateAnotherData || null,
        finalDate: finalDateAnotherData || null,
      },
    };

    const response = await api.get(
      `/dashboard/anotherData/${currentCompanyCode}`,
      requestOptions,
    );

    try {
      if (response) {
        setAnotherData(response.data);
      }
    } finally {
      setLoadingAnotherData(false);
    }
  }

  function handleSendFeedback() {
    setLoadingFeedbackModal(prevState => !prevState);
    const user = JSON.parse(userStorage);
    const body = {
      userCompany: user.name,
      feedback: feedbackTextField.current.value,
      grade: selectedValue,
    };

    dispatch({
      type: 'SET_OPEN_FEEDBACK_MODAL',
      openModal: false,
    });
    api.post(`/opinion-avaliation/${currentCompanyCode}`, body).then(() => {
      setOpenFeedbackModal(prevState => !prevState);
      setOpenFeedbackSuccessModal(prevState => !prevState);
      setLoadingFeedbackModal(prevState => !prevState);
    });
  }

  function checkIfCompanyHasOnlinePointChargesOverdue() {
    api
      .get(`pontoGO/charges/company/${currentCompanyCode}/overdue`)
      .then(({ data }) => {
        if (data?.length) {
          setOnlinePointChargeModal(prevState => !prevState);
          setCharges(data);
        }
      });
  }

  return (
    <>
      <Grid
        container
        wrap={!isMobile && 'nowrap'}
        direction={isMobile && 'column'}
        justify="space-between"
        sm={12}
        md={12}
        lg={12}
      >
        {isMobile && (
          <Grid item>
            {PERMISSION_TO_VIEW_BALANCE && <Balances />}
            <div className={styles.sliderArea}>
              <Slider />
            </div>
          </Grid>
        )}

        <Grid item sm={8} md={8} lg={8}>
          {visibleButtonLeft && (
            <div
              className={
                drawer ? styles.menuGradientLeft : styles.displayMobile
              }
            >
              <IconButton
                className={styles.buttonLeft}
                onClick={handleLeftClick}
                disableRipple
              >
                <Button disableRipple className={styles.buttonChevron}>
                  <ChevronRight className={styles.chevron} />
                </Button>
              </IconButton>
            </div>
          )}

          <div
            className={drawer ? styles.cardsArea : styles.displayMobile}
            ref={carousel}
          >
            <MenuCard
              onButtonClicked={
                hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.FINANCIAL,
                ]) &&
                !isSubsidiary &&
                (() => history.push('/cash-in/pix'))
              }
              disabled={
                !hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.FINANCIAL,
                ]) || isSubsidiary
              }
              title="Depósito via pix"
              icon={<PixIcon className={styles.icon} />}
            />

            <MenuCard
              onButtonClicked={
                hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.PERSONAL_DEPARTMENT,
                ]) &&
                payrollProduct &&
                handleVerifyUploadEmployee
              }
              disabled={
                !hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.PERSONAL_DEPARTMENT,
                ]) || !payrollProduct
              }
              title="Cadastro via arquivo"
              icon={<RegisterCardIcon />}
            />

            <MenuCard
              onButtonClicked={
                hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.PERSONAL_DEPARTMENT,
                ]) &&
                payrollProduct &&
                (() => history.push('/employees/register-single'))
              }
              disabled={
                !hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.PERSONAL_DEPARTMENT,
                ]) || !payrollProduct
              }
              title="Cadastro individual"
              icon={<PersonalRegisterCardIcon className={styles.icon} />}
            />

            <MenuCard
              onButtonClicked={
                hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.FINANCIAL,
                ]) &&
                !isSubsidiary &&
                (() =>
                  history.push('/cash-in/inform-transfer-Deposit', {
                    byScreen: true,
                  }))
              }
              disabled={
                !hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.FINANCIAL,
                ]) || isSubsidiary
              }
              title="Registro de transferência"
              icon={<TedCardIcon className={styles.icon} />}
            />

            <MenuCard
              onButtonClicked={
                hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.PERSONAL_DEPARTMENT,
                  profiles.FINANCIAL,
                ]) &&
                payrollProduct &&
                (() => history.push('/payroll/manual-payroll'))
              }
              disabled={
                !hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.PERSONAL_DEPARTMENT,
                  profiles.FINANCIAL,
                ]) || !payrollProduct
              }
              title="Pagamento manual"
              icon={<ManualPaymentCardIcon className={styles.icon} />}
            />

            <MenuCard
              onButtonClicked={
                hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.PERSONAL_DEPARTMENT,
                  profiles.FINANCIAL,
                ]) &&
                payrollProduct &&
                handleVerifyUpload
              }
              disabled={
                !hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.PERSONAL_DEPARTMENT,
                  profiles.FINANCIAL,
                ]) || !payrollProduct
              }
              title="Pagamento via arquivo"
              icon={<PaymentCardIcon className={styles.icon} />}
            />

            {visibleButtonRight && (
              <div
                className={
                  isMobile ? styles.displayMobile : styles.menuGradientRight
                }
              >
                <IconButton
                  className={styles.buttonRight}
                  onClick={handleRightClick}
                  disableRipple
                >
                  <Button disableRipple className={styles.buttonChevron}>
                    <ChevronRight className={styles.chevron} />
                  </Button>
                </IconButton>
              </div>
            )}

            <MenuCard
              onButtonClicked={
                hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.FINANCIAL,
                ]) && (() => history.push('/manage-registered-accounts'))
              }
              disabled={
                !hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.FINANCIAL,
                ])
              }
              title="Cadastrar nova conta"
              icon={<PlaylistAddIcon className={styles.icon} />}
            />

            <MenuCard
              onButtonClicked={
                (!hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.PERSONAL_DEPARTMENT,
                ]) ||
                  payrollProduct) &&
                (() => history.push('/paycheck'))
              }
              disabled={
                !hasAuthorization(ACCESS_PROFILES, [
                  profiles.ADMIN,
                  profiles.PERSONAL_DEPARTMENT,
                ]) || !payrollProduct
              }
              title="Importar contra cheques"
              icon={<ReceiptLongIcon className={styles.icon} />}
            />

            <MenuCard
              onButtonClicked={
                (!hasAuthorization(ACCESS_PROFILES, [
                  profiles.PERSONAL_DEPARTMENT,
                ]) ||
                  safeProduct) &&
                (() => history.push('/insurance-management'))
              }
              disabled={
                !hasAuthorization(ACCESS_PROFILES, [
                  profiles.PERSONAL_DEPARTMENT,
                ]) || !safeProduct
              }
              title="Adicionar segurado"
              icon={<VerifiedUserIcon className={styles.icon} />}
            />
          </div>

          <div className={styles.chartArea}>
            <PaymentChartDashboard
              handleChangeTab={handleChangeTab}
              activeTab={activeTab}
              activeButton={activeButton}
              onButtonFilterDashboard={onButtonFilterDashboard}
              openMenuOptions={openMenuOptions}
              setOpenMenuOptions={setOpenMenuOptions}
              setOpenMenuEvent={setOpenMenuEvent}
              openMenuEvent={openMenuEvent}
              initialDateDash={initialDateDash}
              finalDateDash={finalDateDash}
              onHandleCleanFilters={onHandleCleanFilters}
              onChangeInitialDateDash={onChangeInitialDateDash}
              onChangeFinalDateDash={onChangeFinalDateDash}
              onButtonFilterMounthInDays={onButtonFilterMounthInDays}
              mounthPayments={mounthPayments}
              totalPayments={totalPayments}
              loadingDashPayments={loadingDashPayments}
              textErrorDashPayments={textErrorDashPayments}
              anotherData={anotherData}
              loadingAnotherData={loadingAnotherData}
              initialDateAnotherData={initialDateAnotherData}
              finalDateAnotherData={finalDateAnotherData}
              onChangeFinalDateAnotherData={onChangeFinalDateAnotherData}
              onChangeInitialDateAnotherData={onChangeInitialDateAnotherData}
              openMenuEventAnotherData={openMenuEventAnotherData}
              setOpenMenuEventAnotherData={setOpenMenuEventAnotherData}
              onButtonFilterAnotherData={onButtonFilterAnotherData}
              animationChartPayment={animationChartPayment}
              setAnimationChartPayment={setAnimationChartPayment}
              noContentPayments={noContentPayments}
              topFiveAmounts={topFiveAmounts}
              topFive={topFive}
              noContentTopFive={noContentTopFive}
              loadingTopFive={loadingTopFive}
              textErrorTopFive={textErrorTopFive}
              yearMouthTopFive={yearMouthTopFive}
              onChangeYearMouthTopFive={onChangeYearMouthTopFive}
              openMenuEventTopFive={openMenuEventTopFive}
              setOpenMenuEventTopFive={setOpenMenuEventTopFive}
              onButtonFilterTopFive={onButtonFilterTopFive}
              animationTopFive={animationTopFive}
              setAnimationTopFive={setAnimationTopFive}
              setLastDays={setLastDays}
              setLastMonths={setLastMonths}
              lastYearMouthTopFive={lastYearMouthTopFive}
              setYearMouthTopFive={setYearMouthTopFive}
            />
          </div>
        </Grid>

        <div className={styles.divider} />

        {!isMobile && (
          <Grid item className={styles.extractArea} sm={4} md={4} lg={4}>
            <div className={styles.sliderArea}>
              <Slider />
            </div>
            <Extract />
          </Grid>
        )}
      </Grid>

      <FeedbackModal
        open={openFeedbackModal}
        description="Como você avalia este produto?"
        text="Em uma escala de 1 a 10, o quanto você indicaria a Somapay para um amigo ou colega?"
        sendButtonText="Enviar"
        onClick={handleSendFeedback}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        feedbackTextField={feedbackTextField}
        loading={loadingFeedbackModal}
      />

      <OnlinePointChargeModal
        open={openOnlinePointChargeModal}
        charges={charges}
        onClick={() => setOnlinePointChargeModal(false)}
      />

      <AlreadyUploadModal
        open={alreadyUploadModal}
        onConfirmClicked={goToProcess}
        onCancelButtonClicked={() => setAlreadyUploadModal(false)}
        confirmButtonText="Sim"
        cancelButtonText="Não"
        title="Já existe um arquivo em processamento. Deseja acompanhar seu status?"
        text="Você só poderá iniciar um novo cadastro, quando o que está em andamento for concluído."
      />

      <ContractTermsModal
        open={openContractTermsModal}
        onConfirmClicked={handleAcceptContract}
        loading={loading}
        planInformations={planInformations}
        loadingContract={loadingContract}
      />

      <SuccessModal
        open={openSuccessModal}
        title="Pronto, seu plano está atualizado!"
        text="Concluímos a atualização da sua nova forma de cobrança,
        que apenas tomará efeito ao fim do periodo promocional."
        onCloseDialog={() => setOpenSuccessModal(false)}
      />

      <SuccessModal
        open={openFeedbackSuccessModal}
        title="Sua avaliação foi enviada!"
        onCloseDialog={() =>
          setOpenFeedbackSuccessModal(prevState => !prevState)
        }
      />

      <PrimaryModal
        open={openPrimaryModal}
        onConfirmClicked={() => setOpenPrimaryModal(false)}
        confirmButtonText="Entendi"
        title={titlePrimaryModal}
        text={textPrimaryModal}
      />

      <HomeModals />

      <Hidden mdUp>
        <FloatButton actions={actions} />
      </Hidden>
    </>
  );
};

export default Home;
