import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Header,
  Filters,
  WrapperPlanActive,
  Table,
  EmployeesListModal,
  MobileList,
} from './UI';

import { api } from '../../services/api';
import { TablePaginationActions } from './UI/Table/UI';

import verifyNumberInString from '../../utils/verifyNumberInString';
import LogoLoading from '../../components/LogoLoading';
import ResultNotFound from '../../components/ResultNotFound';
import ModalDeleteEmployee from '../../components/PrimaryModal';
import { isMobile } from '../../utils/breakpoints';

function OnlinePointEmployees() {
  const companyCode = sessionStorage.getItem('currentCompanyCode');

  const { situationPointPlan } = useSelector(
    state => state.situationPointPlan.data,
  );

  const [loading, setLoading] = useState(true);
  const [loadingLogo, setLoadingLogo] = useState(true);
  const [loadingButtonDelete, setLoadingButtonDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [employeesOnlinePoint, setEmployeesOnlinePoint] = useState([]);
  const [openEmployeesListModal, setOpenEmployeesListModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [
    searchCostAndCenterOnlinePoint,
    setSearchCostAndCenterOnlinePoint,
  ] = useState('Todos');
  const [costAndCenterOnlinePoint, setCostAndCenterOnlinePoint] = useState([]);
  const [searchSituation, setSearchSituation] = useState('Todos');
  const [cpfOrName, setCpfOrName] = useState('');
  const [changedFilter, setChangedFilter] = useState(false);
  const [openModalDeleteEmployee, setOpenModalDeleteEmployee] = useState(false);
  const [openSnackErrorOrSucess, setOpenSnackErrorOrSucess] = useState(false);
  const [isErrorSnack, setIsErrorSnack] = useState(false);
  const [isItemDeleted, setIsItemDeleted] = useState(false);

  useEffect(() => {
    getEmployeesOnlinePoint();
  }, [pageSize, currentPage, searchSituation, searchCostAndCenterOnlinePoint]);

  useEffect(() => {
    getCostAndCenterOnlinePoint();
  }, []);

  useEffect(() => {
    if (changedFilter) {
      const delay = setTimeout(() => {
        getEmployeesOnlinePoint();
      }, 500);

      return () => clearTimeout(delay);
    }
  }, [cpfOrName]);

  function getCostAndCenterOnlinePoint() {
    api.get(`companies/${companyCode}/locations`).then(({ data }) => {
      setCostAndCenterOnlinePoint(data);
    });
  }

  function getEmployeesOnlinePoint() {
    setLoading(true);

    const isNumber = verifyNumberInString(cpfOrName);
    const requestOptions = {
      params: {
        page: currentPage,
        size: pageSize,
        cpf: isNumber ? cpfOrName : '',
        name: !isNumber ? cpfOrName : '',
        location:
          (searchCostAndCenterOnlinePoint !== 'Todos' &&
            searchCostAndCenterOnlinePoint) ||
          null,
        status: (searchSituation !== 'Todos' && searchSituation) || null,
        pontoGoUser: true,
      },
    };

    api
      .get(`/employee/search/${companyCode}`, requestOptions)
      .then(({ data }) => {
        setTotalSize(data.totalSize);
        setCurrentPageSize(data.currentPageSize);
        setEmployeesOnlinePoint(data.content);
      })
      .finally(() => {
        setLoading(false);
        setLoadingLogo(false);
      });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function handleChangeCostAndCenter(costCenterSearch) {
    setSearchCostAndCenterOnlinePoint(costCenterSearch);
    setChangedFilter(true);
  }

  function handleChangeSituation(event) {
    setSearchSituation(event.target.value);
    setChangedFilter(true);
  }

  function onSearchChange(event) {
    setCpfOrName(event?.target?.value);
    setChangedFilter(true);
  }

  function handleChangePage(event, newPage) {
    setCurrentPage(newPage);
    setChangedFilter(true);
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  }

  function handleClearFilters() {
    setLoading(true);
    setSearchCostAndCenterOnlinePoint('Todos');
    setSearchSituation('Todos');
    setCpfOrName('');
  }

  function handleDeleteEmployeeOnlinePoint() {
    const employeeDelete = sessionStorage.getItem('employeeCpfDelete');
    setLoadingButtonDelete(true);
    api
      .delete(`/pontoGO/removeEmployee/${employeeDelete}/${companyCode}`)
      .then(() => {
        setIsErrorSnack(false);
        setIsItemDeleted(true);
        setTimeout(() => {
          getEmployeesOnlinePoint();
        }, 2000);
      })
      .catch(() => {
        setIsErrorSnack(true);
      })
      .finally(() => {
        setLoadingButtonDelete(false);
        handleCloseModalDeleteEmployee();
        setOpenSnackErrorOrSucess(true);
      });
  }

  function handleCloseModalDeleteEmployee() {
    setOpenModalDeleteEmployee(false);
    setTimeout(() => {
      sessionStorage.removeItem('employeeCpfDelete');
    }, 200);
  }

  function handleOpenModalDeleteEmployee(employeeCpf) {
    sessionStorage.setItem('employeeCpfDelete', employeeCpf);

    setOpenModalDeleteEmployee(true);
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <>
      {loadingLogo ? (
        <LogoLoading />
      ) : (
        <>
          <Header onClick={() => setOpenEmployeesListModal(true)} />
          <EmployeesListModal
            getEmployeesOnlinePoint={getEmployeesOnlinePoint}
            open={openEmployeesListModal}
            selected={selected}
            setSelected={setSelected}
            setOpenEmployeesListModal={setOpenEmployeesListModal}
            isItemDeleted={isItemDeleted}
          />
          {employeesOnlinePoint.length === 0 &&
          !changedFilter &&
          situationPointPlan ? (
            <WrapperPlanActive
              situationPlan={situationPointPlan}
              handleAddEmployees={() => setOpenEmployeesListModal(true)}
            />
          ) : (
            <>
              <Filters
                employeeSituation={searchSituation}
                costAndCenterOnlinePoint={costAndCenterOnlinePoint}
                handleChangeCostAndCenter={handleChangeCostAndCenter}
                handleChangeSituation={handleChangeSituation}
                onSearchChange={onSearchChange}
                cpfOrName={cpfOrName}
                setCpfOrName={setCpfOrName}
                searchCostAndCenterOnlinePoint={searchCostAndCenterOnlinePoint}
              />
              {employeesOnlinePoint.length === 0 && changedFilter ? (
                <ResultNotFound
                  text="Nenhum Resultado Encontrado"
                  alternativeText="Verifique os dados pesquisados e tente novamente."
                  onClick={handleClearFilters}
                  buttonText="Limpar Filtros"
                />
              ) : isMobile ? (
                <MobileList
                  employeesOnlinePoint={employeesOnlinePoint}
                  loading={loading}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                  handleOpenModalDeleteEmployee={handleOpenModalDeleteEmployee}
                  currentPageSize={currentPageSize}
                />
              ) : (
                <Table
                  handleRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                  loading={loading}
                  changedFilter={changedFilter}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  totalSize={totalSize}
                  currentPageSize={currentPageSize}
                  rows={employeesOnlinePoint}
                  getComparator={getComparator(order, orderBy)}
                  stableSort={stableSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  TablePaginationActions={TablePaginationActions}
                  handleOpenModalDeleteEmployee={handleOpenModalDeleteEmployee}
                />
              )}
            </>
          )}
        </>
      )}
      <ModalDeleteEmployee
        open={openModalDeleteEmployee}
        onCloseDialog={handleCloseModalDeleteEmployee}
        title="Cancelar este colaborador?"
        text="Esta ação não poderá ser desfeita."
        errorMessage={`CPF: ${sessionStorage.getItem('employeeCpfDelete')}`}
        cancelButtonText="Sair"
        onCancelButtonClicked={handleCloseModalDeleteEmployee}
        confirmButtonText="Confirmar"
        onConfirmClicked={handleDeleteEmployeeOnlinePoint}
        loading={loadingButtonDelete}
      />

      <Snackbar
        open={openSnackErrorOrSucess}
        autoHideDuration={5000}
        onClose={() => setOpenSnackErrorOrSucess(false)}
      >
        <Alert
          severity={isErrorSnack ? 'error' : 'success'}
          onClose={() => setOpenSnackErrorOrSucess(false)}
        >
          {isErrorSnack
            ? 'Não foi possivel cancelar colaborador, tente novamente.'
            : 'Colaborador cancelado com sucesso!'}
        </Alert>
      </Snackbar>
    </>
  );
}

export default OnlinePointEmployees;
