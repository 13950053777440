import React from 'react';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import { ReplayRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import LinearProgressWithLabel from '../../ProgressBarWithLabel';
import Buttons from '../../Buttons';

import { ReactComponent as NotificationError } from '../../../assets/notificationError.svg';
import { ReactComponent as NotificationSuccess } from '../../../assets/notificationSuccess.svg';
import { ReactComponent as ArrowRight } from '../../../assets/chevron-right.svg';

import {
  useStartCnabFileGenerationMutation,
  useGetCnabFileDownloadMutation,
} from '../../../redux/store/FileData/fileData.api';

import { pusherStatus as status } from '../../../enums/pusher';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

import useStyles from '../styles';

export default function useNotificationMenu({
  uploadDataEmployees,
  uploadDataInsurance,
  uploadDataPaycheck,
  uploadDataPayroll,
  onClickNotification,
  handleClickItem,
  handleClickItemEmployees,
  handleCloseNotification,
}) {
  const classes = useStyles();
  const { success, error } = handleSnackbarStack();

  const [handleDownloadFile] = useGetCnabFileDownloadMutation();
  const [handleDownloadRetry] = useStartCnabFileGenerationMutation();

  const cnabDownloadData = useSelector(
    state => state.fileData.cnabDownloadData,
  );

  const onDownloadCnab = async (nsu, hasError) => {
    if (hasError) return;

    const file = cnabDownloadData.find(data => data.nsu === nsu);

    await handleDownloadFile({ url: `${file.data}` })
      .unwrap()
      .then(res => {
        if (res) {
          const downloadUrl = window.URL.createObjectURL(new Blob([res]));

          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', `${file.data.substring(80)}`);

          document.body.appendChild(link);

          link.click();
          link.remove();

          success('Download concluído com sucesso!');
          handleCloseNotification();
        }
      })
      .catch(err => error(err.message));
  };

  const onRetryDownload = async nsu => {
    handleCloseNotification();

    await handleDownloadRetry({ nsu })
      .unwrap()
      .catch(err => error(err.message));
  };

  const renderTitle = title => {
    return (
      <Typography className={classes.menuNotificationTitle}>{title}</Typography>
    );
  };

  const renderError = (hasError, description) => {
    return (
      <Grid item>
        <Typography
          className={
            hasError
              ? classes.statusDescriptionError
              : classes.statusDescriptionSuccess
          }
        >
          {description}
        </Typography>
      </Grid>
    );
  };

  const renderNotificationMenuItem = () => {
    if (uploadDataPayroll) {
      const {
        status: payrollStatus,
        statusDescription,
        percentage,
      } = uploadDataPayroll;

      const hasError = payrollStatus === status.PROCESSED_PAYROLL_ERROR;
      const hasSuccess = payrollStatus === status.PROCESSED_PAYROLL_SUCCESS;

      return (
        <MenuItem
          onClick={handleClickItem}
          className={classes.menuNotification}
        >
          <div>
            {renderTitle(uploadDataPayroll?.titleProcess)}

            {hasError ? (
              <div style={{ display: 'flex' }}>
                <NotificationError style={{ marginTop: 5, marginRight: 5 }} />
                <Typography className={classes.statusDescriptionError}>
                  {statusDescription}
                </Typography>
              </div>
            ) : null}

            {hasSuccess ? (
              <div style={{ display: 'flex' }}>
                <NotificationSuccess style={{ marginTop: 4, marginRight: 5 }} />
                <Typography className={classes.statusDescriptionSuccess}>
                  {statusDescription}
                </Typography>
              </div>
            ) : null}

            {!hasError && !hasSuccess ? (
              <>
                <div className={classes.linearProgress}>
                  <LinearProgressWithLabel value={percentage || 0} />
                </div>
                <div style={{ display: 'flex' }}>
                  <Typography className={classes.statusDescriptionDefault}>
                    {statusDescription}
                  </Typography>
                </div>
              </>
            ) : null}

            <div>
              <ArrowRight />
            </div>
          </div>
        </MenuItem>
      );
    }

    if (uploadDataEmployees) {
      const {
        percentage,
        status: employeesStatus,
        statusDescription,
        titleProcess,
      } = uploadDataEmployees;

      const hasError = employeesStatus === status.PROCESSED_EMPLOYEES_ERROR;
      const hasSuccess = employeesStatus === status.PROCESSED_EMPLOYEES_SUCCESS;

      return (
        <MenuItem
          onClick={handleClickItemEmployees}
          className={classes.menuNotification}
        >
          <div>
            {renderTitle(titleProcess)}

            {hasError ? (
              <div style={{ display: 'flex' }}>
                <NotificationError style={{ marginTop: 5, marginRight: 5 }} />
                <Typography className={classes.statusDescriptionError}>
                  {statusDescription}
                </Typography>
              </div>
            ) : null}

            {hasSuccess ? (
              <div style={{ display: 'flex' }}>
                <NotificationSuccess style={{ marginTop: 4, marginRight: 5 }} />
                <Typography className={classes.statusDescriptionSuccess}>
                  {statusDescription}
                </Typography>
              </div>
            ) : null}

            {!hasError && !hasSuccess ? (
              <>
                <div className={classes.linearProgress}>
                  <LinearProgressWithLabel value={percentage || 0} />
                </div>
                <div style={{ display: 'flex' }}>
                  <Typography className={classes.statusDescriptionDefault}>
                    {statusDescription}
                  </Typography>
                </div>
              </>
            ) : null}
          </div>
          <div>
            <ArrowRight />
          </div>
        </MenuItem>
      );
    }

    if (uploadDataPaycheck) {
      const {
        percentage,
        status: paycheckStatus,
        statusDescription,
        titleProcess,
      } = uploadDataPaycheck;

      const hasError = paycheckStatus === status.PROCESSED_PAYCHECK_ERROR;

      return (
        <MenuItem
          onClick={() => onClickNotification('paycheck')}
          className={classes.menuNotification}
        >
          <div>
            {renderTitle(titleProcess)}

            {paycheckStatus === status.PROCESSING ? (
              <>
                <LinearProgressWithLabel
                  value={percentage || 0}
                  className={classes.linearProgress}
                />

                <Typography className={classes.statusDescriptionDefault}>
                  {statusDescription}
                </Typography>
              </>
            ) : (
              <Grid container>
                <Grid item>
                  <Box mr={1} mt={0.1}>
                    {hasError ? <NotificationError /> : <NotificationSuccess />}
                  </Box>
                </Grid>

                {renderError(hasError, uploadDataPaycheck?.statusDescription)}
              </Grid>
            )}
          </div>

          <ArrowRight />
        </MenuItem>
      );
    }

    if (uploadDataInsurance) {
      const {
        titleProcess,
        status: insuranceStatus,
        percentage,
        statusDescription,
      } = uploadDataInsurance;

      const hasError = insuranceStatus === status.PROCESSED_INSURANCES_ERROR;

      return (
        <MenuItem
          onClick={() => onClickNotification('insurance')}
          className={classes.menuNotification}
        >
          <div>
            {renderTitle(titleProcess)}

            {uploadDataInsurance?.status === status.PROCESSING ? (
              <>
                <LinearProgressWithLabel
                  value={percentage || 0}
                  className={classes.linearProgress}
                />

                <Typography className={classes.statusDescriptionDefault}>
                  {statusDescription}
                </Typography>
              </>
            ) : (
              <Grid container>
                <Grid item>
                  <Box mr={1} mt={0.1}>
                    {hasError ? <NotificationError /> : <NotificationSuccess />}
                  </Box>
                </Grid>

                {renderError(hasError, uploadDataInsurance?.statusDescription)}
              </Grid>
            )}
          </div>
          <ArrowRight />
        </MenuItem>
      );
    }

    if (cnabDownloadData && cnabDownloadData?.length) {
      return cnabDownloadData.map(file => {
        const { nsu, status: cnabStatus, percentage, statusDescription } = file;

        const hasError = cnabStatus === status.CANCELED;

        return (
          <MenuItem
            key={nsu}
            onClick={() => onDownloadCnab(nsu, hasError)}
            className={classes.menuNotification}
          >
            <div>
              {renderTitle('Download CNAB 240')}
              <Typography component="h6" variant="caption">
                NSU: {nsu}
              </Typography>

              {cnabStatus === status.PROCESSING ? (
                <>
                  <LinearProgressWithLabel
                    value={percentage || 0}
                    className={classes.linearProgress}
                  />

                  <Typography className={classes.statusDescriptionDefault}>
                    {statusDescription}
                  </Typography>
                </>
              ) : (
                <Grid container>
                  <Grid item>
                    <Box
                      mr={1}
                      mt={0.1}
                      className={classes.cnabStatusContainer}
                    >
                      {hasError ? (
                        <>
                          <NotificationError />
                          {renderError(
                            hasError,
                            'Ocorreu um erro ao processar o arquivo.',
                          )}
                          <Buttons.PrimaryButton
                            variant="text"
                            size="small"
                            color="primary"
                            title="Tentar novamente"
                            className={classes.retryButton}
                            icon={<ReplayRounded color="primary" />}
                            onClick={() => onRetryDownload(nsu)}
                          />
                        </>
                      ) : (
                        <>
                          <NotificationSuccess />
                          <Grid item>
                            <Typography
                              className={classes.statusDescriptionSuccess}
                            >
                              Arquivo processado com sucesso! Clique para fazer
                              o download.
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              )}
            </div>
          </MenuItem>
        );
      });
    }

    return null;
  };

  return {
    cnabDownloadData,
    renderNotificationMenuItem,
  };
}
