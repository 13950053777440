import { makeStyles, createStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
      borderRadius: '6px',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
      marginBottom: theme.spacing(8),
    },
    title: {
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h6.fontSize,
    },
    alert: {
      marginBottom: theme.spacing(2),
    },
    label: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1.fontSize,
    },
    viewButton: {
      marginBottom: theme.spacing(3),
    },
    dropzoneBase: {
      width: '100%',
      borderRadius: '4px',
      padding: theme.spacing(3),
      marginBottom: theme.spacing(1),
      display: 'flex',
      gap: theme.spacing(1),
      flexFlow: 'column nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    dropzoneIdle: {
      border: `1px dashed ${theme.palette.text.secondary}`,
    },
    dropzoneActive: {
      border: `1px dashed ${theme.palette.primary.main}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
    dropzoneData: {
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column nowrap',
        gap: theme.spacing(2),
      },
    },
    dropzoneBackground: props => ({
      transition: 'background 1s ease-out',
      background: `linear-gradient(90deg, ${alpha(
        theme.palette.primary.main,
        0.1,
      )} ${props.progress}%, #FFF 0%)`,
    }),
    avatar: {
      backgroundColor: '#EEE8F2',
    },
    lengthContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        gap: theme.spacing(1),
      },
    },
    largeAvatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    largeIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    length: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h6.fontSize,
    },
    progressContainer: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      gap: theme.spacing(1),
    },
    percentage: {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.body2.fontSize,
      color: '#666',
    },
    clickText: {
      color: theme.palette.primary.main,
    },
    previewList: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: theme.spacing(1),
    },
    filePreview: {
      width: `100%`,
      padding: theme.spacing(2),
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    fileIcon: {
      width: theme.typography.h3.fontSize,
      height: theme.typography.h3.fontSize,
      margin: 'auto 0',

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    actionIcon: {
      width: '2rem',
      height: '2rem',
    },
    fileName: {
      flexGrow: 1,
      display: 'flex',
      flexFlow: 'row nowrap',
      width: '100%',
      gap: theme.spacing(1),
      justifyContent: 'space-between',

      '& > .MuiBox-root': {
        display: 'flex',
        gap: theme.spacing(1),
      },

      '& p': {
        margin: 'auto 0',
      },
    },
    fileDataContainer: {
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: '4px',
    },
    fileProgress: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
      '& .MuiBox-root:first-child': {
        width: '100%',
      },

      '& span': {
        fontSize: '1.125rem',
        padding: '0 12px',

        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
    },

    dialog: {
      padding: theme.spacing(4),
    },
    dialogTitle: {
      padding: 0,
    },
    dialogContent: {
      padding: `${theme.spacing(1)}px 0`,
    },
    confirmScreenSubtitle: {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      color: '#666',
      marginBottom: theme.spacing(3),
    },
    previewCardsList: {
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(8),
    },
    previewCard: {
      padding: theme.spacing(3),
    },
    progressBar: {
      height: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
      borderRadius: '8px',
      margin: `${theme.spacing(1)}px 0`,
    },
  }),
);
