import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { ReactComponent as FileError } from '../../../../assets/fileError.svg';
import { styles } from '../../PayrollDetails/styles';

export default ({ onBackClickedErrorComponent }) => {
  return (
    <Grid container style={styles.gridStyle}>
      <FileError />
      <Typography style={styles.titleStyle}>
        Oops! Não conseguimos exibir as informações.
      </Typography>
      <Typography style={styles.thinTextStyle}>
        Em caso de dúvidas, entre em contato com nossa Central de
        Relacionamento.
      </Typography>
      <Button
        variant="outlined"
        color="#5C2483"
        style={styles.backButton}
        onClick={onBackClickedErrorComponent}
      >
        Voltar
      </Button>
    </Grid>
  );
};
