import React from "react";

import { Box, Grid, Typography } from "@material-ui/core";

import styles from './styles.module.css';

import convertBrazilianDate from "../../../../../utils/convertBrazilianDate";
import brazilianCurrencyFormatter from "../../../../../utils/brazilianCurrencyFormatter";

function Resume({ charge }) {
    return (
        <Box mt={4} className={styles.card}>
            <Typography className={styles.biggerText}>Resumo</Typography>

            <Grid container spacing={2} className={styles.cardLine} justifyContent='space-between'>
                <Grid item>
                    <Typography className={styles.label}>
                        Situação
                    </Typography>
                    <Typography className={styles.field}>
                        {charge.status}
                    </Typography>
                </Grid>
                
                <Grid item>
                    <Typography className={styles.label}>
                        Criada em
                    </Typography>
                    <Typography className={styles.field}>
                        {convertBrazilianDate(charge.createdAt)}
                    </Typography>
                </Grid>
                
                <Grid item>
                    <Typography className={styles.label}>
                        Funcionários
                    </Typography>
                    <Typography className={styles.field}>
                        {charge.employeeQuantity}
                    </Typography>
                </Grid>
                
                <Grid item>
                    <Typography className={styles.label}>
                        Taxa por funcionário
                    </Typography>
                    <Typography className={styles.field}>
                        {charge.feePerEmployee === 0 ? "R$ 0,00" : brazilianCurrencyFormatter(charge.feePerEmployee)}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Resume;