import React from "react";

import { Box, Grid, Typography } from "@material-ui/core";

import styles from './styles.module.css';

import convertBrazilianDate from "../../../../../utils/convertBrazilianDate";
import brazilianCurrencyFormatter from "../../../../../utils/brazilianCurrencyFormatter";

function Charge({ charge }) {
    return (
        <Box mt={4} className={styles.card}>
            <Typography className={styles.biggerText}>Fatura</Typography>

            <Grid container spacing={2} className={styles.cardLine} justifyContent='space-between'>
                <Grid item>
                    <Typography className={styles.label}>
                        Competência
                    </Typography>
                    <Typography className={styles.field}>
                        {charge.referenceMonth}
                    </Typography>
                </Grid>
                
                <Grid item>
                    <Typography className={styles.label}>
                        Valor
                    </Typography>
                    <Typography className={styles.field}>
                        {charge.amount === 0 ? "R$ 0,00" : brazilianCurrencyFormatter(charge.amount)}
                    </Typography>
                </Grid>
                
                <Grid item>
                    <Typography className={styles.label}>
                        Vencimento
                    </Typography>
                    <Typography className={styles.field}>
                        {convertBrazilianDate(charge.dueDate)}
                    </Typography>
                </Grid>
                
                <Grid item>
                    <Typography className={styles.label}>
                        Pagamento em
                    </Typography>
                    <Typography className={styles.field}>
                        {charge?.paidAt ? convertBrazilianDate(charge.paidAt) : '-'}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Charge;