import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DeleteIcon } from '../../../../assets/removeItem.svg';

import FakeList from '../../../../components/FakeList';

import styles from './styles.module.css';
import capitalize from '../../../../utils/textCapitalize';

function MobileList({
  employeesOnlinePoint,
  loading,
  setPageSize,
  pageSize,
  handleOpenModalDeleteEmployee,
  currentPageSize,
}) {
  const [fetchMoreData, setFetchMoreData] = useState(false);

  function fetchData() {
    setPageSize(pageSize + 10);
    setFetchMoreData(true);
  }

  return loading && !fetchMoreData ? (
    <FakeList />
  ) : (
    <div className={styles.container}>
      <InfiniteScroll
        dataLength={employeesOnlinePoint.length}
        next={fetchData}
        hasMore
        loader={
          fetchData && currentPageSize < pageSize - 5 ? (
            <div className={styles.circularProgressContainer}>
              <CircularProgress size={25} className={styles.circularProgress} />
            </div>
          ) : (
            ''
          )
        }
      >
        {employeesOnlinePoint?.map(item => (
          <div className={styles.card}>
            <div>
              <Typography className={styles.registration}>
                Matrícula: {item.companies[0]?.registration}
              </Typography>
              <Typography className={styles.cpf}>{item.cpf}</Typography>
              <Typography className={styles.name}>
                {capitalize(item.name)}
              </Typography>
            </div>

            {item?.pontoGoStatus === 'Ativo' ? (
              <IconButton
                onClick={() => handleOpenModalDeleteEmployee(item.cpf)}
              >
                <DeleteIcon />
              </IconButton>
            ) : (
              ''
            )}
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
}

export default MobileList;
