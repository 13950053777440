import React, { useEffect, useState } from 'react';

import { Divider } from '@material-ui/core';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';

import styles from './styles.module.css';

import Modal from './Modal';
import videos from './videos.json';

import LogoLoading from '../../components/LogoLoading';
import Title from '../../components/Title';

function SomapayAcademy() {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingVideo, setLoadingVideo] = useState(false);

  const [urlVideo, setUrlVideo] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2600);
  }, []);

  function onClickOnVideo(video) {
    setUrlVideo(video?.url);
    setLoadingVideo(true);
    setOpenModal(true);

    setTimeout(() => {
      setLoadingVideo(false);
    }, 1501);
  }

  if (loading) {
    return <LogoLoading />;
  }

  return (
    <>
      <div className={styles.container}>
        <Title>Somapay academy</Title>

        {videos?.categories?.map(row => (
          <div className={styles.categories}>
            <p className={styles.categoriesTitle}>{row.title}</p>

            <div className={styles.containerVideos}>
              {row.videos.map(video => (
                <div className={styles.videoInfo}>
                  <div
                    className={styles.video}
                    onClick={() => onClickOnVideo(video)}
                  >
                    <div className={styles.videoPlayer}>
                      <PlayArrowRoundedIcon className={styles.iconPlay} />
                    </div>
                  </div>

                  <p className={styles.description}>{video?.description}</p>
                </div>
              ))}
            </div>

            <Divider color="#E0E0E0" className={styles.divider} />
          </div>
        ))}
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        url={urlVideo}
        loadingVideo={loadingVideo}
      />
    </>
  );
}

export default SomapayAcademy;
