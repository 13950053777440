import { useModal } from '@ebay/nice-modal-react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { api } from '../../../../../../services/api';
import { handleRequestError } from '../../../../../../utils/handleRequestError';
import { handleSnackbarStack } from '../../../../../../utils/handleSnackbarStack';

export function useNpsModal({ currentCompanyCode = '', userName = '' }) {
  const modal = useModal();

  const [isLoading, setIsLoading] = useState(false);

  const { control, reset, watch, getValues } = useForm({
    defaultValues: {
      productRating: 10,
      indicationRating: 10,
      feedback: '',
    },
  });

  watch();

  const productRatingOptions = [
    { label: 'Excelente', value: 10 },
    { label: 'Ótimo', value: 9 },
    { label: 'Muito Bom', value: 8 },
    { label: 'Bom', value: 7 },
    { label: 'Razoável', value: 6 },
    { label: 'Mediano', value: 5 },
    { label: 'Insatisfatório', value: 4 },
    { label: 'Abaixo do Esperado', value: 3 },
    { label: 'Ruim', value: 2 },
    { label: 'Bastante Ruim', value: 1 },
    { label: 'Horrível', value: 0 },
  ];

  const indicationRatingOptions = [
    { label: 'Com certeza indicaria', value: 10 },
    { label: 'Quase certeza que indicaria', value: 9 },
    { label: 'Muito provável de indicar', value: 8 },
    { label: 'Provável de indicar', value: 7 },
    { label: 'Levemente provável de indicar', value: 6 },
    { label: 'Neutro', value: 5 },
    { label: 'Indicação improvável', value: 4 },
    { label: 'Pouco provável de indicar', value: 3 },
    { label: 'Improvável de indicar', value: 2 },
    { label: 'Muito improvável de indicar', value: 1 },
    { label: 'Com certeza não indicaria', value: 0 },
  ];

  const handleCloseModal = () => {
    modal.hide();

    setTimeout(() => {
      reset();
    }, 2000);
  };

  const onSubmitNps = () => {
    setIsLoading(false);

    const body = {
      ...getValues(),
      userCompany: userName,
    };

    api
      .post(`/opinion-avaliation/${currentCompanyCode}`, body)
      .then(() => {
        handleSnackbarStack().success('Seu feedback foi enviado com sucesso!');
      })
      .catch(error => {
        handleRequestError(error, 'Houve um erro ao enviar a pesquisa');
      })
      .finally(() => {
        setIsLoading(false);
        modal.hide();

        setTimeout(() => {
          reset();
        }, 2000);
      });
  };

  return {
    modal: {
      open: modal.visible,
      onClose: handleCloseModal,
    },
    form: {
      reset,
      productRatingOptions,
      indicationRatingOptions,
      control,
      onSubmitNps,
      isLoading,
    },
  };
}
