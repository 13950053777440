const pusherKeys = {
  APP_KEY: process.env.REACT_APP_KEY,
  APP_CLUSTER: 'mt1',
};

const pusherStatus = {
  PROCESSED_PAYROLL_SUCCESS: 'PROCESSED_PAYROLL_SUCCESS',
  PROCESSED_PAYROLL_ERROR: 'PROCESSED_PAYROLL_ERROR',

  PROCESSED_EMPLOYEES_SUCCESS: 'PROCESSED_EMPLOYEES_SUCCESS',
  PROCESSED_EMPLOYEES_ERROR: 'PROCESSED_EMPLOYEES_ERROR',

  PROCESSED_PAYCHECK_SUCCESS: 'PROCESSED_PAYCHECK_SUCCESS',
  PROCESSED_PAYCHECK_ERROR: 'PROCESSED_PAYCHECK_ERROR',

  PROCESSED_INSURANCES_SUCCESS: 'PROCESSED_INSURANCES_SUCCESS',
  PROCESSED_INSURANCES_ERROR: 'PROCESSED_INSURANCES_ERROR',

  PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_SUCCESS:
    'PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_SUCCESS',
  PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_ERROR:
    'PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_ERROR',

  PROCESSING: 'PROCESSING',
  CANCELED: 'CANCELED',
  PROCESSED: 'PROCESSED',

  SALARY_ANTICIPATION_REPORT_FINISHED: 'SALARY_ANTICIPATION_REPORT_FINISHED',
};

const pusherChannels = {
  PROCESSED_PAYCHECK: 'PROCESSED_PAYCHECK',
  PROCESSED_PAYCHECK_REPORT: 'PROCESSED_PAYCHECK_REPORT',

  PROCESSED_PAYROLL: 'PROCESSED_PAYROLL',

  PROCESSED_EMPLOYEES: 'PROCESSED_EMPLOYEES',

  PROCESSED_INSURANCES: 'PROCESSED_INSURANCES',
  INSURANCES_REGISTER_CONFIRMATION: 'INSURANCES_REGISTER_CONFIRMATION',

  PONTO_GO_EMPLOYEE_REGISTER: 'PONTO_GO_EMPLOYEE_REGISTER',

  SALARY_ANTICIPATION_REPORT_FINISHED: 'SALARY_ANTICIPATION_REPORT_FINISHED',

  CONSOLIDATED_VALUES_EMPLOYEES_REPORT_FINISHED:
    'CONSOLIDATED_VALUES_EMPLOYEES_REPORT_FINISHED',

  CONSOLIDATED_VALUES_EMPLOYEES_REPORT_PROCESSING:
    'CONSOLIDATED_VALUES_EMPLOYEES_REPORT_PROCESSING',

  CONSOLIDATED_VALUES_LOANS_REPORT_FINISHED:
    'CONSOLIDATED_VALUES_LOANS_REPORT_FINISHED',

  CONSOLIDATED_VALUES_LOANS_REPORT_PROCESSING:
    'CONSOLIDATED_VALUES_LOANS_REPORT_PROCESSING',

  CONSULT_LIMIT_REPORT_FINISHED: 'CONSULT_LIMIT_REPORT_FINISHED',

  CONSULT_LIMIT_REPORT_PROCESSING: 'CONSULT_LIMIT_REPORT_PROCESSING',

  PROCESSED_EMPLOYEES_LIMITS: 'PROCESSED_EMPLOYEES_LIMITS',
};

const pusherEvents = {
  RECEIPT_PAYMENT: 'RECEIPT_PAYMENT',
  RETURN_PAYMENT_FILE_CNAB_240: 'RETURN_PAYMENT_FILE_CNAB_240',
};

export { pusherKeys, pusherStatus, pusherChannels, pusherEvents };
