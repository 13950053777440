import React from 'react';

import { CircularProgress, Dialog } from '@material-ui/core';

import styles from './styles.module.css';

import { isMobile } from '../../utils/breakpoints';

function VideoModal({ open, onClose, url, loadingVideo }) {
  const urlYoutubeId = url.split('v=')[1]?.substring(0, 11);
  const source = `https://www.youtube.com/embed/${urlYoutubeId}`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={isMobile ? 'xm' : 'sm'}
    >
      {loadingVideo ? (
        <div className={styles.circularProgressContainer}>
          <CircularProgress />
        </div>
      ) : (
        <iframe
          title={source}
          height={isMobile ? '228px' : '412.85px'}
          src={source}
          frameBorder="0"
          allowFullScreen
        />
      )}
    </Dialog>
  );
}

export default VideoModal;
