import React from 'react';
import moment from 'moment';

import { Typography, Grid, IconButton } from '@material-ui/core';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';

import FakeCards from './UI/FakeCards';
import { isMobile } from '../../utils/breakpoints';

import styles from './styles.module.css';

export default ({
  companyName,
  payrollAmount,
  date,
  fee,
  totalAmount,
  fileName,
  loading,
  paymentTypeDescription,
  title,
  signatureValue,
  chargeSignature,
  hidden,
  setChangeSeetDataModal,
  nsu,
  depositAccount,
  hasMoreThanOneDepositAccount,
}) => (
  <>
    {loading && !hidden ? (
      <FakeCards />
    ) : (
      <>
        <Grid
          container
          noWrap
          direction={isMobile ? 'column' : 'row'}
          spacing={2}
        >
          <Grid item xs={12} sm={8}>
            <div className={styles.infoCard}>
              <Typography
                className={isMobile ? styles.hidden : styles.titleCard}
              >
                {title}
              </Typography>
              <Grid container item spacing={3}>
                {nsu && (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    className={!nsu && isMobile && styles.hidden}
                  >
                    <div className={styles.containerInColumn}>
                      <Typography className={styles.title}>NSU</Typography>
                      <Typography className={styles.description}>
                        {nsu}
                      </Typography>
                    </div>
                  </Grid>
                )}

                <Grid item xs={12} sm={nsu ? 3 : 4}>
                  <div className={styles.containerInColumn}>
                    <Typography className={styles.title}>
                      Nome do arquivo
                    </Typography>
                    <Typography className={styles.description}>
                      {fileName}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={nsu ? 3 : 4}>
                  <div className={styles.containerInColumn}>
                    <Typography className={styles.title}>Empresa</Typography>
                    <Typography className={styles.description}>
                      {companyName}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={nsu ? 3 : 4}>
                  <div className={styles.containerInColumn}>
                    <Typography className={styles.title}>
                      Data de agendamento
                    </Typography>
                    <Typography className={styles.description}>
                      {moment(date)?.format('DD/MM/YYYY')} às{' '}
                      {moment(date).format('LT')}
                    </Typography>
                  </div>
                </Grid>

                {hasMoreThanOneDepositAccount && (
                  <Grid item xs={6} md={nsu ? 3 : 4} lg={nsu ? 3 : 4}>
                    <div className={styles.containerInColumn}>
                      <Typography className={styles.title}>Conta</Typography>
                      <Typography className={styles.description}>
                        {depositAccount}
                      </Typography>
                    </div>
                  </Grid>
                )}

                <Grid item xs={6} md={nsu ? 9 : 8} lg={nsu ? 9 : 8}>
                  <div className={styles.containerInColumn}>
                    <Typography className={styles.title}>Tipo</Typography>
                    <Typography className={styles.description}>
                      {paymentTypeDescription}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div className={styles.amountCards}>
              <Typography className={styles.titleCard}>
                Valor do pagamento
              </Typography>

              <Grid container item spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div className={styles.totalAmount}>
                    <Typography className={styles.title}>Total</Typography>
                    <Typography className={styles.amountDescription}>
                      {totalAmount}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={4} sm={4}>
                  <div className={styles.containerInColumn}>
                    <Typography className={styles.title}>Folha</Typography>
                    <Typography className={styles.description}>
                      {payrollAmount}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={4} sm={4}>
                  <div className={styles.containerInColumn}>
                    <Typography className={styles.title}>Tarifa</Typography>
                    <Typography className={styles.description}>
                      {fee}
                    </Typography>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sm={4}
                  className={!chargeSignature && isMobile && styles.hidden}
                >
                  {chargeSignature && (
                    <div className={styles.containerInColumn}>
                      <Typography className={styles.title}>
                        Ass. Plano Somapay
                      </Typography>
                      <Typography className={styles.description}>
                        {signatureValue}
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
    )}
  </>
);
