/* eslint-disable no-useless-escape */
import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography, TextField, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { Field } from 'react-final-form';
import styles from './styles.module.css';

function StepTwo({
  textFieldFocusedChargeEmail,
  setTextFieldFocusedChargeEmail,
  textFieldFocusedAccessEmail,
  setTextFieldFocusedAccessEmail,
}) {
  const { errorOnActivePlanPointOnline } = useSelector(
    state => state.pointOnline,
  );

  return (
    <div className={styles.containerStepTwo}>
      <Typography variant="h6" className={styles.title}>
        Ativação Ponto Online
      </Typography>
      <Typography variant="h6" className={styles.subtitle}>
        Informe esses processos:
      </Typography>
      <Typography variant="h6" className={styles.titleInfo}>
        Digite o e-mail do usuário do DP que será o gestor do produto Ponto
        Online:
      </Typography>

      <div className={styles.formGroup}>
        <Field name="accessEmail">
          {({ input, meta }) => (
            <TextField
              size="small"
              className={styles.textFieldBig}
              label="E-mail gestor"
              variant="outlined"
              fullWidth
              value={input.value}
              onChange={input.onChange}
              error={!!meta.submitFailed && meta.error}
              helperText={meta.submitFailed && meta.error}
              onFocus={() =>
                setTimeout(
                  () => setTextFieldFocusedAccessEmail('accessEmail'),
                  200,
                )
              }
              onBlur={() =>
                setTimeout(() => setTextFieldFocusedAccessEmail(''), 200)
              }
              InputProps={{
                tabIndex: '2',
                endAdornment: input.value.length > 0 &&
                  textFieldFocusedAccessEmail === 'accessEmail' && (
                    <IconButton
                      className={styles.endAdornment}
                      onClick={() => input.onChange('')}
                    >
                      <CloseRoundedIcon className={styles.endAdornmentIcon} />
                    </IconButton>
                  ),
              }}
            />
          )}
        </Field>
      </div>
      <Typography variant="h6" className={styles.titleInfo}>
        Confirme o e-mail abaixo para o qual são enviadas as Notas Fiscais e
        Cobranças da Somapay:
      </Typography>
      <div className={styles.formGroup}>
        <Field name="chargeEmail">
          {({ input, meta }) => (
            <TextField
              size="small"
              className={styles.textFieldBig}
              label="E-mail cobrança"
              variant="outlined"
              fullWidth
              value={input.value}
              onChange={input.onChange}
              error={!!meta.submitFailed && meta.error}
              helperText={meta.submitFailed && meta.error}
              onFocus={() =>
                setTimeout(
                  () => setTextFieldFocusedChargeEmail('chargeEmail'),
                  200,
                )
              }
              onBlur={() =>
                setTimeout(() => setTextFieldFocusedChargeEmail(''), 200)
              }
              InputProps={{
                tabIndex: '2',
                endAdornment: input.value.length > 0 &&
                  textFieldFocusedChargeEmail === 'chargeEmail' && (
                    <IconButton
                      className={styles.endAdornment}
                      onClick={() => input.onChange('')}
                    >
                      <CloseRoundedIcon className={styles.endAdornmentIcon} />
                    </IconButton>
                  ),
              }}
            />
          )}
        </Field>

        {errorOnActivePlanPointOnline.hasError && (
          <Box mt={2}>
            <Alert severity="error">
              {errorOnActivePlanPointOnline.message}
            </Alert>
          </Box>
        )}
      </div>
    </div>
  );
}

export default StepTwo;
