import { reduxApi } from '../../../services/reduxApi';

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [],
  })
  .injectEndpoints({
    endpoints: builder => ({
      startCnabFileGeneration: builder.mutation({
        query: ({ nsu = '' }) => ({
          url: `files/employeespayments/return-file/generate/${nsu}`,
          method: 'POST',
        }),

        transformErrorResponse: response => {
          return response?.data?.errors?.[0]?.errorDetail;
        },
      }),

      getCnabFileDownload: builder.mutation({
        query: ({ url = '' }) => ({
          url: `files/employeespayments/download/file`,
          headers: { Accept: 'text/plain', url },
          responseHandler: 'text',
        }),

        transformErrorResponse: response => {
          return response?.data?.errors?.[0]?.errorDetail;
        },
      }),
    }),
  });

export const {
  useStartCnabFileGenerationMutation,
  useGetCnabFileDownloadMutation,
} = slice;
