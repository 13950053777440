import axios from 'axios';
import { api } from './api';
import { encrypt } from '../utils/security';

export const getToken = async ({ cpfEmail, password }) => {
  let pwd = encrypt(password);

  const requestConfig = {
    headers: {
      branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
    },
  };

  const requestBody = {
    emailOrCpf: cpfEmail.toLowerCase(),
    password: pwd,
  };
  return api
    .post('/v2/auth/login', requestBody, requestConfig)
    .then(({ data: { token } }) => {
      return token;
    })
    .catch(error => {
      return error;
    });
};

export const getUser = async ({
  cpfEmail,
  password,
  setErrorStatus,
  setErrorDetail,
  emailNotReceived,
}) => {
  let pwd = encrypt(password);

  const requestConfig = {
    headers: {
      branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
    },
  };

  const requestBody = {
    emailOrCpf: cpfEmail.toLowerCase(),
    password: pwd,
    emailNotReceived,
  };

  return api
    .post('/v2/auth/login', requestBody, requestConfig)
    .then(({ data }) => data)
    .catch(error => {
      setErrorStatus(error?.response?.data?.errors[0]?.errorReason);
      setErrorDetail(
        error?.response?.data?.errors[0]?.errorDetail ||
          error?.response?.data?.message,
      );
    });
};

export const loginConfirmation = async ({
  cpfEmail,
  requestValidation,
  codeRequest,
  setErrorStatus,
  setErrorDetail,
}) => {
  const requestConfig = {
    headers: {
      branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
    },
  };

  const requestBody = {
    login: cpfEmail.toLowerCase(),
    code: codeRequest,
    requestValidation,
  };

  return api
    .post('/v2/auth/loginConfirmation', requestBody, requestConfig)
    .then(({ data }) => data)
    .catch(error => {
      setErrorStatus(error?.response?.data?.errors[0]?.errorReason);
      setErrorDetail(
        error?.response?.data?.errors[0]?.errorDetail ||
          error?.response?.data?.message,
      );
    });
};

export const refreshToken = async () => {
  return api
    .post('/auth/refreshToken', {
      Authorization: sessionStorage.getItem('@ContaSoma-Token'),
      cpf: sessionStorage.getItem('cpf'),
    })
    .then(({ data }) => {
      sessionStorage.setItem('@ContaSoma-Token', data.idToken);
      axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
        '@ContaSoma-Token',
      )}`;

      return data;
    });
};
