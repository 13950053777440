import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import Form from '../../../../../../../components/Form';

export function ProductRatingStep({ control = {}, productRatingOptions = [] }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
        De 0 à 10, como você avalia o produto Somapay?
      </Typography>

      <Controller
        name="productRating"
        control={control}
        render={({ field }) => (
          <Form.SelectField
            label="Nota"
            options={productRatingOptions}
            getOptionLabel={item => `${item?.value} - ${item?.label}`}
            {...field}
          />
        )}
      />
    </Box>
  );
}
