import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import Form from '../../../../../../../components/Form';

export function SuggestionsStep({ control = {} }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
        Caso tenha alguma sugestão, elogio ou reclamações, por favor escreva
        abaixo para que possamos melhorar cada vez mais
      </Typography>

      <Controller
        name="feedback"
        control={control}
        render={({ field }) => (
          <Form.TextField multiline rows={3} label="Feedback" {...field} />
        )}
      />
    </Box>
  );
}
