import React from 'react';

import { Typography } from '@material-ui/core';
import SendRoundedIcon from '@material-ui/icons/SendRounded';

import styles from './styles.module.css';

export default function Title() {
  return (
    <div className={styles.titleContainer}>
      <SendRoundedIcon className={styles.titleIcon} />
      <Typography className={styles.titleBigText}>
        Contra cheques importados e disponibilizados
      </Typography>
      <Typography className={styles.titleSmallText}>
        Em instantes estarão disponíveis no aplicativo Somapay.
      </Typography>
    </div>
  )
}
