import React from 'react';

import {
  FormControl,
  Grid,
  Typography,
  Hidden,
  TextField,
} from '@material-ui/core';

import CustomDatePicker from '../../../../components/DatePicker';
  
import styles from './styles.module.css';
import { Autocomplete } from '@material-ui/lab';
  
  function Filters({
    statuses,
    status,
    statusInput,
    startDate,
    endDate,
    onChangeStatus,
    onChangeStatusInput,
    onChangeStartDate,
    onChangeEndDate,
    validateStartDateInfo,
    validateEndDateInfo
  }) {
  
    return (
      <>
        <Grid container spacing={1} className={styles.containerInputs}>  
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <FormControl
              variant="outlined"
              className={styles.formControl}
              fullWidth
            >
              <Autocomplete
								id="status-autocomplete-id"
								options={statuses}
								getOptionLabel={(option) => option.value}
								value={status}
								onChange={onChangeStatus}
								inputValue={statusInput}
								onInputChange={onChangeStatusInput}
								fullWidth
								renderInput={(params) => <TextField {...params} label="Situação" variant="outlined" />}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4}>
            <div className={styles.dateFilter}>
              <CustomDatePicker
                  value={startDate}
                  onChange={onChangeStartDate}
                  label={startDate ? 'Data inicial' : null}
                  placeholder="Data inicial"
                  error={validateStartDateInfo}
                  disableCalendarIcon={validateStartDateInfo}
              />
              <Hidden smDown>
                  {validateStartDateInfo && (
                  <Typography className={styles.dateError}>
                      *Data inválida
                  </Typography>
                  )}
              </Hidden>
            </div>
          </Grid>

          <span className={styles.dateLabel}>até</span>
          
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <div className={styles.dateFilter}>
              <CustomDatePicker
                  value={endDate}
                  onChange={onChangeEndDate}
                  label={endDate ? 'Data final' : null}
                  placeholder="Data final"
                  error={validateEndDateInfo}
                  disableCalendarIcon={validateEndDateInfo}
              />
              <Hidden smDown>
                  {validateEndDateInfo && (
                  <Typography className={styles.dateError}>
                      *Data inválida
                  </Typography>
                  )}
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
  
export default Filters;