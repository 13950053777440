import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';

import styles from './styles.module.css';

export default function Infos() {
  const {
    currentCompany: { name, pixKey },
  } = useSelector(state => state.companies);

  return (
    <>
      <Box mb={2}>
        <Typography className={styles.biggerText}>
          Informações do Pix:
        </Typography>
      </Box>

      <Grid container>
        <Grid item className={styles.grid}>
          <Typography className={styles.label}>Empresa</Typography>

          <Typography className={styles.defaultText}>{name}</Typography>
        </Grid>
        <Grid item className={styles.grid}>
          <Typography className={styles.label}>Chave Pix</Typography>

          <Typography className={styles.defaultText}>
            {pixKey || '-'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
