import React from 'react';
import Carousel from 'nuka-carousel';
import { useHistory } from 'react-router-dom';

import Banner1 from '../../../../assets/banner-1-intern-insurance.png';
import Banner2 from '../../../../assets/banner-2-consigned.png';
import Banner3 from '../../../../assets/banner-3-secure-termination.png';
import Banner4 from '../../../../assets/banner-4-ra1000.png';
import Banner5 from '../../../../assets/banner-5-beevale.png';

import Banner7 from '../../../../assets/banner-7-premio-ra-2024.png';

import { isMobile } from '../../../../utils/breakpoints';

import styles from './styles.module.css';

export default function Slider() {
  const history = useHistory();

  const banners = [
    {
      alt: 'Banner Seguro Estagiário',
      src: Banner1,
      link: 'https://bpc.capemisa.com.br/pages/Index.aspx',
    },
    {
      alt: 'Banner Empréstimo Consignado',
      src: Banner2,
      link: 'https://materiais.somapay.com.br/consignado-para-empresa',
    },
    {
      alt: 'Banner Rescisão Segura',
      src: Banner3,
      link: 'https://materiais.somapay.com.br/rescisao-segura-somapay-cs',
    },
    {
      alt: 'Banner RA1000',
      src: Banner4,
      link: '',
    },
    {
      alt: 'Banner Bee Vale',
      src: Banner5,
      link: 'https://bit.ly/BeeVale',
    },

    {
      alt: 'Banner Prêmio RA 2024',
      src: Banner7,
      link:
        'https://www.reclameaqui.com.br/premio/votacao/bancos-e-cartoes/somapaydigitalbank/',
    },
  ];

  const handleClickBanner = (url, externalLink) => {
    if (!url) return;

    if (externalLink) {
      window.open(url, '_blank');
      return;
    }

    history.push(url);
  };

  return (
    <div className={styles.sliderContainer}>
      <div>
        <Carousel
          className={styles.carousel}
          navButtonsAlwaysVisible
          animation="fade"
          wrapAround
          autoplay
          autoplayInterval={10000}
          defaultControlsConfig={{
            pagingDotsStyle: {
              fill: '#52197F',
              outline: 'none',
              position: 'relative',
              top: isMobile ? 35 : 40,
            },
            prevButtonStyle: {
              display: 'none',
            },
            nextButtonStyle: {
              display: 'none',
            },
          }}
        >
          {banners?.map(({ alt, link, src }) => (
            <img
              key={alt}
              onClick={() => handleClickBanner(link, true)}
              src={src}
              alt={alt}
              className={styles.bannerClick}
              onKeyDown={() => handleClickBanner(link, true)}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}
