import React from 'react';

import { Button, TextField, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import styles from './styles.module.css';

export default function ResponsiveFilters({
    onSearchChange,
    searchText,
    setSituation,
    situation
  }) {
    const onButtonFilterClicked = (e) => {
        const value = Number(e.currentTarget.id);

        switch(value) {
            case 0:
                setSituation('');
                break;
            case 1:
                setSituation(1);
                break;
            case 2:
                setSituation(2);
                break;
            case 3:
                setSituation(3);
                break;
            case 4:
                setSituation(4);
                break;
            case 5:
                setSituation(5);
                break;
            case 6:
                setSituation(6);
                break;
            case 7:
                setSituation(7);
                break;
            default:
                setSituation(0)
                break;
        }
    };

    return (
        <div>
            <TextField
                placeholder="Pesquise por nome ou CPF"
                variant="outlined"
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                    ),
                }}
                className={styles.search}
                value={searchText}
                onChange={onSearchChange}
            />

            <div className={styles.buttonFilterContainer} >
                <Button
                    className={
                        situation === '' || situation === 0 ?
                        styles.activeButtonFilter :
                        styles.buttonFilter
                    }
                    onClick={onButtonFilterClicked}
                    id={0}
                >
                    Todos
                </Button>
                <Button
                    className={
                        situation === 1 ?
                        styles.activeButtonFilter :
                        styles.buttonFilter
                    }
                    onClick={onButtonFilterClicked}
                    id={1}
                >
                    Pagamento Pendente
                </Button>
                <Button
                    className={
                        situation === 2 ?
                        styles.activeButtonFilter :
                        styles.buttonFilter
                    }
                    onClick={onButtonFilterClicked}
                    id={2}
                >
                    Paga
                </Button>
                <Button
                    className={
                        situation === 3 ?
                        styles.activeButtonFilter :
                        styles.buttonFilter
                    }
                    onClick={onButtonFilterClicked}
                    id={3}
                >
                    Pagamento não Realizado
                </Button>
                <Button
                    className={
                        situation === 4 ?
                        styles.activeButtonFilter :
                        styles.buttonFilter
                    }
                    onClick={onButtonFilterClicked}
                    id={4}
                >
                    Em Processamento
                </Button>
                <Button
                    className={
                        situation === 5 ?
                        styles.activeButtonFilter :
                        styles.buttonFilter
                    }
                    onClick={onButtonFilterClicked}
                    id={5}
                >
                    Agendado
                </Button>
                <Button
                    className={
                        situation === 6 ?
                        styles.activeButtonFilter :
                        styles.buttonFilter
                    }
                    onClick={onButtonFilterClicked}
                    id={6}
                >
                    Cancelado
                </Button>
                <Button
                    className={
                        situation === 7 ?
                        styles.activeButtonFilter :
                        styles.buttonFilter
                    }
                    onClick={onButtonFilterClicked}
                    id={7}
                >
                    Autorizado
                </Button>
                <Button
                    className={
                        situation === 8 ?
                        styles.activeButtonFilter :
                        styles.buttonFilter
                    }
                    onClick={onButtonFilterClicked}
                    id={8}
                >
                    Ted não Paga
                </Button>
                <Button
                    className={
                        situation === 9 ?
                        styles.activeButtonFilter :
                        styles.buttonFilter
                    }
                    onClick={onButtonFilterClicked}
                    id={9}
                >
                    TED Agendada
                </Button>
                <Button
                    className={
                        situation === 10 ?
                        styles.activeButtonFilter :
                        styles.buttonFilter
                    }
                    onClick={onButtonFilterClicked}
                    id={10}
                >
                    Pagamento Invalido
                </Button>
            </div>
        </div>
    )
}
