/* eslint-disable no-undef */
/* eslint-disable react/no-children-prop */
import React, { useCallback, useState, useEffect } from 'react';
import {
  useHistory,
  useRouteMatch,
  Prompt,
  useLocation,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Hidden,
  Menu,
  Box,
  List,
  Divider,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { MuiThemeProvider } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import 'moment/locale/pt-br';
import { pusherStatus } from '../../../enums/pusher';

import ModalSkippedInvalidDeposits from '../../../components/ModalSkippedInvalidDeposits';
import Info from '../../../components/Info';
import ProgressToolBar from '../../../components/ProgressToolBar';
import CustomDatePicker from '../../../components/DatePicker';
import CustomDateTimePicker from '../../../components/DatePickerTime';
import CancelUploadModal from '../../../components/PrimaryModal';
import FinishedUploadModal from '../../../components/PrimaryModal';
import AlreadyUploadModal from '../../../components/PrimaryModal';
import InvalidFileModal from '../../../components/PrimaryModal';
import ErrorModal from '../../../components/PrimaryModal';
import { useValidationForUnformattedDate } from '../../../hooks/useValidationForUnformattedDate';

import ProgressBar from './UI/ProgressBar';

import { api } from '../../../services/api';
import { theme } from './theme';
import styles from './styles.module.css';
import ImportPayrollServices from './utils/services';
import { pusher } from '../../../utils/pusher';

import { ReactComponent as DescriptionIcon } from '../../../assets/fileText.svg';
import { ReactComponent as WarningIcon } from '../../../assets/info-yellow.svg';
import PrimaryModal from '../../../components/PrimaryModal';
import convertBytesToBlobDownloadXls from '../../../utils/convertBytesToBlobDownloadXls';
import { ActionsImportFilePayrollErrors } from '../../../redux/store/Payroll/FilePayrollErrors';

moment.locale('pt-br');

const isMobile = window.innerWidth <= 500 || window.innerHeight <= 500;

const ImportPayroll = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const uploadCanceled = useSelector(state => state.importFiles.uploadCanceled);

  const today = moment();
  const tomorrowFirstHour = moment(today)
    .add(1, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const fileStorage = JSON.parse(localStorage.getItem('fileItem'));
  const companyCodeValueStorage = sessionStorage.getItem('currentCompanyCode');
  const uploadDataPayroll = JSON.parse(
    localStorage.getItem(`uploadDataPayroll-${companyCodeValueStorage}`),
  );
  const payrollMenuData = JSON.parse(localStorage.getItem('payrollMenuData'));
  const payrollOption = JSON.parse(localStorage.getItem('payrollOption'));
  const zeroProduct = JSON.parse(sessionStorage.getItem('zeroProduct'));

  const { accountSelected } = useSelector(state => state.account);

  const [loadingButton, setLoadingButton] = useState(false);
  const [date, setDate] = useState(tomorrowFirstHour || '');
  const availablePaymentHours = [
    { value: '21:00:00', description: '21:00' },
    { value: '20:00:00', description: '20:00' },
    { value: '19:00:00', description: '19:00' },
    { value: '18:00:00', description: '18:00' },
    { value: '17:00:00', description: '17:00' },
    { value: '16:00:00', description: '16:00' },
    { value: '15:00:00', description: '15:00' },
    { value: '14:00:00', description: '14:00' },
    { value: '13:00:00', description: '13:00' },
    { value: '12:00:00', description: '12:00' },
    { value: '11:00:00', description: '11:00' },
    { value: '10:00:00', description: '10:00' },
    { value: '09:00:00', description: '09:00' },
    { value: '08:00:00', description: '08:00' },
    { value: '07:00:00', description: '07:00' },
    { value: '06:00:00', description: '06:00' },
    { value: '05:00:00', description: '05:00' },
    { value: '04:00:00', description: '04:00' },
  ];
  const [
    selectedAvailablePaymentHour,
    setSelectedAvailablePaymentHour,
  ] = useState(moment(today).format('HH:00:00'));
  const [openDialog, setOpenDialog] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [file, setFile] = useState([]);
  const [downloadUrl, setDownloadURL] = useState('');
  const [downloadUrlCostCenter, setDownloadURLCostCenter] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [paymentTypeDescription, setPaymentTypeDescription] = useState(
    'Salário',
  );
  const [disable, setDisable] = useState(true);
  const [openUploadCancelModal, setUploadCancelModal] = useState(false);
  const [uploadData, setUploadData] = useState(uploadDataPayroll || null);
  const [types, setTypes] = useState([]);
  const [finishedUploadModal, setFinishedUploadModal] = useState(false);
  const [alreadyUploadModal, setAlreadyUploadModal] = useState(false);
  const [loadingValidatePayroll, setLoadingValidatePayroll] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errorAlreadyPayroll, setErrorAlreadyPayroll] = useState('');
  const [competence, setCompetence] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [actionsButtonAnchor, setActionsButtonAnchor] = useState('');
  const [resignedEmployees, setResignedEmployees] = useState([]);
  const [
    confirmResignedEmployeesModal,
    setConfirmResignedEmployeesModal,
  ] = useState(false);

  const validateDateInfo = useValidationForUnformattedDate(date);

  const statusProgress = uploadDataPayroll?.status;

  const fileType = payrollMenuData?.fileType || payrollOption?.fileType;
  const extension = payrollMenuData?.extension || payrollOption?.extension;
  const exampleLink =
    'https://somapay-companies.s3.amazonaws.com/import-file-type/Planilha_de_folha_de_pagamento_Somapay+(novo+modelo).xlsx';

  const exampleLinkCostCenter =
    'https://somapay-companies.s3.amazonaws.com/import-file-type/Planilha_de_folha_de_pagamento_Somapay_Centro_custo.xlsx';

  const {
    PROCESSED_PAYROLL_SUCCESS,
    PROCESSED_PAYROLL_ERROR,
    PROCESSING,
    CANCELED,
  } = pusherStatus;

  // const isErpFile = fileType === 2 || fileType === 3 || fileType === 4;
  const isErpFile = fileType >= 2;

  useEffect(() => setDownloadURL(exampleLink), []);

  useEffect(() => setDownloadURLCostCenter(exampleLinkCostCenter), []);

  const onRemoveFileClicked = () => {
    setFile([]);
    localStorage.removeItem('fileItem');
  };

  const onDrop = useCallback(acceptedFiles => {
    let value = acceptedFiles[0].name.substring(
      acceptedFiles[0].name.lastIndexOf('.') + 1,
    );

    value = value?.toLowerCase();

    if (isErpFile ? value === 'txt' : value === 'xls' || value === 'xlsx') {
      localStorage.setItem('fileItem', JSON.stringify(acceptedFiles));
      setFile(acceptedFiles);
    } else {
      setOpenDialog(true);
    }
  }, []);

  useEffect(() => {
    const channel = pusher.subscribe(companyCodeValueStorage);

    channel.bind('PROCESSED_PAYROLL', function(data) {
      setUploadData(data);

      if (data?.status !== CANCELED) {
        localStorage.setItem(
          `uploadDataPayroll-${companyCodeValueStorage}`,
          JSON.stringify(data),
        );
      }

      if (data?.status === CANCELED) {
        localStorage.removeItem(`uploadDataPayroll-${companyCodeValueStorage}`);
      }

      if (
        (data?.status === PROCESSED_PAYROLL_SUCCESS ||
          data?.status === PROCESSED_PAYROLL_ERROR) &&
        data?.companyCode === companyCodeValueStorage &&
        (!data?.data || data?.data?.errors?.length === 0)
      ) {
        const newSkippedInvalidDeposits = data?.data?.skippedInvalidDeposits;

        if (newSkippedInvalidDeposits) {
          setConfirmModal(true);
          return;
        }

        if (data?.data?.resignedEmployees?.length > 0) {
          setLoadingButton(false);
          setUploadCancelModal(false);
          setResignedEmployees(data?.data?.resignedEmployees);
          setConfirmResignedEmployeesModal(true);
          return;
        }

        setLoadingButton(false);
        setFinishedUploadModal(true);
        setUploadCancelModal(false);
      }

      if (data?.status === PROCESSED_PAYROLL_ERROR && data?.data) {
        setErrorTitle(data?.data.errorDetail);
        setErrorMessage(data?.data.errorsDescription);
        setErrorModal(true);
        setLoadingButton(false);
        setUploadCancelModal(false);
        setFinishedUploadModal(false);
      }
    });

    dispatch(ActionsImportFilePayrollErrors.setIsFileXls(!isErpFile));
  }, []);

  useEffect(() => {
    if (isErpFile) {
      if (
        file.length === 0 ||
        !date ||
        !selectedAvailablePaymentHour ||
        paymentTypeDescription === '' ||
        !competence
      ) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }

    if (!isErpFile) {
      if (
        file.length === 0 ||
        !date ||
        !selectedAvailablePaymentHour ||
        paymentTypeDescription === ''
      ) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
  }, [paymentTypeDescription, date, file, competence]);

  useEffect(() => {
    if (moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      if (moment().format('HH:00:00') < '21:00:00') {
        setSelectedAvailablePaymentHour(
          moment()
            .add(1, 'hour')
            .format('HH:00:00'),
        );
      } else {
        setSelectedAvailablePaymentHour('');
      }
    } else if (moment().format('HH:00:00') < '21:00:00') {
      setSelectedAvailablePaymentHour(moment().format('HH:00:00'));
    } else {
      setSelectedAvailablePaymentHour('04:00:00');
    }
  }, [date]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onCancelUpload = () => {
    setLoadingValidatePayroll(true);

    const processId = uploadDataPayroll?.processId;

    api
      .put(`/files/employeespayments/cancel/${processId}`)
      .then(() => {
        dispatch({
          type: 'UPLOAD_CANCELED',
          uploadCanceled: true,
        });
        setFile([]);
        localStorage.removeItem(`uploadDataPayroll-${companyCodeValueStorage}`);
      })
      .finally(() => {
        setFinishedUploadModal(false);
        setUploadCancelModal(false);
        setLoadingValidatePayroll(false);
        setLoadingButton(false);
      });
  };

  function handleConfirm(valueConfirm) {
    if (valueConfirm) {
      setConfirmModal(false);
      setLoadingButton(false);
      setFinishedUploadModal(true);
      setUploadCancelModal(false);
    } else {
      setConfirmModal(false);
      setLoadingButton(false);
      setUploadCancelModal(false);
    }
  }

  const renderDropzone = () => {
    if (
      uploadDataPayroll?.status === PROCESSING &&
      uploadDataPayroll?.companyCode === companyCodeValueStorage &&
      !uploadCanceled
    ) {
      return (
        <>
          <div className={styles.dropzoneStyle}>
            <ListItem>
              <ListItemAvatar className={styles.fileAvatarStyle}>
                <DescriptionIcon width="58" height="58" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Typography className={styles.fileTextStyle}>
                      {uploadDataPayroll ? uploadDataPayroll?.fileName : ''}
                    </Typography>
                    {uploadDataPayroll?.percentage === 100 ? (
                      <Typography className={styles.fileTextDescriptionFinish}>
                        Importação concluída
                      </Typography>
                    ) : uploadDataPayroll?.status === PROCESSING ? (
                      <div style={{ display: 'flex' }}>
                        <CircularProgress className={styles.circularProgress} />
                        <Typography className={styles.fileTextDescription}>
                          {uploadDataPayroll?.statusDescription}
                        </Typography>
                        <Typography className={styles.fileTextProgress}>
                          {uploadDataPayroll?.percentage} %
                        </Typography>
                      </div>
                    ) : null}
                  </>
                }
              />
            </ListItem>
            <Button
              variant="outlined"
              className={styles.fileButtonStyle}
              onClick={() => setUploadCancelModal(true)}
              disabled={uploadDataPayroll?.percentage === 0}
            >
              Cancelar
            </Button>
          </div>
          <Typography className={styles.infoTextStyleLoad}>
            Enquanto verificamos o arquivo você pode continuar seu trabalho e
            acompanhar o andamento pelo indicador na parte superior.
          </Typography>
          <ProgressBar
            progress={uploadData?.percentage ? uploadData?.percentage : 0}
          />
        </>
      );
    }
    if (file.length === 0) {
      return (
        <>
          <Button
            {...getRootProps()}
            className={
              isMobile ? styles.mobileDropzoneStyle : styles.dropzoneStyle
            }
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography className={styles.primaryColor}>Soltar</Typography>
            ) : (
              <Typography className={styles.buttonTextStyle}>
                <PublishIcon />
                {isErpFile ? 'Enviar um arquivo' : 'Importar Arquivo'}
              </Typography>
            )}
          </Button>
          <Typography className={styles.infoTextStyle}>
            Você pode arrastar ou clicar na caixa para selecionar o arquivo.
          </Typography>
        </>
      );
    }

    return (
      <>
        <div className={styles.dropzoneStyle}>
          <ListItem>
            <ListItemAvatar className={styles.fileAvatarStyle}>
              <DescriptionIcon width="58" height="58" />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography className={styles.fileTextStyle}>
                  {fileStorage ? fileStorage[0]?.path : ''}
                </Typography>
              }
            />
          </ListItem>
          <Button
            variant="outlined"
            className={styles.fileButtonStyle}
            onClick={onRemoveFileClicked}
            disabled={loadingButton || uploadDataPayroll?.status === PROCESSING}
          >
            Remover
          </Button>
        </div>
        <Typography className={styles.infoTextStyle}>
          Enquanto verificamos o arquivo você pode continuar seu trabalho.
        </Typography>
      </>
    );
  };

  useEffect(() => {
    ImportPayrollServices.getListOfTypes({
      setStates: data => {
        setTypes(data);
      },
    });
  }, []);

  const uploadFile = () => {
    setLoadingButton(true);

    const requestOptions = {
      headers: {
        extensionFile: extension,
        paymentType,
        scheduleDate:
          date && selectedAvailablePaymentHour
            ? `${moment(date).format(
                'YYYY-MM-DD',
              )}T${selectedAvailablePaymentHour}`
            : '',
        competencyDate: isErpFile
          ? moment(competence).format('YYYY-MM-01')
          : '',
        depositAccountId: accountSelected?.encodedKey,
        accountOrigin: accountSelected?.origin,
      },
    };

    const formData = new FormData();
    formData.append('file', file[0]);

    api
      .post(
        `/files/employeespayments/validate/${companyCodeValueStorage}/${fileType}`,
        formData,
        requestOptions,
      )
      .then(() => {
        setIsBlocking(false);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorTitle(errors?.[0]?.errorDetail);
          setErrorMessage(errors?.[0]?.errorsDescription);
          setErrorModal(true);
        },
      )
      .finally(() => {
        if (uploadDataPayroll) {
          setLoadingButton(false);
        }
      });
  };

  const onBackClicked = () => {
    history.push('/payroll/menu');
  };

  const onNextClicked = () => {
    dispatch({ type: 'UPLOAD_CANCELED', uploadCanceled: false });

    uploadFile();

    const obj = {
      paymentTypeDescription,
      paymentType,
      date,
      paymentHour: selectedAvailablePaymentHour,
    };

    sessionStorage.setItem('paymentDescription', JSON.stringify(obj));
    sessionStorage.setItem('urlPayroll', url);
  };

  const onChangeDate = value => {
    return setDate(value);
  };

  const onChangeCompetence = value => {
    return setCompetence(value);
  };

  const onContinueModalClicked = () => {
    const data = uploadDataPayroll?.data;
    const fileCode = uploadDataPayroll?.data?.fileEncodedKey;
    const status = uploadDataPayroll?.status;
    const processId = uploadDataPayroll?.processId;

    if (status === PROCESSED_PAYROLL_SUCCESS) {
      setLoadingValidatePayroll(true);

      api
        .get(`/files/employeespayments/notAlreadyExistsPayroll/${fileCode}`)
        .then(() => {
          localStorage.removeItem(
            `uploadDataPayroll-${companyCodeValueStorage}`,
          );
          localStorage.removeItem('fileItem');

          sessionStorage.setItem(
            'importFileData',
            JSON.stringify(Object.assign(data, { isFileImport: true })),
          );

          history.push(
            '/payroll/menu/info-payroll/register-batch/data-review',
            resignedEmployees,
          );
        })
        .catch(
          ({
            response: {
              data: { errors },
            },
          }) => {
            setErrorAlreadyPayroll(errors[0]?.errorsDescription.message);

            if (errors[0]?.errorReason === 'FILE_ALREADY_PROCESSED') {
              localStorage.removeItem(
                `uploadDataPayroll-${companyCodeValueStorage}`,
              );
              localStorage.removeItem('fileItem');
              setAlreadyUploadModal(true);
            }
          },
        )
        .finally(() => {
          setLoadingValidatePayroll(false);
          setFinishedUploadModal(false);
        });
    } else if (status === PROCESSED_PAYROLL_ERROR) {
      setLoadingValidatePayroll(true);

      api
        .get(`/files/employeespayments/errors/${processId}`)
        .then(({ data }) => {
          dispatch(ActionsImportFilePayrollErrors.setFileErrorsData(data));
        })
        .finally(() => {
          localStorage.removeItem(
            `uploadDataPayroll-${companyCodeValueStorage}`,
          );

          localStorage.removeItem('fileItem');
          history.push(`/payroll/menu/info-payroll/register-batch/list-error`);
          setLoadingValidatePayroll(false);
          setLoadingValidatePayroll(false);
          setFinishedUploadModal(false);
        });
    }
  };

  const clearInputs = () => {
    dispatch({
      type: 'UPLOAD_CANCELED',
      uploadCanceled: true,
    });

    onRemoveFileClicked();

    localStorage.removeItem(`uploadDataPayroll-${companyCodeValueStorage}`);

    setLoadingValidatePayroll(false);
    setLoadingButton(false);
  };

  const handleActionsMenu = event => {
    setActionsButtonAnchor(event.currentTarget);
  };

  function renderResignedList() {
    return (
      <Box flexDirection="column">
        <Typography className={styles.resignedEmployeesModalTitle}>
          Funcionários demitidos:
        </Typography>
        <List>
          {resignedEmployees?.map(employees => (
            <>
              <ListItem>
                <ListItemText>
                  <div>
                    •{' '}
                    <span className={styles.resignedEmployeesName}>
                      {employees?.name}
                    </span>{' '}
                    -{' '}
                    <span className={styles.resignedEmployeesCpf}>
                      {employees?.cpf}
                    </span>
                  </div>
                </ListItemText>
              </ListItem>
            </>
          ))}
        </List>
      </Box>
    );
  }

  function downloadModel(isLocation) {
    const requestConfig = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
        accept: 'application/xls',
      },
      params: {
        isLocation,
      },
      responseType: 'blob',
    };
    api
      .get('files/employeespayments/download/model', requestConfig)
      .then(({ data }) => {
        convertBytesToBlobDownloadXls(
          data,
          `modelo de folha de pagamento${
            isLocation ? ' com centro de custo' : ''
          }`,
        );
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorModal(true);
          setErrorTitle(
            'Houve um erro ao tentar baixar o modelo. Tente novamente mais tarde.',
          );
        },
      );
  }

  return (
    <div className={styles.rootView}>
      <Prompt
        when={isBlocking}
        message="Você tem certeza que deseja cancelar a criação desta folha?"
      />

      <MuiThemeProvider theme={theme}>
        <Typography className={styles.titleStyle}>
          Novo pagamento via arquivo
        </Typography>

        {isErpFile ? (
          <div
            className={
              isMobile ? styles.mobileContentStyle : styles.contentStyle
            }
          >
            <div className={styles.stepContainer}>
              <Typography className={styles.stepTextStyle}>
                1. Comece extraindo o arquivo do seu ERP no layout Somapay
              </Typography>
              <Info
                warning
                icon={<WarningIcon />}
                text="Importante: é possível apenas um tipo de pagamento e data de agendamento por arquivo ERP."
              />
            </div>

            <div className={styles.stepContainer}>
              <Typography className={styles.stepTextStyle}>
                2. Configure o Pagamento
              </Typography>
              <div className={styles.inputsContainer}>
                <FormControl
                  variant="outlined"
                  className={styles.textFieldStyle}
                >
                  <InputLabel id="payment-type-select-box-label">
                    Tipo de pagamento*
                  </InputLabel>
                  <Select
                    disabled={
                      loadingButton || uploadData?.status === PROCESSING
                    }
                    labelId="payment-type-select-box-label"
                    id="payment-type-select-box"
                    label="Tipo de pagamento*"
                    value={paymentType}
                    onChange={({ target: { value } }) => {
                      setPaymentType(value);
                      setPaymentTypeDescription(
                        types.filter(item => item.code === value)[0]
                          .description,
                      );
                      if (
                        value !== '2' &&
                        moment(date).format('DD/MM/YYYY') ===
                          moment().format('DD/MM/YYYY')
                      ) {
                        setDate(moment().add(1, 'day'));
                      }
                      if (value === '2') {
                        setDate(moment());
                      }
                    }}
                  >
                    {types?.map((type, index) => (
                      <MenuItem key={type.description} value={type.code}>
                        {type.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant="outlined"
                  className={styles.textFieldStyle}
                >
                  <InputLabel
                    id="select-date-label"
                    style={date ? { display: 'none' } : {}}
                    className={
                      date ? styles.disabledInputLabel : styles.inputLabel
                    }
                  >
                    Data do Pagamento*
                  </InputLabel>
                  <CustomDatePicker
                    disabled={
                      loadingButton || uploadData?.status === PROCESSING
                    }
                    value={date}
                    onChange={onChangeDate}
                    minDate={
                      paymentType !== '2' && !zeroProduct
                        ? moment().add(1, 'day')
                        : moment()
                    }
                    label={date ? 'Data do Pagamento*' : null}
                    error={validateDateInfo}
                    disableCalendarIcon={validateDateInfo}
                  />
                  <Hidden smDown>
                    {validateDateInfo && (
                      <Typography className={styles.dateError}>
                        *Data inválida
                      </Typography>
                    )}
                  </Hidden>
                </FormControl>

                <FormControl
                  variant="outlined"
                  className={styles.textFieldStyle}
                >
                  <InputLabel id="payment-hour-select-box-label">
                    Hora do pagamento*
                  </InputLabel>

                  <Select
                    labelId="payment-hour-select-box-label"
                    id="payment-hour-select-box"
                    label="Hora do Pagamento*"
                    value={selectedAvailablePaymentHour}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={({ target: { value } }) =>
                      setSelectedAvailablePaymentHour(value)
                    }
                  >
                    {moment(date).format('YYYY-MM-DD') ===
                    moment().format('YYYY-MM-DD')
                      ? availablePaymentHours?.map(hour => {
                          if (
                            hour.value > moment().format('HH:00:00') &&
                            hour.value <= '21:00:00'
                          ) {
                            return (
                              <MenuItem
                                key={hour.description}
                                value={hour.value}
                              >
                                {hour.description}
                              </MenuItem>
                            );
                          }

                          return (
                            <MenuItem
                              disabled
                              key={hour.description}
                              value={hour.value}
                            >
                              {hour.description}
                            </MenuItem>
                          );
                        })
                      : availablePaymentHours?.map(hour => (
                          <MenuItem key={hour.description} value={hour.value}>
                            {hour.description}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant="outlined"
                  className={styles.textFieldStyle}
                >
                  <InputLabel
                    id="select-date-label"
                    style={competence ? { display: 'none' } : {}}
                    className={
                      competence ? styles.disabledInputLabel : styles.inputLabel
                    }
                  >
                    Competência*
                  </InputLabel>
                  <MuiPickersUtilsProvider
                    utils={(DateFnsUtils, MomentUtils)}
                    locale="pt-br"
                  >
                    <KeyboardDatePicker
                      openTo="year"
                      views={['year', 'month']}
                      label={competence ? 'Competência*' : null}
                      value={competence || null}
                      onChange={onChangeCompetence}
                      inputVariant="outlined"
                      disabled={
                        loadingButton || uploadData?.status === PROCESSING
                      }
                      keyboardIcon={<EventIcon />}
                      InputAdornmentProps={{ position: 'end' }}
                      helperText={false}
                      error={false}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </div>
            </div>

            <div className={styles.stepContainer}>
              <Typography className={styles.stepTextStyle}>
                3. Envie o arquivo preenchido
              </Typography>
              {renderDropzone()}
            </div>
          </div>
        ) : (
          <div
            className={
              isMobile ? styles.mobileContentStyle : styles.contentStyle
            }
          >
            <Typography className={styles.biggerTextStyle}>
              Comece fazendo o download do modelo da planilha
            </Typography>
            <Grid container alignItems="center" justify="space-between">
              <Typography className={styles.smallerTextStyle}>
                Após o download, utilize o excel ou equivalente e preencha com
                os dados dos seu colaboradores.
              </Typography>
              <Button
                variant="contained"
                className={styles.downloadTicket}
                startIcon={<DescriptionOutlinedIcon />}
                onClick={handleActionsMenu}
              >
                <Typography>Modelos</Typography>
              </Button>
              <Menu
                id="simple-menu"
                keepMounted
                anchorEl={actionsButtonAnchor}
                open={Boolean(actionsButtonAnchor)}
                onClose={() => setActionsButtonAnchor('')}
                className={styles.menu}
              >
                <MenuItem onClick={() => downloadModel(false)}>
                  Baixar modelo
                </MenuItem>
                <MenuItem onClick={() => downloadModel(true)}>
                  Baixar novo modelo com centro de custo
                </MenuItem>
              </Menu>
            </Grid>
            <div className={styles.marginStyle}>
              <Grid container justify="flex-start" spacing={12}>
                <Grid item style={{ width: '100%' }}>
                  <Typography className={styles.biggerTextStyle}>
                    Escolha o tipo de pagamento
                  </Typography>
                  <FormControl
                    variant="outlined"
                    className={styles.textFieldStyle}
                  >
                    <InputLabel id="payment-type-select-box-label">
                      Tipo de Pagamento*
                    </InputLabel>

                    <Select
                      labelId="payment-type-select-box-label"
                      id="payment-type-select-box"
                      label="Tipo de Pagamento*"
                      disabled={
                        loadingButton || uploadData?.status === PROCESSING
                      }
                      value={paymentType}
                      onChange={({ target: { value } }) => {
                        setPaymentType(value);
                        setPaymentTypeDescription(
                          types.filter(item => item.code === value)[0]
                            .description,
                        );
                        if (
                          value !== '2' &&
                          moment(date).format('DD/MM/YYYY') ===
                            moment().format('DD/MM/YYYY')
                        ) {
                          setDate(moment().add(1, 'day'));
                        }
                        if (value === '2') {
                          setDate(moment());
                        }
                      }}
                    >
                      {types?.map((type, index) => (
                        <MenuItem key={type.description} value={type.code}>
                          {type.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    className={styles.textFieldStyle}
                  >
                    <InputLabel
                      id="select-date-label"
                      style={date ? { display: 'none' } : {}}
                      className={
                        date ? styles.disabledInputLabel : styles.inputLabel
                      }
                    >
                      Data do Pagamento*
                    </InputLabel>
                    <CustomDatePicker
                      disabled={
                        loadingButton || uploadData?.status === PROCESSING
                      }
                      value={date}
                      onChange={onChangeDate}
                      minDate={
                        paymentType !== '2' && !zeroProduct
                          ? moment().add(1, 'day')
                          : moment()
                      }
                      label={date ? 'Data do Pagamento*' : null}
                      error={validateDateInfo}
                      disableCalendarIcon={validateDateInfo}
                    />
                    <Hidden smDown>
                      {validateDateInfo && (
                        <Typography className={styles.dateError}>
                          *Data inválida
                        </Typography>
                      )}
                    </Hidden>
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    className={styles.textFieldStyle}
                  >
                    <InputLabel id="payment-hour-select-box-label">
                      Hora do pagamento*
                    </InputLabel>

                    <Select
                      labelId="payment-hour-select-box-label"
                      id="payment-hour-select-box"
                      label="Hora do Pagamento*"
                      value={selectedAvailablePaymentHour}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      onChange={({ target: { value } }) =>
                        setSelectedAvailablePaymentHour(value)
                      }
                    >
                      {moment(date).format('YYYY-MM-DD') ===
                      moment().format('YYYY-MM-DD')
                        ? availablePaymentHours?.map(hour => {
                            if (
                              hour.value > moment().format('HH:00:00') &&
                              hour.value <= '21:00:00'
                            ) {
                              return (
                                <MenuItem
                                  key={hour.description}
                                  value={hour.value}
                                >
                                  {hour.description}
                                </MenuItem>
                              );
                            }

                            return (
                              <MenuItem
                                disabled
                                key={hour.description}
                                value={hour.value}
                              >
                                {hour.description}
                              </MenuItem>
                            );
                          })
                        : availablePaymentHours?.map(hour => (
                            <MenuItem key={hour.description} value={hour.value}>
                              {hour.description}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div className={styles.marginStyle}>
                <Typography className={styles.biggerTextStyle}>
                  Envie o arquivo preenchido
                </Typography>
                {renderDropzone()}
              </div>
            </div>
          </div>
        )}

        <CancelUploadModal
          open={openUploadCancelModal}
          onConfirmClicked={onCancelUpload}
          onCancelButtonClicked={() => setUploadCancelModal(false)}
          confirmButtonText="Sim, cancelar"
          cancelButtonText="Não, continuar"
          loading={loadingValidatePayroll}
          title="Tem certeza que quer cancelar o processamento do arquivo?"
          text="Você perderá o progresso processado até agora e essa ação não poderá ser desfeita."
        />

        <FinishedUploadModal
          open={finishedUploadModal}
          onConfirmClicked={onContinueModalClicked}
          confirmButtonText={
            statusProgress === PROCESSED_PAYROLL_ERROR ? 'Entendi' : 'Continuar'
          }
          title={
            statusProgress === PROCESSED_PAYROLL_ERROR && isErpFile
              ? 'Arquivo ERP com dados incorretos.'
              : statusProgress === PROCESSED_PAYROLL_ERROR && !isErpFile
              ? 'Preenchimento de arquivo incorreto'
              : 'Processamento concluído com sucesso!'
          }
          text={
            statusProgress === PROCESSED_PAYROLL_ERROR && isErpFile
              ? 'Identificamos dados divergentes no seu arquivo. Clique em entendi e verifique as divergências encontradas.'
              : statusProgress === PROCESSED_PAYROLL_ERROR && !isErpFile
              ? 'Verifique se você preencheu o arquivo modelo corretamente e tente novamente.'
              : 'Clique em “CONTINUAR” para seguir com o processo.'
          }
          loading={loadingValidatePayroll}
        />

        <AlreadyUploadModal
          open={alreadyUploadModal}
          onConfirmClicked={() => history.push('/payroll')}
          confirmButtonText="Ver gestão de folha"
          title="Um outro usuário já realizou está ação."
          text={errorAlreadyPayroll}
        />

        <InvalidFileModal
          open={openDialog}
          onConfirmClicked={() => {
            clearInputs();
            setOpenDialog(false);
          }}
          title="Formato de arquivo inválido"
          text={`Escolha um arquivo do tipo ${
            isErpFile ? '.txt' : '.xls ou .xlsx'
          }`}
          confirmButtonText="Entendi"
        />

        <ErrorModal
          open={errorModal}
          onConfirmClicked={() => {
            clearInputs();
            setErrorModal(false);
          }}
          title={errorTitle}
          text={errorMessage}
          confirmButtonText="Entendi"
        />

        <ProgressToolBar
          disabled={
            disable ||
            moment(date).format('DD/MM/YYYY HH:mm') === 'Data inválida' ||
            paymentType === ''
          }
          onBackClicked={onBackClicked}
          onNextClicked={onNextClicked}
          nextButtonText="Processar"
          loading={
            loadingButton ||
            (uploadData?.status === PROCESSING &&
              uploadDataPayroll?.companyCode === companyCodeValueStorage)
          }
        />
      </MuiThemeProvider>

      <ModalSkippedInvalidDeposits
        confirmModal={confirmModal}
        onContinueProcess={() => handleConfirm(true)}
        onDontContinueProcess={() => handleConfirm(false)}
      />

      <PrimaryModal
        open={confirmResignedEmployeesModal}
        onCloseDialog={() => setConfirmResignedEmployeesModal(false)}
        onCancelButtonClicked={() => setConfirmResignedEmployeesModal(false)}
        onConfirmClicked={onContinueModalClicked}
        title="Ops! Foram encontrados alguns funcionários demitidos"
        text="Por favor, verifique se os funcionários listados abaixo devem estar inclusos na folha de pagamento"
        children={renderResignedList()}
        confirmButtonText="Continuar"
        cancelButtonText="Cancelar"
      />
    </div>
  );
};

export default ImportPayroll;
