const styles = {
  title: {
    fontWeight: 700,
    fontSize: 20,
    color: '#000000'
  },
  text: {
    marginTop: '-12px',
    fontSize: '14px',
    color: '#545255',
  },
  confirmButtonStyle: {
    border: '1px solid #e54217',
    borderRadius: '6px',
    color: '#ffffff',
    padding: '6px 20px',
    backgroundColor: '#e54217',
    fontWeight: 500,
    height: '38px',
    minWidth: '95px',
  },
  closeButtonStyle: {
    color: '#FFFFFF',
    backgroundColor: '#E54217',
    marginRight: '5px',
  },
  circularLoadingStyle: {
    color: '#FFFFFF',
    width: '20px',
    height: '20px',
    marginLeft: '25px',
    marginRight: '25px',
  },
  cancelButtonStyle: {
    borderRadius: '6px',
    color: '#52197f',
    padding: '6px 20px',
    marginRight: '10px',
    fontWeight: 500,
  }
};

export default styles;
