import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { CircularProgress, IconButton, Typography } from '@material-ui/core';

import PurpleCheckbox from '../../../../../../components/Checkbox/PurpleCheckbox';
import FakeList from '../../../../../../components/FakeList';

import styles from './styles.module.css';
import capitalize from '../../../../../../utils/textCapitalize';

function ModalMobileList({
  employees,
  loading,
  setPageSize,
  pageSize,
  isSelectAllClicked,
  ignoredEmployees,
  selected,
  setSelected,
  setIgnoredEmployees,
  currentPageSize,
}) {
  const [fetchMoreData, setFetchMoreData] = useState(false);

  function fetchData() {
    setPageSize(pageSize + 10);
    setFetchMoreData(true);
  }

  function handleClick(event, row) {
    const selectedIndex = selected
      .map(function(e) {
        return e.cpf;
      })
      .indexOf(row.cpf);
    let newSelected = [];

    if (isSelectAllClicked) {
      handleIgnoredEmployees(row);
      return;
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, {
        name: row.name,
        cpf: row.cpf,
        amount: row.amount,
        registration: row.companies[0]?.registration,
        employeeStatus: row.companies[0]?.status,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleIgnoredEmployees(row) {
    const selectedIndexIgnoreds = ignoredEmployees
      .map(function(e) {
        return e.cpf;
      })
      .indexOf(row.cpf);
    const ignoreds = [...ignoredEmployees];

    if (selectedIndexIgnoreds !== -1) {
      ignoreds.splice(selectedIndexIgnoreds, 1);
      setIgnoredEmployees([...ignoreds]);
    } else {
      setIgnoredEmployees([
        ...ignoreds,
        {
          cpf: row.cpf,
          encodedKey: row.encodedKey,
        },
      ]);
    }
  }

  const isSelected = row =>
    selected
      .map(function(e) {
        return e?.cpf;
      })
      .indexOf(row?.cpf) !== -1;

  const isIgnored = row =>
    ignoredEmployees
      .map(function(e) {
        return e?.cpf;
      })
      .indexOf(row?.cpf) !== -1;

  return loading && !fetchMoreData ? (
    <FakeList />
  ) : (
    <div className={styles.container}>
      <InfiniteScroll
        dataLength={employees.length}
        next={fetchData}
        hasMore
        loader={
          fetchData && currentPageSize < pageSize - 5 ? (
            <div className={styles.circularProgressContainer}>
              <CircularProgress size={25} className={styles.circularProgress} />
            </div>
          ) : (
            ''
          )
        }
      >
        {employees?.map(item => {
          const isItemSelected = isSelected(item);
          const isItemIgnored = isIgnored(item);
          return (
            <div className={styles.card}>
              <div>
                <Typography className={styles.cpf}>{item.cpf}</Typography>
                <Typography className={styles.name}>
                  {capitalize(item.name)}
                </Typography>
              </div>

              <PurpleCheckbox
                checked={isSelectAllClicked ? !isItemIgnored : isItemSelected}
                onClick={event => handleClick(event, item)}
                disabled={item.companies[0]?.status === 'Demitido'}
              />
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

export default ModalMobileList;
