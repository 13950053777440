import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Filters, Header } from './UI';

import { useValidationForUnformattedDate } from '../../hooks/useValidationForUnformattedDate';

import Table from './UI/Table/index';
import NoList from '../../components/NoList';
import LogoLoading from '../../components/LogoLoading';
import ResultNotFound from '../../components/ResultNotFound';
import TablePaginationActions from './UI/Table/TablePaginationActions';

import { api } from '../../services/api';
import openNewTab from '../../utils/openNewTab';
import convertMapToArray from '../../utils/convertMapToArray';
import convertBytesToBlobDowload from '../../utils/convertBytesToBlobDowload';

function OnlinePointCharges() {
  
  const history = useHistory();

  const companyCode = sessionStorage.getItem('currentCompanyCode');

  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingStatuses, setLoadingStatuses] = useState(true);

  const [status, setStatus] = useState(null);
  const [statusInput, setStatusInput] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [totalSize, setTotalSize] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);

  const [charge, setCharge] = useState(null);
  const [charges, setCharges] = useState([]);
  const [withoutCharges, setWithoutCharges] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);

  const validateStartDateInfo = useValidationForUnformattedDate(startDate);
  const validateEndDateInfo = useValidationForUnformattedDate(endDate);

	const onChangeStatus = (_, newValue) => {
		setStatus(newValue);
	};

	const onChangeStatusInput = (_, newValue) => {
		setStatusInput(newValue);
	}

	const onChangeStartDate = (event) => {
		setStartDate(event);
	}

	const onChangeEndDate = (event) => {
		setEndDate(event);
	}

  const loadingTableOn = () => setLoadingTable(true);
  const loadingTableOff = () => setLoadingTable(false);

  const loadingStatusesOn = () => setLoadingStatuses(true);
  const loadingStatusesOff = () => setLoadingStatuses(false);

  const resetPagination = () => {
		setCurrentPage(0);
		setPageSize(10);
	}

	const handleCharges = data => {
		setCharges(data.charges);
		setTotalSize(data.totalSize);
    
    if(data.totalSize)
		  setWithoutCharges(false);
	}

	const handlePageChange = (_, newPage) => setCurrentPage(newPage);

	const handleRowsPerPageChange = event => {
		setPageSize(parseInt(event.target.value, 10));
		setCurrentPage(0);
	}

  const handleClearSearch = () => {
    setStartDate(null);
    setEndDate(null);
    setStatus(null);
    setStatusInput('');
  }

  function getStatuses() {
    loadingStatusesOn();
    
    api.get(`/pontoGO/charges/statuses`)
      .then(({ data }) => {
        setStatuses(convertMapToArray(data));
      })
      .finally(() => loadingStatusesOff());
  }
  
  function getCharge(id) {
    if(id)
      history.push(`/online-point-charges/${id}`)
  }
  
  function getDownloadNfe(url) {
    if(url)
      openNewTab(url)
  }
  
  function getDownloadBankTicket(id) {
    if(id) {
        api.get(`/pontoGO/charges/${id}/bankTicket`, {
            responseType: 'blob',
          })
          .then(({ data }) => {
            const nameDocument = `boleto_${id}`;
            convertBytesToBlobDowload(data, nameDocument);
          });
    }
  }

  function handleClickMenu(event, charge) {
    setAnchorEl(event.currentTarget);
    setCharge(charge);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickOption(option) {
    handleClose();

    switch (option) {
      case 1:
        getDownloadBankTicket(charge?.id);
        break;

      case 2:
        getDownloadNfe(charge?.invoicePdfUrl);
        break;
    
      default:
        break;
    }
  }

  function getCharges() {
    const body = { 
      startDate,
      endDate,
      status: status?.key
    }

    loadingTableOn();

    api.post(`/pontoGO/charges/company/${companyCode}?currentPage=${currentPage}&pageSize=${pageSize}`, body)
      .then(({ data }) => {
        setCharges([])
				handleCharges(data);
      })
      .finally(() => loadingTableOff());
  }

	useEffect(() => {
    if(currentPage != 0 || pageSize != 10)
		  resetPagination();
    else
		  getCharges();
	}, [status, startDate, endDate]);

	useEffect(() => {
		getCharges();
	}, [currentPage, pageSize]);

  useEffect(() => {
    if(!loadingTable && !loadingStatuses)
      setLoading(false);
  }, [loadingTable, loadingStatuses]);

  useEffect(() => {
    getStatuses();
    getCharges();
  }, []);

  return (
    <>
      {loading ? (
        <LogoLoading />
      ) : (
        <>
          <Header/>

          <Filters
            statuses={statuses}
            status={status}
            statusInput={statusInput}
            startDate={startDate}
            endDate={endDate}
            onChangeStatus={onChangeStatus}
            onChangeStatusInput={onChangeStatusInput}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
            validateStartDateInfo={validateStartDateInfo}
            validateEndDateInfo={validateEndDateInfo}
          />
          
          {charges && charges.length
            ? <Table
                data={charges}
                loading={loadingTable}
                totalSize={totalSize}
                pageSize={pageSize}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                TablePaginationActions={TablePaginationActions}
                handleDetailCharge={getCharge}
                anchorEl={anchorEl}
                handleClickOption={handleClickOption}
                handleClickMenu={handleClickMenu}
                handleClose={handleClose}
                charge={charge}
              />
            : withoutCharges
              ? <NoList
                text="As faturas do Ponto Online aparecerão aqui"
              />
              : <ResultNotFound
                text="Nenhum resultado encontrado"
                alternativeText="Verifique os dados pesquisados e tente novamente."
                buttonText="Limpar Pesquisa"
                onClick={handleClearSearch}
              />
          }
        </>
      )}
    </>
  );
}

export default OnlinePointCharges;
