import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = {
  titleStyle: {
    fontSize: 34,
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '-1px',
    marginBottom: 50,
    fontFamily: 'Sofia-Pro',
  },
  subtitle: {
    fontFamily: 'Sofia-Pro',
    fontSize: 18,
    fontWeight: 700,
  },
  spanBold: {
    fontWeight: 'bold',
  },
  card: {
    marginTop: 20,
    marginBottom: 20,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
    border: '1px solid #ffffff',
    borderRadius: 6,
    boxSizing: 'borderBox',
  },
  borderCard: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
    border: '1px solid #7500D1',
    borderRadius: 6,
    boxSizing: 'borderBox',
  },
  notBorderCard: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
    border: '1px solid transparent',
    borderRadius: 6,
    boxSizing: 'borderBox',
  },
  biggerTextStyle: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-0.5px',
  },
  smallerTextStyle: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  buttonStyle: {
    height: '19vh',
    width: '100%',
  },
  padding: {
    padding: 10,
    marginTop: 10,
  },
  primaryTextSTyle: {
    fontSize: '18px',
    lineHeight: '14px',
    fontWeight: 700,
    letterSpacing: '0.1px',
    textTransform: 'none',
    fontFamily: 'DM Sans',
    marginBottom: 10,
  },
  secondaryTextStyle: {
    fontSize: '14px',
    textTransform: 'none',
  },
  radioButtonStyle: {
    color: '#6200EE',
  },
  loadingButtonArea: {
    marginTop: '15px',
    width: '100%',
  },
  loadingButtonStyle: {
    color: '#5C2483',
  },
  paper: {
    marginTop: 20,
    height: 121,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

export default styles;

export const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      display: 'flex',
      marginTop: '30px',
      marginLeft: '-22px',
      marginRight: '-22px',
      wordBreak: 'break-all',
    },
  }),
);
