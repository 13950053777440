import { DefaultModal } from './components/DefaultModal';
import { FullscreenModal } from './components/FullscreenModal';
import { SideModal } from './components/SideModal';
import { Carousel } from './components/Carousel';
import { StepModal } from './components/StepModal';

export default {
  DefaultModal,
  FullscreenModal,
  SideModal,
  Carousel,
  StepModal,
};
