import React from 'react';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

import {
  Box,
  Divider,
  Grid,
  TextField,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import FakeForm from './FakeForm';

import textCapitalize from '../../../utils/textCapitalize';
import formatCPF from '../../../utils/formatCPF';
import formatContactNumber from '../../../utils/formatContactNumber';
import { isMobile } from '../../../utils/breakpoints';

import { errors } from '../../../enums/registerUsersErrors';

import styles from './styles.module.css';

const PurpleCheckbox = withStyles({
  root: {
    color: '#808080',
    '&$checked': {
      color: '#52197F',
    },
  },
  checked: {},
  disabled: {
    opacity: 0.5,
  },
})(props => <Checkbox color="default" {...props} />);

export default function Form({
  editUser,
  DISABLED_INPUTS,
  userForm,
  setUserForm,
  textFieldFocused,
  setTextFieldFocused,
  loading,
  errorsDetail,
}) {
  const { EMAIL_ERROR, CPF_ERROR, PHONE_ERROR, NAME_ERROR } = errors;

  function handleChange(event, name, isCheck) {
    const formFields = userForm;

    formFields[name] = isCheck ? !formFields[name] : event?.target?.value;

    setUserForm({ ...formFields });
  }

  function clearInput(name) {
    const formFields = userForm;

    formFields[name] = '';

    setUserForm({ ...formFields });
  }

  if (loading) {
    return <FakeForm editUser={editUser} />;
  }

  return (
    <Grid
      className={styles.formContainer}
      container
      direction="column"
      justify="flex-start"
    >
      <Grid item>
        {loading ? (
          <Box mb={2}>
            <Skeleton
              width="100%"
              height={26}
              color="#202020"
              highlightColor="#444"
            />
          </Box>
        ) : (
          <TextField
            className={styles.nameField}
            fullWidth
            variant="outlined"
            label="Nome completo"
            value={userForm?.name}
            error={
              errorsDetail?.some(item => item.errorSource === NAME_ERROR) ||
              errorsDetail?.some(
                item => item.errorReason === 'USERNAME_ALREADY_EXISTS',
              )
            }
            helperText={
              errorsDetail?.filter(item => item.errorSource === NAME_ERROR)[0]
                ?.errorDetail ||
              errorsDetail?.filter(
                item => item.errorReason === 'USERNAME_ALREADY_EXISTS',
              )[0]?.errorDetail
            }
            onChange={event => handleChange(event, 'name', false)}
            onFocus={() => {
              setTimeout(() => setTextFieldFocused('name'), 200);
            }}
            onBlur={() => {
              setTimeout(() => setTextFieldFocused(''), 200);
            }}
            InputProps={{
              endAdornment: userForm?.name?.length > 0 &&
                textFieldFocused === 'name' && (
                  <IconButton
                    className={styles.endAdornment}
                    onClick={() => clearInput('name')}
                  >
                    <CloseRoundedIcon className={styles.endAdornmentIcon} />
                  </IconButton>
                ),
              inputMode: 'text',
            }}
            disabled={DISABLED_INPUTS}
          />
        )}
      </Grid>

      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        wrap="nowrap"
        justify={isMobile ? 'flex-start' : 'space-between'}
      >
        <Grid item className={styles.cpfField}>
          {loading ? (
            <Box mb={2}>
              <Skeleton
                width="100%"
                height={26}
                color="#202020"
                highlightColor="#444"
              />
            </Box>
          ) : (
            <TextField
              fullWidth
              variant="outlined"
              label="CPF"
              value={formatCPF(userForm?.cpf)}
              error={errorsDetail?.some(item => item.errorSource === CPF_ERROR)}
              helperText={
                errorsDetail?.filter(item => item.errorSource === CPF_ERROR)[0]
                  ?.errorDetail
              }
              onChange={event => handleChange(event, 'cpf', false)}
              onFocus={() => {
                setTimeout(() => setTextFieldFocused('cpf'), 200);
              }}
              onBlur={() => {
                setTimeout(() => setTextFieldFocused(''), 200);
              }}
              InputProps={{
                endAdornment: userForm?.cpf?.length > 0 &&
                  textFieldFocused === 'cpf' && (
                    <IconButton
                      className={styles.endAdornment}
                      onClick={() => clearInput('cpf')}
                    >
                      <CloseRoundedIcon className={styles.endAdornmentIcon} />
                    </IconButton>
                  ),
              }}
              inputProps={{
                inputMode: 'numeric',
                maxLength: 14,
              }}
              disabled={DISABLED_INPUTS}
            />
          )}
        </Grid>

        <Grid item className={styles.emailField}>
          {loading ? (
            <Box mb={2}>
              <Skeleton
                width="100%"
                height={26}
                color="#202020"
                highlightColor="#444"
              />
            </Box>
          ) : (
            <TextField
              fullWidth
              variant="outlined"
              label="E-mail"
              value={userForm?.email}
              error={errorsDetail?.some(
                item => item.errorSource === EMAIL_ERROR,
              )}
              helperText={
                errorsDetail?.filter(
                  item => item.errorSource === EMAIL_ERROR,
                )[0]?.errorDetail
              }
              onChange={event => handleChange(event, 'email', false)}
              onFocus={() => {
                setTimeout(() => setTextFieldFocused('email'), 200);
              }}
              onBlur={() => {
                setTimeout(() => setTextFieldFocused(''), 200);
              }}
              InputProps={{
                endAdornment: userForm?.email?.length > 0 &&
                  textFieldFocused === 'email' && (
                    <IconButton
                      className={styles.endAdornment}
                      onClick={() => clearInput('email')}
                    >
                      <CloseRoundedIcon className={styles.endAdornmentIcon} />
                    </IconButton>
                  ),
                inputMode: 'email',
              }}
              disabled={DISABLED_INPUTS}
            />
          )}
        </Grid>

        <Grid item className={styles.phoneField}>
          {loading ? (
            <Box mb={2}>
              <Skeleton
                width="100%"
                height={26}
                color="#202020"
                highlightColor="#444"
              />
            </Box>
          ) : (
            <TextField
              fullWidth
              variant="outlined"
              label="Telefone"
              value={formatContactNumber(userForm?.phoneNumber)}
              error={errorsDetail?.some(
                item => item.errorSource === PHONE_ERROR,
              )}
              helperText={
                errorsDetail?.some(item => item.errorSource === PHONE_ERROR) &&
                'Número de telefone inválido'
              }
              onChange={event => handleChange(event, 'phoneNumber', false)}
              onFocus={() => {
                setTimeout(() => setTextFieldFocused('phoneNumber'), 200);
              }}
              onBlur={() => {
                setTimeout(() => setTextFieldFocused(''), 200);
              }}
              InputProps={{
                endAdornment: userForm?.phoneNumber?.length > 0 &&
                  textFieldFocused === 'phoneNumber' && (
                    <IconButton
                      className={styles.endAdornment}
                      onClick={() => clearInput('phoneNumber')}
                    >
                      <CloseRoundedIcon className={styles.endAdornmentIcon} />
                    </IconButton>
                  ),
              }}
              inputProps={{
                maxLength: 15,
                inputMode: 'tel',
              }}
              disabled={DISABLED_INPUTS}
            />
          )}
        </Grid>
      </Grid>

      <Divider className={styles.divider} />

      <Grid item>
        <Typography className={styles.textUserProfile}>
          Qual é o nível de acesso desse usuário?
        </Typography>
      </Grid>

      <Grid
        style={{
          width: !isMobile && '70%',
          marginLeft: !isMobile && '-10px',
        }}
        className={styles.checkboxContainer}
        container
        direction={isMobile ? 'column' : 'row'}
        wrap="nowrap"
        justify="flex-start"
        alignItems="center"
      >
        {loading ? (
          <Box mb={1}>
            <Skeleton
              width={500}
              height={22}
              color="#202020"
              highlightColor="#444"
            />
          </Box>
        ) : (
          <>
            <Tooltip
              title="Acesso a todas as funcionalidades da plataforma,
              exceto autorização de pagamento."
              disableTouchListener={isMobile}
            >
              <Grid
                item
                container
                direction={isMobile ? 'row-reverse' : 'row'}
                wrap="nowrap"
                alignItems="center"
                justify={isMobile && 'space-between'}
                className={!isMobile && styles.checkbox}
              >
                <PurpleCheckbox
                  disabled={DISABLED_INPUTS}
                  onChange={event => handleChange(event, 'admin_access', true)}
                  value={userForm?.admin_access}
                  checked={userForm?.admin_access}
                />
                <Typography>Administrador</Typography>
              </Grid>
            </Tooltip>

            <Tooltip
              title="Acesso a funcão de autorizar pagamentos
              e liberação imediata."
              disableTouchListener={isMobile}
            >
              <Grid
                container
                direction={isMobile ? 'row-reverse' : 'row'}
                wrap="nowrap"
                alignItems="center"
                justify={isMobile && 'space-between'}
                className={!isMobile && styles.checkbox}
              >
                <PurpleCheckbox
                  disabled={DISABLED_INPUTS}
                  onChange={event =>
                    handleChange(event, 'authorizer_access', true)
                  }
                  value={userForm?.authorizer_access}
                  checked={userForm?.authorizer_access}
                />
                <Typography>Autorizador</Typography>
              </Grid>
            </Tooltip>

            <Tooltip
              title="Acesso a gestão de pagamentos de folha
              e gestão de funcionários."
              disableTouchListener={isMobile}
            >
              <Grid
                container
                direction={isMobile ? 'row-reverse' : 'row'}
                wrap="nowrap"
                alignItems="center"
                justify={isMobile && 'space-between'}
              >
                <PurpleCheckbox
                  disabled={DISABLED_INPUTS}
                  onChange={event =>
                    handleChange(event, 'personal_department_access', true)
                  }
                  value={userForm?.personal_department_access}
                  checked={userForm?.personal_department_access}
                />
                <Typography>Departamento pessoal</Typography>
              </Grid>
            </Tooltip>

            <Tooltip
              title="Acesso a gestão de pagamentos e gerenciamento
              financeiro (depósitos e extratos)."
              disableTouchListener={isMobile}
            >
              <Grid
                container
                direction={isMobile ? 'row-reverse' : 'row'}
                wrap="nowrap"
                alignItems="center"
                justify={isMobile && 'space-between'}
              >
                <PurpleCheckbox
                  disabled={DISABLED_INPUTS}
                  onChange={event =>
                    handleChange(event, 'financial_access', true)
                  }
                  value={userForm?.financial_access}
                  checked={userForm?.financial_access}
                />
                <Typography>Financeiro</Typography>
              </Grid>
            </Tooltip>
          </>
        )}
      </Grid>

      {editUser && (
        <Grid item>
          <Divider className={styles.divider} />

          <Typography className={styles.smallLabel}>Criação</Typography>

          {loading ? (
            <>
              <Box mb={1}>
                <Skeleton
                  width={150}
                  height={22}
                  color="#202020"
                  highlightColor="#444"
                />
              </Box>

              <Box>
                <Skeleton
                  width={150}
                  height={22}
                  color="#202020"
                  highlightColor="#444"
                />
              </Box>
            </>
          ) : (
            <>
              <Typography className={styles.defaultText}>
                {textCapitalize(userForm?.creationUser)}
              </Typography>

              <Typography className={styles.label}>
                {`${moment(userForm?.creationDate).format('DD/MM/YYYY')} às
                ${userForm?.creationDate?.substring(11, 16)}`}
              </Typography>
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
}
