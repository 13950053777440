import React from 'react';

import {
  Dialog,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import formatOnlyNumbers from '../../../../../../utils/formatOnlyNumbers';
import formatCPF from '../../../../../../utils/formatCPF';

import styles from './styles.module.css';

export default ({
  open,
  title,
  employeesList,
  onClose,
  onCancelButtonClicked,
  onConfirmButtonClicked,
  handlePisInput,
  pisValue,
  loading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="false">
      <div className={styles.container}>
        <Typography className={styles.title}>{title}</Typography>
        {employeesList.map((data, index) => {
          return (
            <div className={styles.employeeDataFields}>
              <Typography className={styles.dot}> • </Typography>
              <Typography className={styles.text}>
                {formatCPF(data.cpf)}
              </Typography>
              <Typography className={styles.dot}> - </Typography>
              <Typography className={styles.errorMessage}>
                {data.error}
              </Typography>
              {data.isPis && (
                <Typography className={styles.dot}> - </Typography>
              )}
              {data.isPis && (
                <TextField
                  value={formatOnlyNumbers(pisValue[index])}
                  onChange={event => handlePisInput(event, index)}
                  className={styles.pisInput}
                  variant="outlined"
                  label="PIS"
                />
              )}
            </div>
          );
        })}
        <div className={styles.buttonsGroup}>
          <Button
            className={styles.closeButton}
            onClick={onCancelButtonClicked}
          >
            Cancelar
          </Button>
          <Button
            className={styles.confirmButton}
            onClick={onConfirmButtonClicked}
          >
            {loading ? (
              <CircularProgress className={styles.circularLoading} />
            ) : (
              'Salvar'
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
