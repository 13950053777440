import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import * as yup from 'yup';
import {
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from '@material-ui/core';
import styles from './styles.module.css';

import { StepOne, StepTwo, StepSucess } from './UI';
import { ReactComponent as ImageModalActivePlan } from '../../../../assets/modal-active-plan.svg';
import { ValidateFormSchema } from '../../../../utils/validateFormSchema';
import { ActionsPointOnline } from '../../../../redux/store/PointOnline';

function ModalOnboardingPlanActive({
  open,
  cancelButtonText,
  handleCloseModalSucess,
  onCancelButtonClicked,
  confirmButtonText,
  disabledCancel,
  nameUser,
  valuePlanPayment,
  textFieldFocusedChargeEmail,
  setTextFieldFocusedChargeEmail,
  textFieldFocusedAccessEmail,
  setTextFieldFocusedAccessEmail,
  isChecked,
  onChangeCheckbox,
}) {
  const dispatch = useDispatch();
  const {
    stepToActivePlanModal,
    isActivePlanPointOnlineLoading,
    emailNfe,
  } = useSelector(state => state.pointOnline);

  const successStep = stepToActivePlanModal === 'success';

  const validationSchema = yup.object().shape({
    accessEmail: yup
      .string()
      .email('E-mail inválido.')
      .required('O campo é obrigatório.'),
    chargeEmail: yup
      .string()
      .email('E-mail inválido.')
      .required('O campo é obrigatório.'),
  });

  function renderStep(value) {
    switch (value) {
      case 'one':
        return (
          <StepOne
            valuePlanPayment={valuePlanPayment}
            isChecked={isChecked}
            onChangeCheckbox={onChangeCheckbox}
          />
        );
      case 'two':
        return (
          <StepTwo
            textFieldFocusedChargeEmail={textFieldFocusedChargeEmail}
            setTextFieldFocusedChargeEmail={setTextFieldFocusedChargeEmail}
            textFieldFocusedAccessEmail={textFieldFocusedAccessEmail}
            setTextFieldFocusedAccessEmail={setTextFieldFocusedAccessEmail}
          />
        );
      default:
        return <StepSucess />;
    }
  }

  function sendEmailsToActivatePointOnline(e) {
    dispatch(ActionsPointOnline.activePlanPointOnline(e));
  }

  function handleNextStepsToActivatePointOnline() {
    if (successStep) {
      handleCloseModalSucess();
    } else {
      dispatch(ActionsPointOnline.setStepToActivePlanModal('two'));
    }
  }

  return (
    <Dialog open={open} maxWidth="xl">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.contentInfo}>
            {!successStep && (
              <Typography className={styles.stepText}>
                {stepToActivePlanModal === 'one' ? '1/2' : '2/2'}
              </Typography>
            )}

            <Typography variant="h2" className={styles.titleHello}>
              {successStep ? 'Parabéns' : 'Olá'},
            </Typography>

            {nameUser && (
              <Typography variant="h2" className={styles.title}>
                {nameUser?.name}
              </Typography>
            )}

            {successStep ? (
              <Typography variant="h2" className={styles.subtitle}>
                Tudo certo! O produto Ponto Online está ativo e pronto para
                utilização.
              </Typography>
            ) : (
              <Typography variant="h2" className={styles.subtitle}>
                {stepToActivePlanModal === 'one'
                  ? 'Gostaríamos que você revisasse algumas informações para ativar o produto Ponto Online.'
                  : 'Precisamos de algumas informações para completar a ativação.'}
              </Typography>
            )}
          </div>
          <ImageModalActivePlan />
        </div>

        <div className={styles.wrapperContainerInfo}>
          <Form
            initialValues={{
              accessEmail: '',
              chargeEmail: emailNfe || '',
            }}
            validate={ValidateFormSchema(validationSchema)}
            onSubmit={sendEmailsToActivatePointOnline}
          >
            {props => (
              <form className={styles.form} onSubmit={props.handleSubmit}>
                {renderStep(stepToActivePlanModal)}
                <div
                  className={
                    successStep ? styles.buttonSuccess : styles.buttonsGroup
                  }
                >
                  {cancelButtonText && !successStep && (
                    <Button
                      onClick={onCancelButtonClicked}
                      className={styles.closeButton}
                      disabled={disabledCancel}
                    >
                      {cancelButtonText}
                    </Button>
                  )}

                  {stepToActivePlanModal === 'one' || successStep ? (
                    <Button
                      onClick={handleNextStepsToActivatePointOnline}
                      className={styles.confirmButton}
                      disabled={stepToActivePlanModal === 'one' && !isChecked}
                    >
                      {stepToActivePlanModal === 'one' ? 'Próximo' : 'Fechar'}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      className={styles.confirmButton}
                      disabled={
                        props.submitting ||
                        props.pristine ||
                        isActivePlanPointOnlineLoading
                      }
                    >
                      {isActivePlanPointOnlineLoading ? (
                        <CircularProgress className={styles.loader} />
                      ) : (
                        confirmButtonText
                      )}
                    </Button>
                  )}
                </div>
              </form>
            )}
          </Form>
        </div>
      </div>
    </Dialog>
  );
}

export default ModalOnboardingPlanActive;
