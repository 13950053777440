import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

import Title from '../../../../components/Title';
import { isMobile } from '../../../../utils/breakpoints';

import styles from './styles.module.css';

function Header({
  situationPlan,
  loadingManagePointOnline,
  handleManagePointOnline,
}) {
  return (
    <>
      {!isMobile && (
        <div className={styles.header}>
          <Title>Ponto online</Title>
          {situationPlan && (
            <Button
              onClick={handleManagePointOnline}
              className={styles.headerButton}
            >
              {loadingManagePointOnline ? (
                <CircularProgress
                  size={16}
                  className={styles.circularLoadingStyle}
                />
              ) : (
                <span>Gerenciar ponto online</span>
              )}
            </Button>
          )}
        </div>
      )}
    </>
  );
}

export default Header;
