import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Prompt, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';

import {
  CircularProgress,
  FormControl,
  Typography,
  ListItem,
  ListItemText,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  ListItemAvatar,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import DownloadIcon from '@material-ui/icons/GetApp';

import ProgressBar from './UI/ProgressBar';
import ProgressToolBar from '../../../components/ProgressToolBar';
import Info from '../../../components/Info';
import FinishedUploadModal from '../../../components/PrimaryModal';
import InvalidFileModal from '../../../components/PrimaryModal';
import EmptyFileModal from '../../../components/PrimaryModal';
import CancelUploadModal from '../../../components/PrimaryModal';
import AlreadyUploadModal from '../../../components/PrimaryModal';
import ErrorModal from '../../../components/PrimaryModal';

import { ReactComponent as WarningIcon } from '../../../assets/info-yellow.svg';
import { ReactComponent as DescriptionIcon } from '../../../assets/fileText.svg';

import { api } from '../../../services/api';
import styles from './styles';
import { pusherStatus } from '../../../enums/pusher';
import { pusher } from '../../../utils/pusher';

import convertBytesToBlobDownloadXls from '../../../utils/convertBytesToBlobDownloadXls';

const isMobile = window.innerWidth <= 500 || window.innerHeight <= 500;

const EmployeesBatchRegistrationExcelForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const registerMenuData = JSON.parse(localStorage.getItem('registerMenuData'));
  const currencyCompanyIndex = sessionStorage.getItem('currentCompanyIndex');
  const companyIndex = currencyCompanyIndex || 0;
  const company = JSON.parse(sessionStorage.getItem('companies'));
  const uploadDataEmployees = JSON.parse(
    localStorage.getItem(`uploadDataEmployees-${currentCompanyCode}`),
  );

  const [uploadData, setUploadData] = useState(uploadDataEmployees || null);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [file, setFile] = useState([]);
  const [downloadUrl, setDownloadURL] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [errorDetail, setErrorDetail] = useState('');
  const [openUploadCancelModal, setUploadCancelModal] = useState(false);
  const [loadingValidateEmployees, setLoadingValidateEmployees] = useState(
    false,
  );
  const [finishedUploadModal, setFinishedUploadModal] = useState(false);
  const [locationValue, setLocationValue] = useState('');
  const [regime, setRegime] = useState('');
  const [stocking, setStocking] = useState('');
  const [disableNext, setDisableNext] = useState(true);
  const [openEmptyDialog, setOpenEmptyDialog] = useState(false);
  const [alreadyUploadModal, setAlreadyUploadModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [stockings, setStockings] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);

  const uploadCanceled = useSelector(state => state.importFiles.uploadCanceled);

  const statusProgress = uploadDataEmployees?.status;

  const {
    PROCESSED_EMPLOYEES_SUCCESS,
    PROCESSED_EMPLOYEES_ERROR,
    PROCESSING,
    CANCELED,
  } = pusherStatus;

  const { fileType } = registerMenuData;

  const isErpFile = fileType > 1;

  useEffect(() => {
    setDownloadURL(registerMenuData?.exampleLink);
    getLocations();
    getStockings();
    getContracts();

    sessionStorage.removeItem('uploadEmployeesConfirmationData');
  }, []);

  const onDrop = useCallback(acceptedFiles => {
    let value = acceptedFiles[0].name.substring(
      acceptedFiles[0].name.lastIndexOf('.') + 1,
    );

    value = value?.toLowerCase();

    if (isErpFile ? value === 'txt' : value === 'xls' || value === 'xlsx') {
      localStorage.setItem('fileItem', JSON.stringify(acceptedFiles));
      setFile(acceptedFiles);
    } else {
      setOpenDialog(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (isErpFile) {
      if (regime === '' || file.length === 0) {
        setDisableNext(true);
      } else {
        setDisableNext(false);
      }
    } else if (file.length !== 0) {
      setDisableNext(false);
    }
  }, [regime, locationValue, file]);

  useEffect(() => {
    const channel = pusher.subscribe(currentCompanyCode);

    channel.bind('PROCESSED_EMPLOYEES', function(data) {
      setUploadData(data);

      if (data?.status !== CANCELED) {
        localStorage.setItem(
          `uploadDataEmployees-${currentCompanyCode}`,
          JSON.stringify(data),
        );
      }

      if (data?.status === CANCELED) {
        localStorage.removeItem(`uploadDataEmployees-${currentCompanyCode}`);
      }

      if (
        (data?.status === PROCESSED_EMPLOYEES_SUCCESS ||
          data?.status === PROCESSED_EMPLOYEES_ERROR) &&
        data?.status !== CANCELED &&
        data?.companyCode === currentCompanyCode
      ) {
        setLoading(false);
        setFinishedUploadModal(true);
        setUploadCancelModal(false);
      }

      if (data?.status === PROCESSED_EMPLOYEES_ERROR && data?.data) {
        setErrorTitle(data?.data.errorDetail);
        setErrorMessage(data?.data.errorsDescription);
        setErrorModal(true);
        setLoading(false);
        setUploadCancelModal(false);
        setFinishedUploadModal(false);
      }
    });
  }, []);

  const getLocations = () => {
    api
      .get(`companies/${currentCompanyCode}/locations`)
      .then(({ data }) => setLocations(data));
  };

  const getStockings = () => {
    api
      .get(`companies/${currentCompanyCode}/stockings`)
      .then(({ data }) => setStockings(data));
  };

  const getContracts = () => {
    api.get('companies/contracts').then(({ data }) => setContracts(data));
  };

  const onContinueModalClicked = () => {
    const data = uploadDataEmployees?.data || {};
    const fileId = uploadDataEmployees?.fileId;
    const processId = uploadDataEmployees?.processId;

    setLoadingValidateEmployees(true);

    sessionStorage.setItem('employeesRegistrationData', JSON.stringify(data));
    sessionStorage.setItem('employeesRegistrationFileId', fileId);

    api
      .get(`/files/employees/result/${processId}`)
      .then(({ data }) => {
        sessionStorage.setItem('importEmployeesErrors', JSON.stringify(data));
      })
      .finally(() => {
        localStorage.removeItem(`uploadDataEmployees-${currentCompanyCode}`);
        localStorage.removeItem('fileItem');
        history.push(
          `/employees/register-batch/menu/select-company/excel-form/employee-list`,
        );
        setLoadingValidateEmployees(false);
        setFinishedUploadModal(false);
      });
  };

  const onCancelUpload = () => {
    const processId = uploadDataEmployees?.processId;

    setLoadingValidateEmployees(true);

    api
      .put(`files/employees/cancel/${processId}`)
      .then(() => {
        localStorage.removeItem(`uploadDataEmployees-${currentCompanyCode}`);
        dispatch({
          type: 'UPLOAD_CANCELED',
          uploadCanceled: true,
        });
        setFile([]);
      })
      .finally(() => {
        setFinishedUploadModal(false);
        setUploadCancelModal(false);
        setLoadingValidateEmployees(false);
        setLoading(false);
      });
  };

  const onNextClick = () => {
    setIsBlocking(false);
    setLoading(true);

    dispatch({ type: 'UPLOAD_CANCELED', uploadCanceled: false });

    const requestOptions = {
      headers: {
        contractType: regime,
        companyLocation: locationValue,
        stocking,
      },
    };

    const obj = {
      fileName: file[0]?.name,
      date: moment().format('DD/MM/YYYY'),
      location: locationValue,
      regime,
      stocking,
    };

    sessionStorage.setItem('registerFileData', JSON.stringify(obj));

    const formData = new FormData();
    formData.append('file', file[0]);
    api
      .post(
        `/files/employees/import/validate/${company[companyIndex].code}/${registerMenuData.fileType}`,
        formData,
        requestOptions,
      )
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorDetail(errors[0]?.errorDetail);
          setErrorDescription(errors[0]?.errorsDescription);

          if (errors[0]?.errorReason === 'FILE_HAS_ALREADY_BEEN_IMPORTED') {
            setAlreadyUploadModal(true);
          } else if (errors[0]?.errorReason === 'SPREADSHEET_EMPTY') {
            setOpenEmptyDialog(true);
          } else {
            setErrorModal(true);
          }
          setLoading(false);
        },
      );
  };

  const onBackClicked = () => history.push('/employees/register-batch/menu');

  const onRemoveFileClicked = () => setFile([]);

  const handleChangeLocations = event => setLocationValue(event.target.value);

  const handleChangeStocking = event => setStocking(event.target.value);

  const handleChangeRegime = event => setRegime(event.target.value);

  const clearInputs = () => {
    dispatch({
      type: 'UPLOAD_CANCELED',
      uploadCanceled: true,
    });

    onRemoveFileClicked();

    localStorage.removeItem(`uploadDataEmployees-${currentCompanyCode}`);

    setLoading(false);
  };

  const renderDropzone = () => {
    if (
      uploadDataEmployees?.status === PROCESSING &&
      uploadDataEmployees?.companyCode === currentCompanyCode &&
      !uploadCanceled
    ) {
      return (
        <>
          <div style={styles.dropzoneStyle}>
            <ListItem>
              <ListItemAvatar style={styles.fileAvatarStyle}>
                <DescriptionIcon width="58" height="58" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Typography style={styles.fileTextStyle}>
                      {uploadDataEmployees ? uploadDataEmployees?.fileName : ''}
                    </Typography>
                    {uploadDataEmployees?.percentage === 100 ? (
                      <Typography style={styles.fileTextDescriptionFinish}>
                        Importação concluída
                      </Typography>
                    ) : uploadDataEmployees?.status === PROCESSING ? (
                      <div style={{ display: 'flex' }}>
                        <CircularProgress style={styles.circularProgress} />
                        <Typography style={styles.fileTextDescription}>
                          {uploadDataEmployees?.statusDescription}
                        </Typography>
                        <Typography style={styles.fileTextProgress}>
                          {uploadDataEmployees?.percentage} %
                        </Typography>
                      </div>
                    ) : null}
                  </>
                }
              />
            </ListItem>
            <Button
              variant="outlined"
              style={styles.fileButtonStyle}
              onClick={() => setUploadCancelModal(true)}
              disabled={uploadDataEmployees?.percentage === 0}
            >
              Cancelar
            </Button>
          </div>
          <Typography style={styles.infoTextStyleLoad}>
            Enquanto verificamos o arquivo você pode continuar seu trabalho e
            acompanhar o andamento pelo indicador na parte superior.
          </Typography>
          <ProgressBar progress={uploadData?.percentage || 0} />
        </>
      );
    }

    if (file.length === 0) {
      return (
        <>
          <Button
            {...getRootProps()}
            style={isMobile ? styles.mobileDropzoneStyle : styles.dropzoneStyle}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography style={styles.primaryColor}>Soltar</Typography>
            ) : (
              <Typography style={styles.buttonTextStyle}>
                <PublishIcon />
                Enviar um arquivo
              </Typography>
            )}
          </Button>
          <Typography style={styles.infoTextStyle}>
            Você pode arrastar ou clicar na caixa para selecionar o arquivo.
          </Typography>
        </>
      );
    }

    return (
      <>
        <div style={styles.dropzoneStyle}>
          <ListItem>
            <ListItemAvatar style={styles.fileAvatarStyle}>
              <DescriptionIcon width="58" height="58" />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography style={styles.fileTextStyle}>
                  {file[0].name}
                </Typography>
              }
            />
          </ListItem>
          <Button
            variant="outlined"
            style={styles.fileButtonStyle}
            onClick={onRemoveFileClicked}
            disabled={loading || uploadDataEmployees?.status === PROCESSING}
          >
            Remover
          </Button>
        </div>
        <Typography style={styles.infoTextStyle}>
          Enquanto verificamos o arquivo você pode continuar seu trabalho.
        </Typography>
      </>
    );
  };

  function downloadModel() {
    const requestConfig = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
        accept: 'application/xls',
      },
      responseType: 'blob',
    };
    api
      .get('files/employees/download/model', requestConfig)
      .then(({ data }) => {
        convertBytesToBlobDownloadXls(
          data,
          'modelo de registro de funcionário',
        );
      })
      .catch(() => {
        setErrorModal(true);
        setErrorTitle(
          'Houve um erro ao tentar baixar o modelo. Tente novamente mais tarde.',
        );
      });
  }

  return (
    <div style={isErpFile ? styles.contentPadding : null}>
      <Prompt
        when={isBlocking}
        message="Você tem certeza que deseja cancelar a criação destes funcionários a partir de um arquivo?"
      />
      <Typography style={styles.titleStyle}>
        {isErpFile
          ? 'Cadastrar a partir de um arquivo'
          : 'Cadastro via planilha do Excel'}
      </Typography>
      <div style={isMobile ? styles.mobileContentStyle : styles.contentStyle}>
        <div style={styles.block}>
          <Grid container justify="space-between" alignItems="flex-start">
            <Typography style={styles.biggerTextStyle}>
              {isErpFile
                ? '1. Comece extraindo o arquivo do seu ERP no layout Somapay'
                : '1. Comece fazendo o download do modelo da planilha'}
            </Typography>
            {!isErpFile && (
              <Button
                style={styles.primaryColor}
                startIcon={<DownloadIcon />}
                onClick={downloadModel}
              >
                Baixar modelo
              </Button>
            )}
          </Grid>
          <Info
            warning
            icon={<WarningIcon />}
            text={
              isErpFile
                ? 'Certifique-se que o layout exportado seja o da Somapay'
                : 'Após download, utilize o Excel ou equivalente e preencha os dados dos seus colaboradores'
            }
          />
        </div>
        <Grid>
          <Typography style={styles.biggerTextStyle}>
            {isErpFile
              ? '2. Configure o cadastro'
              : '2. Envie o arquivo preenchido'}
          </Typography>
          <Info
            warning
            icon={<WarningIcon />}
            text={
              isErpFile
                ? 'Importante: a configuração abaixo será aplicada em todos os cadastros contidos no arquivo'
                : 'Com a planilha preenchida, envie o arquivo utilizando o botão abaixo'
            }
          />
          {isErpFile && (
            <div style={styles.inputs}>
              <FormControl variant="outlined" style={styles.formControl}>
                <InputLabel id="location-select">Centro de Custo</InputLabel>
                <Select
                  labelId="location-select"
                  disabled={
                    loading ||
                    (uploadData?.status === PROCESSING &&
                      uploadData?.companyCode === currentCompanyCode)
                  }
                  value={locationValue}
                  onChange={handleChangeLocations}
                  label="Centro de custo"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {locations.map(item => (
                    <MenuItem value={item.code}>{item.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" style={styles.formControl}>
                <InputLabel id="stocking-select">Setor</InputLabel>
                <Select
                  labelId="stocking-select"
                  disabled={
                    loading ||
                    (uploadData?.status === PROCESSING &&
                      uploadData?.companyCode === currentCompanyCode)
                  }
                  value={stocking}
                  onChange={handleChangeStocking}
                  label="Setor"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {stockings.map(item => (
                    <MenuItem value={item.code}>{item.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" style={styles.formControl}>
                <InputLabel id="regime-select">Regime*</InputLabel>
                <Select
                  labelId="regime-select"
                  value={regime}
                  onChange={handleChangeRegime}
                  label="Regime*"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  disabled={
                    loading ||
                    (uploadData?.status === PROCESSING &&
                      uploadData?.companyCode === currentCompanyCode)
                  }
                >
                  {contracts.map(item => (
                    <MenuItem value={item.code}>{item.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </Grid>
        {isErpFile && (
          <Typography style={styles.biggerTextStyle}>
            3. Envie o arquivo preenchido
          </Typography>
        )}
        {renderDropzone()}
      </div>

      <InvalidFileModal
        open={openDialog}
        onConfirmClicked={() => {
          clearInputs();
          setOpenDialog(false);
        }}
        title="Formato de arquivo inválido"
        text={`Escolha um arquivo do tipo ${
          fileType === isErpFile ? '.txt' : '.xls ou .xlsx'
        }`}
        confirmButtonText="Entendi"
      />

      <EmptyFileModal
        open={openEmptyDialog}
        title="Planilha vazia"
        text="Verifique se a planilha está preenchida corretamente"
        confirmButtonText="Entendi"
        onConfirmClicked={() => setOpenEmptyDialog(false)}
      />

      <ErrorModal
        open={errorModal}
        onConfirmClicked={() => {
          clearInputs();
          setErrorModal(false);
        }}
        title={errorTitle}
        text={errorMessage}
        confirmButtonText="Entendi"
      />

      <FinishedUploadModal
        open={finishedUploadModal}
        onConfirmClicked={onContinueModalClicked}
        confirmButtonText={
          statusProgress === PROCESSED_EMPLOYEES_ERROR ? 'Entendi' : 'Continuar'
        }
        title={
          statusProgress === PROCESSED_EMPLOYEES_ERROR && isErpFile
            ? 'Arquivo ERP com dados incorretos.'
            : statusProgress === PROCESSED_EMPLOYEES_ERROR && !isErpFile
            ? 'Preenchimento de arquivo incorreto'
            : 'Processamento concluído com sucesso!'
        }
        text={
          statusProgress === PROCESSED_EMPLOYEES_ERROR && isErpFile
            ? 'Identificamos dados divergentes no seu arquivo. Clique em entendi e verifique as divergências encontradas.'
            : statusProgress === PROCESSED_EMPLOYEES_ERROR && !isErpFile
            ? 'Verifique se você preencheu o arquivo modelo corretamente e tente novamente.'
            : 'Clique em “CONTINUAR” para seguir com o processo.'
        }
        loading={loadingValidateEmployees}
      />

      <CancelUploadModal
        loading={loadingValidateEmployees}
        open={openUploadCancelModal}
        onConfirmClicked={onCancelUpload}
        onCancelButtonClicked={() => setUploadCancelModal(false)}
        confirmButtonText="Sim, cancelar"
        cancelButtonText="Não, continuar"
        title="Tem certeza que quer cancelar o processamento do arquivo?"
        text="Você perderá o progresso processado até agora e essa ação não poderá ser desfeita."
      />

      <AlreadyUploadModal
        loading={loadingValidateEmployees}
        open={alreadyUploadModal}
        onConfirmClicked={() => setAlreadyUploadModal(false)}
        confirmButtonText="Entendi"
        title={errorDetail}
        text={errorDescription}
      />

      <ProgressToolBar
        onBackClicked={onBackClicked}
        onNextClicked={onNextClick}
        nextButtonText="Processar"
        disabled={disableNext}
        loading={
          loading ||
          (uploadData?.status === PROCESSING &&
            uploadData?.companyCode === currentCompanyCode)
        }
      />
    </div>
  );
};

export default EmployeesBatchRegistrationExcelForm;
