import React from 'react';

import { Box, Typography } from '@material-ui/core';

import Lottie from 'react-lottie';
import styles from './styles.module.css';

import checkAnimation from '../../../../../../lotties/check.json';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: checkAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function StepSucess() {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Lottie options={animationOptions} />
      </div>

      <Box>
        <Typography className={styles.title}>
          Seu plano foi ativado com sucesso!
        </Typography>
      </Box>
    </div>
  );
}

export default StepSucess;
