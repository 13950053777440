import React from 'react';

import {
	Paper, Table,
    TableBody, TableCell,
    TableContainer, TableFooter,
    TableHead, TablePagination,
    TableRow
} from '@material-ui/core';

import FakeRowsTable from './FakeRowsTable';

import formatCPF from '../../../../utils/formatCPF';
import convertBrazilianDate from '../../../../utils/convertBrazilianDate';

import styles from './styles';
import classes from './styles.module.css';

export default({
    data,
	loading,
	totalSize,
	pageSize,
	currentPage,
	handlePageChange,
	handleRowsPerPageChange,
	TablePaginationActions,
}) => {

	const handleStatus = status => {
		switch (status) {
			case "ACTIVE":
				return "Ativo";
		
			case "INACTIVE":
				return "Inativo";
		
			default:
				return status;
		}
	}

    return (
        <TableContainer component={Paper} className={classes.root}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell style={styles.tableHeadLabel}>CPF</TableCell>
						<TableCell style={styles.tableHeadLabel}>Situação</TableCell>
						<TableCell style={styles.tableHeadLabel}>Data de criação</TableCell>
						<TableCell style={styles.tableHeadLabel}>Data de cancelamento</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{loading ? (
						FakeRowsTable()
					) : (pageSize > 0
						? data.slice(currentPage * pageSize, currentPage * pageSize + pageSize)
						: data
					)?.map(element => (

						<TableRow hover key={element.id}>
							<TableCell component="th" scope="row" align="left">
								{formatCPF(element.cpf)}
							</TableCell>
							<TableCell align="left">
								{handleStatus(element.status)}
							</TableCell>
							<TableCell align="left">
								{convertBrazilianDate(element.createdAt)}
							</TableCell>
							<TableCell align="left">
								{element.cancelationDate ? convertBrazilianDate(element.cancelationDate) : "-"}
							</TableCell>							
						</TableRow>
					))}
				</TableBody>

				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							count={totalSize}
							rowsPerPage={pageSize}
							page={currentPage}
							labelRowsPerPage="linhas por página"
							labelDisplayedRows={
								function defaultLabelDisplayedRows({ from, to, count }) {
									return `${from}–${to} de ${count !== -1 ? count : `mais que ${to}`}`;
								}}
							onChangePage={handlePageChange}
							onRowsPerPageChange={handleRowsPerPageChange}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
    );
}