import React from 'react';
import { AccordionActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styles from './styles.module.css';
import { ChipStatus } from '../../../../../ChipStatus';

export function MenuAccordionButton({
  text = '-',
  option = '',
  onClick = () => {},
  isNew = false,
  selected = false,
  disabled = false,
}) {
  return (
    <AccordionActions
      style={{
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <Button
        onClick={onClick}
        disabled={disabled}
        size="medium"
        classes={{
          label: makeStyles({
            label: {
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: isNew ? 'space-between' : 'start',
            },
          })().label,
        }}
        className={
          selected === option
            ? styles.menuAccordionActionsButtonToggled
            : styles.menuAccordionActionsButton
        }
      >
        {text}
        {isNew && (
          <ChipStatus
            label="novo"
            size="small"
            variant="filled"
            status="secondary"
            classes={{
              sizeSmall: makeStyles({ sizeSmall: { height: 'fit-content' } })()
                .sizeSmall,
              labelSmall: makeStyles({ labelSmall: { paddingX: '0.5rem' } })()
                .labelSmall,
            }}
            chipStyle={{
              display: 'flex',
              alignItems: 'center',
              textTransform: 'uppercase',
              fontSize: '0.65rem',
              fontWeight: 'bold',
            }}
          />
        )}
      </Button>
    </AccordionActions>
  );
}
