import React, { useState } from 'react';

import {
  FormControl,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import Search from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import styles from './styles.module.css';

function Filters({
  cpfOrName,
  setCpfOrName,
  onSearchChange,
  changedFilter,
  situationPlan,
  searchCostAndCenterOnlinePoint,
  costAndCenterOnlinePoint,
  handleChangeCostAndCenter,
}) {
  const [textFieldFocused, setTextFieldFocused] = useState('');

  return (
    <>
      {!situationPlan && (
        <Grid container spacing={1} className={styles.containerInputs}>
          <Grid item xs={12} sm={9} md={9} lg={9} wrap="nowrap">
            <TextField
              onFocus={() =>
                setTimeout(() => setTextFieldFocused('searchText'), 200)
              }
              onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
              onChange={onSearchChange}
              className={styles.searchBackground}
              placeholder="Pesquisar por nome ou CPF"
              autoFocus={changedFilter && cpfOrName}
              fullWidth
              variant="outlined"
              value={cpfOrName || ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search className={styles.iconColor} />
                  </InputAdornment>
                ),
                endAdornment: cpfOrName?.length > 0 &&
                  textFieldFocused === 'cpfOrName' && (
                    <IconButton
                      className={styles.endAdornment}
                      onClick={() => setCpfOrName('')}
                    >
                      <CloseRoundedIcon className={styles.endAdornmentIcon} />
                    </IconButton>
                  ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <FormControl
              variant="outlined"
              className={styles.formControl}
              fullWidth
            >
              <InputLabel id="select-locations">Centro de custo</InputLabel>
              <Select
                labelId="select-locations"
                id="select-outlined"
                value={searchCostAndCenterOnlinePoint}
                onChange={({ target: { value } }) =>
                  handleChangeCostAndCenter(value)
                }
                label="Centro de custo"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value="Todos">Todos</MenuItem>
                {costAndCenterOnlinePoint.map(item => (
                  <MenuItem value={item.description}>
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Filters;
