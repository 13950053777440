import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  IconButton,
  Typography,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Form from '../../../components/Form';

import { ResetPasswordActions as Actions } from '../../../redux/store/ResetPassword';

import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

import { ReactComponent as LogoEmpresa } from '../../../assets/somapay_digital_bank.svg';
import { ReactComponent as ArrowBack } from '../../../assets/arrow_back.svg';

import styles from './styles.module.css';

const validations = yup.object().shape({
  token: yup
    .string()
    .required()
    .length(10),
});

const EmailAndCpf = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { error } = handleSnackbarStack();

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    initialValues: {
      token: '',
    },
    resolver: yupResolver(validations),
  });

  const { isLoading } = useSelector(state => state.resetPassword);

  const sendCode = async ({ token }) => {
    const actionResult = await dispatch(
      Actions.getEmailConfirmation({ token }),
    );

    try {
      unwrapResult(actionResult);
      history.push('/reset-password/sms');
    } catch (err) {
      error(err);
    }
  };

  const goBackStep = () => {
    history.push('/reset-password/email-and-cpf');
  };

  const goToRequestHelp = () => {
    history.push('/reset-password/request-help');
  };

  return (
    <div className={styles.pageContainer}>
      <MuiThemeProvider>
        <Container>
          <CssBaseline />
          <div className={styles.paper}>
            <IconButton className={styles.arrowBack} onClick={goBackStep}>
              <ArrowBack />
            </IconButton>

            <div>
              <LogoEmpresa width={240} height={65} />
            </div>

            <form className={styles.form} onSubmit={handleSubmit(sendCode)}>
              <div className={styles.content}>
                <Typography className={styles.textBigger}>
                  Digite o código enviado para seu e-mail.
                </Typography>

                <Controller
                  control={control}
                  name="token"
                  render={({ field }) => (
                    <Form.TextField
                      size="medium"
                      variant="outlined"
                      margin="normal"
                      error={!!errors?.token}
                      helperText={errors?.token?.message}
                      fullWidth
                      label="Código"
                      className={styles.input}
                      {...field}
                      disabled={isLoading}
                    />
                  )}
                />

                <Typography className={styles.textSmallAlert}>
                  Não recebeu o e-mail? Verifique as pastas Spam e Lixeira.
                </Typography>
              </div>

              <div className={styles.submitArea}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!isValid}
                  type="submit"
                >
                  {isLoading ? (
                    <div className={styles.circularProgress}>
                      <CircularProgress
                        className={styles.spinner}
                        size={styles.spinner}
                      />
                    </div>
                  ) : (
                    'Continuar'
                  )}
                </Button>

                <Button
                  className={styles.ghostButton}
                  onClick={goToRequestHelp}
                >
                  Quero tentar de outra forma
                </Button>
              </div>
            </form>
          </div>
        </Container>
      </MuiThemeProvider>
    </div>
  );
};

export default EmailAndCpf;
