import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import Modals from '../../../../../components/Modals';
import { ProductRatingStep } from './components/ProductRatingStep';
import { SomapayIndicationStep } from './components/SomapayIndicationStep';
import { SuggestionsStep } from './components/SuggestionsStep';
import { useNpsModal } from './hooks/useNpsModal';

export const NpsModal = NiceModal.create(
  ({ currentCompanyCode = '', userName = '' }) => {
    const { modal, form } = useNpsModal({ currentCompanyCode, userName });

    return (
      <Modals.StepModal
        open={modal.open}
        title="Pesquisa de satisfação do cliente"
        stepsQtt={3}
        onClose={modal.onClose}
        onPrimaryButtonClick={form.onSubmitNps}
        isLoading={form.isLoading}
      >
        <ProductRatingStep
          control={form.control}
          productRatingOptions={form.productRatingOptions}
        />

        <SomapayIndicationStep
          control={form.control}
          indicationRatingOptions={form.indicationRatingOptions}
        />

        <SuggestionsStep control={form.control} />
      </Modals.StepModal>
    );
  },
);
