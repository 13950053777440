export default (data) => {
	let list = [];

	if(data) {
		Object.keys(data).map(key => {
			list.push({key: key, value: data[key]});
		})
	}

	return list.sort((a, b) => {
		if(a.value > b.value)
			return 1;
		else if(a.value < b.value)
			return -1;
		return 0;
	});
}