import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import moment from 'moment';
import Lottie from 'react-lottie';

import {
  Typography,
  Button,
  Grid,
  Menu,
  MenuItem,
  Hidden,
  Drawer,
  TextField,
  InputAdornment,
  Chip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Search } from '@material-ui/icons';

import { styles, useStyles, theme } from './styles';
import style from './styles.module.css';
import { useStyles1 } from './UI/styles';

import TablePaginationActions from './UI/TablePaginationActions';
import ConfirmImmediateReleaseModal from './UI/ConfirmImmediateReleaseModal';
import Filters from './UI/Filters/Filters';
import BottomBar from './UI/BottomBar';
import ContentFilterDrawer from './UI/ContentFilterDrawer';
import Table from './UI/Table';

import CancelPayrollDialog from '../../../components/CancelPayrollDialog';
import RescheduleDialog from '../../../components/RescheduleDialog';
import AlterDateAndAuthorizePayrollModal from '../../../components/AlterDateAndAuthorizePayrollModal';
import LogoLoading from '../../../components/LogoLoading';
import AlreadyUploadModal from '../../../components/PrimaryModal';
import AuthorizePayrollModal from '../../../components/ProgressModal';
import BalancesModal from '../../../components/BalancesModal';
import LoadingModal from '../../../components/LoadingModal';
import SuccessModal from '../../../components/SuccessModal';
import ResultNotFound from '../../../components/ResultNotFound';
import MobileList from '../../../components/MobileList';
import ErrorModal from '../../../components/PrimaryModal';

import CustomSnackbar from '../../../components/Snackbar';

import { useValidationForUnformattedDate } from '../../../hooks/useValidationForUnformattedDate';

import { ActionsBalance } from '../../../redux/store/Balances';

import { api } from '../../../services/api';

import currencyFormatter from '../../../utils/currencyFormatter';
import somapayLogoAnimation from '../../../lotties/somapayLogo.json';
import { pusherStatus } from '../../../enums/pusher';
import processErrorMessage from '../../../utils/processErrorMessage';

import { ReactComponent as DollarSign } from '../../../assets/DollarSign.svg';
import { ReactComponent as PlusIcon } from '../../../assets/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/minusIcon.svg';
import { ReactComponent as FilterIcon } from '../../../assets/filter-icon.svg';

import FloatButton from '../../../components/FloatButton';
import { actions } from './actions';

import { hasAuthorization, profiles } from '../../../utils/hasAuthorization';
import { isSubsidiaryCompanie } from '../../../utils/isSubsidiaryCompanie';

import { pusher } from '../../../utils/pusher';
import useGeolocation from '../../../hooks/useGeolocation';
import { useCountdown } from '../../../hooks/useValidationForUnformattedDate/useCountdown';
import { ActionsManualPayroll } from '../../../redux/store/Payroll/ManualPayroll';
import { ActionsPayrollDetails } from '../../../redux/store/Payroll/PayrollDetails';
import Form from '../../../components/Form';

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: somapayLogoAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const isMobile = window.innerWidth <= 500 || window.innerHeight <= 500;

const ConsultPayroll = () => {
  const dispatch = useDispatch();
  const location = useGeolocation();

  const { state: paramsActivities } = useLocation();
  const nsuActivities = paramsActivities?.nsu;
  const statusActivities =
    paramsActivities?.status &&
    (paramsActivities?.status === 'Expirada' ? 8 : 1);

  const selectedPayments = sessionStorage.getItem('selectedPayments');
  const userJobRole = JSON.parse(sessionStorage.getItem('userJobRole'));
  const isTutorialModalOpen = userJobRole?.viewedComponents?.some(
    item => item.component === 'BATCH_AUTHORIZATION_TUTORIAL',
  );
  const isTutorialCustomTooltipOpen = userJobRole?.viewedComponents?.some(
    item => item.component === 'BATCH_AUTHORIZATION_CUSTOMTOOLTIP',
  );

  const COUNTDOWN_TIME = 60;

  const [expressPayment, setExpressPayment] = useState(false);
  const [currentPaymentSchedule, setCurrentPaymentSchedule] = useState('');
  const [payments, setPayments] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowAnchorElWaiting, setRowAnchorElWaiting] = useState(null);
  const [
    rowAnchorElInsuficientFounds,
    setRowAnchorElInsuficientFounds,
  ] = useState(null);
  const [rowAnchorEl, setRowAnchorEl] = useState(null);
  const [rowAnchorElCancelPayroll, setAnchorElRowCancelPayroll] = useState(
    null,
  );
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [
    openAlterDateAndAuthorizePayrollModal,
    setOpenAlterDateAndAuthorizePayrollModal,
  ] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openTutorialModal, setOpenTutorialModal] = useState(
    !isTutorialModalOpen,
  );
  const [openTutorialCustomTooltip, setOpenTutorialCustomTooltip] = useState(
    !isTutorialCustomTooltipOpen,
  );
  const [cancelButton, setCancelButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [circularLoading, setCircularLoading] = useState(false);
  const [confirButtonStyle, setConfirButtonStyle] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(0);
  const [nsu, setNsu] = useState(0);
  const [scheduleDate, setScheduleDate] = useState('');
  const availablePaymentHours = [
    { value: '21:00:00', description: '21:00' },
    { value: '20:00:00', description: '20:00' },
    { value: '19:00:00', description: '19:00' },
    { value: '18:00:00', description: '18:00' },
    { value: '17:00:00', description: '17:00' },
    { value: '16:00:00', description: '16:00' },
    { value: '15:00:00', description: '15:00' },
    { value: '14:00:00', description: '14:00' },
    { value: '13:00:00', description: '13:00' },
    { value: '12:00:00', description: '12:00' },
    { value: '11:00:00', description: '11:00' },
    { value: '10:00:00', description: '10:00' },
    { value: '09:00:00', description: '09:00' },
    { value: '08:00:00', description: '08:00' },
    { value: '07:00:00', description: '07:00' },
    { value: '06:00:00', description: '06:00' },
    { value: '05:00:00', description: '05:00' },
    { value: '04:00:00', description: '04:00' },
  ];
  const [
    selectedAvailablePaymentHour,
    setSelectedAvailablePaymentHour,
  ] = useState('');
  const [messageError, setMessageError] = useState('');
  const [requestId, setRequestId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [pinValue, setPinValue] = useState(null);
  const anchorRef = useRef(null);
  const history = useHistory();
  const { url } = useRouteMatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState(
    selectedPayments ? JSON.parse(selectedPayments) : [],
  );
  const [uniqueNsuSelected, setUniqueNsuSelected] = useState([]);
  const [paymentType, setPaymentType] = useState('');
  const [filterPaymentType, setFilterPaymentType] = useState('Todos');
  const [statusPayroll, setStatusPayroll] = useState('');
  const [openImmediateReleaseModal, setOpenImmediateReleaseModal] = useState(
    false,
  );
  const [
    openImmediateReleaseSuccessModal,
    setOpenImmediateReleaseSuccessModal,
  ] = useState(false);
  const [
    openConfirmImmediateReleaseModal,
    setOpenConfirmImmediateReleaseModal,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [insufficientFunds, setInsufficientFunds] = useState(false);
  const [rateValues, setRateValues] = useState({});
  const [loadingRates, setLoadingRates] = useState(false);

  const today = moment().format('DD/MM/YYYY');
  const token = sessionStorage.getItem('@ContaSoma-Token');
  const companyCodeValueReducer = useSelector(
    state => state.currentCompanyReducer.company,
  );

  const companyCodeValueStorage = sessionStorage.getItem('currentCompanyCode');
  const companyCodeValue = companyCodeValueStorage || companyCodeValueReducer;
  const uploadDataPayroll = JSON.parse(
    localStorage.getItem(`uploadDataPayroll-${companyCodeValue}`),
  );

  const classes = useStyles();
  const classes1 = useStyles1();
  const [loadingButton, setLoadingButton] = useState(false);
  const [errorAuthorization, setErrorAuthorization] = useState(false);
  const [alreadyUploadModal, setAlreadyUploadModal] = useState(false);
  const [authorizePayrollModal, setAuthorizePayrollModal] = useState(false);
  const [insufficientBalanceModal, setInsufficientBalanceModal] = useState(
    false,
  );
  const [loadingModal, setLoadingModal] = useState(false);
  const [avaliableBalance, setAvaliableBalance] = useState({});
  const [channelData, setChannelData] = useState({});
  const [searchTextNSU, setSearchTextNSU] = useState(nsuActivities || '');
  const [situation, setSituation] = useState(statusActivities || 'Todos');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [changedFilter, setChangedFilter] = useState(false);
  const [cleanFilter, setCleanFilter] = useState(false);
  const [datesInput, setDatesInput] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const [searchFocus, setSearchFocus] = useState('');
  const [isFilteredType, setIsFilteredType] = useState(false);
  const [isFilteredSituation, setIsFilteredSituation] = useState(false);
  const [isFilteredFinalDate, setIsFilteredFinalDate] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [pinOnConfirmPayroll, setPinOnConfirmPayroll] = useState(false);

  const [payrollTypes, setPayrollTypes] = useState([]);
  const [type, setType] = useState('');
  const [situation2, setSituation2] = useState('');
  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');
  const [openModalError, setOpenModalError] = useState(false);
  const [errorMessageModal, setErrorMessageModal] = useState(null);
  const [sendPinStatus, setSendPinStatus] = useState(null);

  const [severity, setSeverity] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [nsuList, setNsuList] = useState([]);
  const [countdownHasStarted, setCountDownHasStarted] = useState(false);
  const { currentTime } = useCountdown(COUNTDOWN_TIME, countdownHasStarted);

  const zeroProduct = JSON.parse(sessionStorage.getItem('zeroProduct'));

  const validateInitialDateInfo = useValidationForUnformattedDate(initialDate);
  const validateFinalDateInfo = useValidationForUnformattedDate(finalDate);

  const multiplePaymentsSelected = selected.length > 1;
  const isSubsidiary = isSubsidiaryCompanie();
  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));

  const { accountSelected } = useSelector(state => state.account);

  const FILTER_FULL_LIST = 'Todos';

  const {
    PROCESSED_PAYROLL_SUCCESS,
    PROCESSING,
    PROCESSED_PAYROLL_ERROR,
  } = pusherStatus;

  const formatNumber = rawNumber =>
    '****'?.concat(rawNumber?.slice(rawNumber?.length - 4));

  const authorizePayrollModalOn = () => setAuthorizePayrollModal(true);

  const resetError = () => setErrorMessage('');

  const verifyValueOrNull = (key, value) => {
    if (value === 'null') {
      return '( Em branco )';
    }
    return value;
  };

  useEffect(() => {
    setScheduleDate(moment().format('YYYY-MM-DD'));
    sessionStorage.removeItem('manualPaymentEmployees');
    sessionStorage.removeItem('paymentInfo');

    dispatch(ActionsManualPayroll.setSelectedRowsEmployees([]));

    dispatch({
      type: 'SET_VALUE_INPUTS',
      inputValue: '',
    });

    verifyNecessityOfPinForAuthorizePayroll();
    getPaymentTypes();
  }, []);

  useEffect(() => {
    if (!filterPaymentType && !situation && !finalDate) {
      payRollList();
    }
  }, [cleanFilter]);

  useEffect(() => {
    if (
      searchTextNSU ||
      filterPaymentType !== 'Todos' ||
      filterPaymentType === 0 ||
      situation !== 'Todos' ||
      situation === 0 ||
      initialDate ||
      finalDate
    ) {
      setCleanFilter(false);
      setChangedFilter(true);
    }
  }, [searchTextNSU, filterPaymentType, situation, initialDate, finalDate]);

  useEffect(() => {
    if (!isMobile) {
      payRollList();
    }
  }, [
    companyCodeValue,
    currentPage,
    pageSize,
    filterPaymentType,
    situation,
    orderBy,
    order,
    accountSelected,
  ]);

  useEffect(() => {
    if (changedFilter) {
      const delay = setTimeout(() => {
        payRollList();
      }, 500);

      return () => clearTimeout(delay);
    }
  }, [searchTextNSU]);

  useEffect(() => {
    if (!initialDate && !finalDate) {
      setDatesInput(false);
      setCleanFilter(false);
    }

    if (
      (initialDate &&
        finalDate &&
        !validateFinalDateInfo &&
        !validateInitialDateInfo &&
        !isMobile) ||
      cleanFilter
    ) {
      setCurrentPage(0);
      setDatesInput(true);
      payRollList();
    }

    if (
      ((!initialDate && !finalDate) ||
        (!initialDate && finalDate && !validateFinalDateInfo) ||
        (initialDate && !finalDate && !validateInitialDateInfo)) &&
      datesInput &&
      !isMobile
    ) {
      payRollList();
    }
  }, [initialDate, finalDate]);

  useEffect(() => {
    if (!isFilteredType || !isFilteredSituation || !isFilteredFinalDate) {
      payRollList();
    }
  }, [isFilteredType, isFilteredSituation, isFilteredFinalDate]);

  useEffect(() => {
    if (
      moment(scheduleDate).format('YYYY-MM-DD') ===
      moment().format('YYYY-MM-DD')
    ) {
      if (moment().format('HH:00:00') < '21:00:00') {
        setSelectedAvailablePaymentHour(
          moment()
            .add(1, 'hour')
            .format('HH:00:00'),
        );
      } else {
        setSelectedAvailablePaymentHour('');
      }
    } else if (moment().format('HH:00:00') < '21:00:00') {
      setSelectedAvailablePaymentHour(moment().format('HH:00:00'));
    } else {
      setSelectedAvailablePaymentHour('04:00:00');
    }
  }, [scheduleDate]);

  function onConfirmTutorial(nameComponent) {
    const arr = userJobRole?.viewedComponents;
    const requestBody = {
      component: nameComponent,
    };

    if (nameComponent === 'BATCH_AUTHORIZATION_TUTORIAL') {
      setOpenTutorialModal(false);
    } else {
      setOpenTutorialCustomTooltip(false);
    }

    api.post('auth/viewedComponents', requestBody).finally(() => {
      arr.push({ component: requestBody.component });
      sessionStorage.setItem('userJobRole', JSON.stringify(userJobRole));
    });
  }

  const handleClearFilters = () => {
    setType('');
    setSituation2('');
    setDate1('');
    setDate2('');

    setInitialDate('');
    setFinalDate('');
    setPaymentType('');
    setSituation('Todos');
    setFilterPaymentType('Todos');
    setSearchTextNSU('');

    setCleanFilter(true);
    setLoadingList(true);
  };

  const payRollList = () => {
    setLoadingList(true);
    setLoadingFilter(true);

    const requestOptions = {
      headers: {
        Authorization: token,
        depositAccountId: accountSelected?.encodedKey,
      },
      params: {
        page: initialDate && finalDate ? 0 : currentPage,
        size: pageSize,
        nsu: searchTextNSU,
        type: filterPaymentType === FILTER_FULL_LIST ? '' : filterPaymentType,
        status: situation === FILTER_FULL_LIST ? '' : situation,
        initialDate: initialDate && finalDate ? initialDate : '',
        finalDate: initialDate && finalDate ? finalDate : '',
        sort: `${orderBy},${order}`,
      },
    };

    api
      .get(`/employeesPayments/search/${companyCodeValue}`, requestOptions)
      .then(({ data }) => {
        const dataResult = JSON.stringify(
          data.paymentsFiles,
          verifyValueOrNull(),
        );
        setPayments(JSON.parse(dataResult));
        setTotalSize(data.totalSize);
        setCurrentPageSize(data.currentPageSize);
        setOpenModalSuccess(false);
        setConfirButtonStyle(false);
      })
      .finally(() => {
        setLoadingFilter(false);
        setLoading(false);
        setLoadingList(false);
        setFetchData(false);
      });
  };

  useEffect(() => {
    if (!loadingFilter) {
      payRollList();
    }
  }, []);

  const onChangePaymentType = event => {
    setCurrentPage(0);
    setFilterPaymentType(event.target.value);
  };

  const onSearchNSUChangeFilters = event => {
    setCurrentPage(0);
    setSearchTextNSU(event.target.value);
  };

  const handleChangeSituation = event => {
    setCurrentPage(0);
    setSituation(event.target.value);
  };

  const onChangeInitialDate = value => {
    setInitialDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : value,
    );
  };

  const onChangeFinalDate = value => {
    setFinalDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : value,
    );
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRowMenuClick = event => {
    setRowAnchorEl(event.currentTarget);
  };

  const handleRowMenuClose = () => {
    setRowAnchorEl(null);
  };

  const handleRowAnchorElWaiting = event => {
    setRowAnchorElWaiting(event.currentTarget);
  };

  const handleRowAnchorElWaitingClose = () => {
    setRowAnchorElWaiting(null);
  };

  const handleRowAnchorElInsuficientFounds = event => {
    setRowAnchorElInsuficientFounds(event.currentTarget);
  };

  const handleRowAnchorElInsuficientFoundsClose = () => {
    setRowAnchorElInsuficientFounds(null);
  };

  const handleRowAnchorElCancelPayroll = event => {
    setAnchorElRowCancelPayroll(event.currentTarget);
  };

  const handleRowAnchorElCancelPayrollClose = () => {
    setAnchorElRowCancelPayroll(null);
  };

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  function onValidadeRescheduleDate(date, paymentType) {
    const dateValue = moment(date).format('YYYY-MM-DD');
    const todayDate = moment().format('YYYY-MM-DD');
    const invalidDate =
      (dateValue <= todayDate && paymentType !== 'Rescisão' && !zeroProduct) ||
      (dateValue < todayDate && paymentType === 'Rescisão' && !zeroProduct);

    if (invalidDate) {
      setMessageError(
        'A data não pode ser selecionada para o tipo de pagameto',
      );
    } else {
      setMessageError('');
    }
  }

  const onRescheduleClicked = (date, nsu, paymentType) => {
    onValidadeRescheduleDate(date, paymentType);
    setOpenRescheduleModal(true);
    setScheduleDate(date);
    setSelectedAvailablePaymentHour(moment(date).format('HH:00:00'));
    setNsu(nsu);
    setPaymentType(paymentType);
    handleRowAnchorElWaitingClose();
  };

  const onAlterDateAndAuthorizePayrollModalClicked = (_, nsu, paymentType) => {
    setScheduleDate(
      paymentType !== 'Rescisão' && !zeroProduct
        ? moment().add(1, 'day')
        : moment(),
    );
    setNsu(nsu);
    setPaymentType(paymentType);
    setOpenAlterDateAndAuthorizePayrollModal(true);
    handleRowAnchorElWaitingClose();
  };

  const onCancelClicked = nsu => {
    setOpenCancelModal(true);
    handleRowAnchorElCancelPayrollClose();
    handleRowAnchorElWaitingClose();
    setNsu(nsu);
  };

  const onDetailsClicked = payrollData => {
    dispatch(ActionsPayrollDetails.setPayrollBasicData(payrollData));
    sessionStorage.setItem('payrollData', JSON.stringify(payrollData));
    history.push(`${url}/details`);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onSubscribePusherChannel = payment => {
    const channel = pusher.subscribe(companyCodeValueStorage);
    channel.bind('AUTHORIZATION_PAYROLL', data => {
      setChannelData(data);

      setCurrentPaymentSchedule(
        payment
          ? `Pagamento agendado com sucesso para o dia ${moment(
              data?.data?.scheduledDate,
            ).format(
              'DD/MM/YYYY [às] HH:mm',
            )}. Caso deseje antecipar o pagamento, utilize a opção de liberação imediata.`
          : 'Pagamentos agendados com sucesso para os dias e horários definidos. Caso deseje antecipar os pagamentos, utilize a opção de liberação imediata.',
      );
    });
  };

  const submitAuthorizePayment = payments => {
    api
      .post(
        `/employeesPayments/batch/authorize/balance/${companyCodeValueStorage}`,
        {
          nsuList: payments,
        },
      )
      .then(({ data }) => {
        if (data?.insufficientBalance) {
          setAvaliableBalance(data);
          setInsufficientBalanceModal(true);
        } else if (pinOnConfirmPayroll) {
          preAuthorizePayroll(false, payments, () => authorizePayrollModalOn());
        } else authorizePayrollModalOn();

        setSendPinStatus(data?.status);
      })
      .finally(() => setLoadingModal(false));
  };

  const onPaymentClick = payment => {
    const newNsuList = [payment.nsu];

    setNsuList(newNsuList);
    setUniqueNsuSelected(newNsuList);
    setLoadingModal(true);
    onSubscribePusherChannel(payment);
    setErrorMessage('');
    setPinValue('');

    submitAuthorizePayment(newNsuList);
  };

  const onBatchPaymentClick = () => {
    const newNsuList = selected.map(item => item.nsu);

    setNsuList(newNsuList);
    setUniqueNsuSelected(newNsuList);

    setLoadingModal(true);
    onSubscribePusherChannel();
    setErrorMessage('');
    setPinValue('');

    submitAuthorizePayment(newNsuList);
  };

  function preAuthorizePayroll(isTryingSendCodeBySms, nsu, callback) {
    const requestBody = {
      nsuList: nsu,
      emailNotReceived: isTryingSendCodeBySms,
    };

    const requestConfig = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
      },
    };

    setCountDownHasStarted(true);

    setTimeout(() => {
      setCountDownHasStarted(false);
    }, COUNTDOWN_TIME * 1000);

    api
      .put('/employeesPayments/pre-authorize', requestBody, requestConfig)
      .then(({ data }) => {
        setRequestId(data.requestId);
        setUserEmail(data.email);
        setSendPinStatus(data.status);

        if (data?.phoneNumber && data?.status === 'SMS_SENT') {
          setSeverity('success');
          setShowSnackbar(true);
          setSnackbarMessage(
            `O SMS foi enviado para o número: ${formatNumber(
              data?.phoneNumber,
            )}`,
          );

          setTimeout(() => {
            setShowSnackbar(false);
          }, 5000);
        }

        if (callback) callback();
      })
      .catch(error => handleErrorMessagePreAuthorize(error.response?.data));
  }

  function handleErrorMessagePreAuthorize(error) {
    setSnackbarMessage(processErrorMessage(error));
    setSeverity('error');
    setShowSnackbar(true);
  }

  const confirmAuthorization = () => {
    const nsuList = selected.map(item => item.nsu);
    const nsuValue =
      selected?.length === 1 ? selected[0]?.nsu : uniqueNsuSelected?.[0];

    const requestOptions = pinOnConfirmPayroll
      ? {
          headers: {
            requestId,
            pin: pinValue,
            branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
            ...location,
          },
        }
      : null;

    const requestBody = {
      nsuList,
      expressPayment,
    };

    setLoadingButton(true);

    if (multiplePaymentsSelected) {
      api
        .put(
          `employeesPayments/batch/authorize/${companyCodeValueStorage}`,
          requestBody,
          requestOptions,
        )
        .then(() => {
          payRollList();
          sessionStorage.removeItem('selectedPayments');
          setSelected([]);
        })
        .catch(
          ({
            response: {
              data: { errors },
            },
          }) => {
            if (errors[0]?.errorReason === 'FILE_ALREADY_PROCESSING') {
              setErrorAuthorization(true);
            }

            setErrorMessage(errors[0]?.errorDetail);
          },
        )
        .finally(() => {
          setLoadingButton(false);
          setInsufficientBalanceModal(false);
        });
    } else {
      api
        .put(`employeesPayments/${nsuValue}/authorize`, '', requestOptions)
        .then(() => {
          payRollList();
          sessionStorage.removeItem('selectedPayments');
          setSelected([]);
        })
        .catch(
          ({
            response: {
              data: { errors },
            },
          }) => {
            if (errors[0]?.errorReason === 'FILE_ALREADY_PROCESSING') {
              setErrorAuthorization(true);
            }

            setErrorMessage(errors[0]?.errorDetail);
          },
        )
        .finally(() => {
          setLoadingButton(false);
          setInsufficientBalanceModal(false);
        });
    }
  };

  function verifyNecessityOfPinForAuthorizePayroll() {
    api.get(`/companies/${companyCodeValue}/search`).then(({ data }) => {
      setPinOnConfirmPayroll(data.confirmPayroll);
    });
  }

  function handleChangePinValue(event) {
    setPinValue(event.target.value);
  }

  const successPayment = () => {
    setOpenModalSuccess(true);
    setConfirButtonStyle(true);
  };

  const closeModalSuccess = () => {
    setErrorAuthorization(false);
    dispatch(ActionsBalance.getBalances());
    payRollList();
    setOpenCancelModal(false);
  };

  const closeRescheduleModal = () => {
    setOpenRescheduleModal(false);
    setMessageError('');
  };

  const closeOpenAlterDateAndAuthorizePayrollModal = () => {
    payRollList();
    setOpenAlterDateAndAuthorizePayrollModal(false);
  };

  const onChangeDate = value => {
    onValidadeRescheduleDate(value, paymentType);
    setScheduleDate(value);
  };

  const confirmCancel = () => {
    setCircularLoading(true);
    const requestOptions = {
      headers: {
        Authorization: token,
      },
    };
    api
      .put(`/employeesPayments/${nsu}/cancel`, '', requestOptions)
      .then(() => {
        setCircularLoading(false);
        setCancelButton(true);
        successPayment();
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setCircularLoading(false);
          setErrorAuthorization(false);
          setOpenCancelModal(false);
          setOpenModalError(true);
          setErrorMessageModal(errors);
        },
      );
  };

  const alterDatePayroll = () => {
    const date = `${moment(scheduleDate).format(
      'YYYY-MM-DD',
    )}T${selectedAvailablePaymentHour}`;

    setCircularLoading(true);

    const requestOptions = {
      headers: {
        newScheduleDate: date,
      },
    };
    api
      .put(`/employeesPayments/${nsu}/reschedule`, '', requestOptions)
      .then(() => {
        setCancelButton(true);
        successPayment();
        setMessageError('');
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          return setMessageError(errors[0].errorDetail);
        },
      )
      .finally(() => setCircularLoading(false));
  };

  const alterDateAndAuthorizePayroll = () => {
    setCircularLoading(true);

    const date =
      scheduleDate === today
        ? scheduleDate
        : moment(scheduleDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

    const requestOptions = {
      headers: {
        newScheduleDate: date,
        requestId,
        pin: pinValue,
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
        ...location,
      },
    };

    api
      .put(`employeesPayments/${nsu}/authorize`, '', requestOptions)
      .then(() => {
        setCancelButton(true);
        successPayment();
        setMessageError('');
        setPinValue('');
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          return setMessageError(errors[0].errorDetail);
        },
      )
      .finally(() => setCircularLoading(false));
  };

  const immediateReleasePayroll = (status, nsu) => {
    setInsufficientFunds(false);
    setLoadingRates(true);
    setOpenImmediateReleaseModal(true);
    setStatusPayroll(status);
    getRates(nsu);
  };

  const getRates = nsu => {
    setErrorMessage('');
    setPinValue('');

    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
        accountOrigin: accountSelected?.origin,
      },
    };

    api
      .get(
        `/employeesPayments/expressPayment/fee/${companyCodeValue}/${nsu}`,
        requestOptions,
      )
      .then(({ data }) => {
        const { errorMessage, missingBalance } = data;

        if (pinOnConfirmPayroll) {
          setNsuList([nsu]);
          preAuthorizePayroll(false, [nsu]);
        }

        if (errorMessage && missingBalance) {
          setInsufficientFunds(true);
        }

        setRateValues(data);
      })
      .finally(() => {
        setLoadingRates(false);
      });
  };

  const onConfirmImmediateRelease = () => {
    setLoadingButton(true);

    const payrollId = rateValues.nsu;

    const requestOptions = {
      headers: {
        requestId,
        pin: pinValue,
        accountOrigin: accountSelected?.origin,
      },
    };

    api
      .post(`employeesPayments/expressPayment/${payrollId}`, '', requestOptions)
      .then(() => {
        setOpenConfirmImmediateReleaseModal(false);
        setOpenImmediateReleaseSuccessModal(true);
        setOpenImmediateReleaseModal(false);
        setInsufficientFunds(false);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorMessage(errors[0]?.errorDetail);
        },
      )
      .finally(() => {
        setLoadingButton(false);
      });
  };

  function onCloseProcessedPayrollSuccessModal() {
    setAuthorizePayrollModal(false);
    dispatch(ActionsBalance.getBalances());
    payRollList();
  }

  const onCloseImmediateReleaseSuccessModal = () => {
    setOpenImmediateReleaseSuccessModal(false);
    dispatch(ActionsBalance.getBalances());
    payRollList();
  };

  const onCloseConfirmImmediateReleaseModal = () => {
    setOpenConfirmImmediateReleaseModal(false);
  };

  const formatFeeAmount = rateValues.feeAmount
    ? currencyFormatter(rateValues.feeAmount)
    : 'R$ 0,00';

  const formatPayrollValue = rateValues.totalAmount
    ? currencyFormatter(rateValues.totalAmount)
    : 'R$ 0,00';

  const totalPayrollAmount = rateValues.totalAmount + rateValues.feeAmount;

  const formatTotalPayrollAmount = totalPayrollAmount
    ? currencyFormatter(totalPayrollAmount)
    : 'R$ 0,00';

  const formatedMissingBalance = rateValues.missingBalance
    ? currencyFormatter(rateValues.missingBalance)
    : 'R$ 0,00';

  const formatedBalance = rateValues?.availableBalance
    ? currencyFormatter(rateValues?.availableBalance)
    : 'R$ 0,00';

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const isSelected = name => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected(payments.name);

  const registerFilePayroll = () => {
    if (
      uploadDataPayroll &&
      uploadDataPayroll?.status === PROCESSING &&
      uploadDataPayroll?.companyCode === companyCodeValueStorage
    ) {
      setAlreadyUploadModal(true);
    } else {
      history.push('/payroll/menu');
    }
  };

  const goToMultipleFileScreen = () => {
    history.push('/payroll/file-batch/upload');
  };

  const onCancelAlreadyModal = () => {
    setAlreadyUploadModal(false);
    handleClose();
  };

  const goToDeposits = () => {
    history.push('/cash-in');
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onSearchNSUChange = event => {
    setSearchTextNSU(event.target.value);
  };

  function getPaymentTypes() {
    api.get('/employeesPayments/payments/types').then(({ data }) => {
      setPayrollTypes(data);
    });
  }

  function paymentTypeToWord(status) {
    const paymentType = payrollTypes?.filter(item => {
      return item.code === status;
    });

    return paymentType[0]?.description;
  }

  function paymentStatusToWord(status) {
    const statusPayment = {
      1: 'Aguardando Autorização',
      2: 'Autorizada',
      3: 'Saldo Insuficiente',
      4: 'Paga',
      5: 'Cancelada',
      6: 'Autorizada Imediata',
      7: 'Paga Imediata',
      8: 'Expirada',
      9: 'Pagamento Incompleto',
      10: 'Pagamento não Realizado',
    };

    return statusPayment[status] ?? statusPayment[''];
  }

  function regeneratePayment(row) {
    history.push({
      pathname: '/payroll/manual-payroll/payroll-regenerate',
      state: row,
    });
  }

  const AUTHORIZE_WITH_INSUFFICIENT_BALANCE =
    (avaliableBalance?.insufficientBalance && !zeroProduct) ||
    (avaliableBalance?.insufficientBalance && multiplePaymentsSelected) ||
    avaliableBalance?.insufficientBalance;

  const EMAIL_MASKED = userEmail?.replace(
    /^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c,
  );

  function renderMobileList() {
    return (
      <>
        <MobileList
          data={payments}
          loadingList={loadingList}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          getData={payRollList}
          totalSize={totalSize}
          payments={payments}
          isMobile={isMobile}
          currentPageSize={currentPageSize}
          setFetchData={setFetchData}
          fetchData={fetchData}
          state={state}
        />
      </>
    );
  }

  function renderNSUFilter() {
    return (
      <>
        <TextField
          id="outlined-number"
          type="number"
          onFocus={() => setSearchFocus(true)}
          onBlur={() => setSearchFocus(false)}
          className={searchFocus ? style.nsuFocus : style.nsu}
          onChange={onSearchNSUChange}
          value={searchTextNSU}
          placeholder="NSU"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className={style.searchIcon} />
              </InputAdornment>
            ),
            inputMode: 'numeric',
          }}
        />
      </>
    );
  }

  function renderFilteredChips() {
    return (
      <>
        {isMobile ? (
          <Grid
            container
            direction="row"
            wrap="wrap"
            spacing={1}
            className={style.chips}
          >
            <Grid item>
              {filterPaymentType && isFilteredType ? (
                <Chip
                  className={style.chipItem}
                  label={paymentTypeToWord(filterPaymentType)}
                  onDelete={() => {
                    setIsFilteredType(false);
                    setFilterPaymentType('');
                  }}
                />
              ) : (
                ''
              )}
            </Grid>

            <Grid item>
              {situation && isFilteredSituation ? (
                <Chip
                  className={style.chipItem}
                  label={paymentStatusToWord(situation)}
                  onDelete={() => {
                    setIsFilteredSituation(false);
                    setSituation('');
                  }}
                />
              ) : (
                ''
              )}
            </Grid>

            <Grid item>
              {finalDate && isFilteredFinalDate && (
                <Chip
                  className={style.chipItem}
                  label={`${moment(initialDate).format(
                    'DD/MM/YYYY',
                  )} a ${moment(finalDate).format('DD/MM/YYYY')}`}
                  onDelete={() => {
                    setIsFilteredFinalDate(false);
                    setFinalDate('');
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </>
    );
  }

  const renderFilters = () => {
    return (
      <Filters
        filterPaymentType={filterPaymentType}
        searchTextNSU={searchTextNSU}
        situation={situation}
        initialDate={initialDate}
        finalDate={finalDate}
        handleClearFilters={() => handleClearFilters()}
        onChangePaymentType={onChangePaymentType}
        onSearchNSUChangeFilters={onSearchNSUChangeFilters}
        handleChangeSituation={handleChangeSituation}
        onChangeInitialDate={onChangeInitialDate}
        onChangeFinalDate={onChangeFinalDate}
        validateInitialDateInfo={validateInitialDateInfo}
        validateFinalDateInfo={validateFinalDateInfo}
        changedFilter={changedFilter}
        payrollTypes={payrollTypes}
      />
    );
  };

  const renderTableOrList = () => {
    return (
      <>
        {payments.length === 0 && changedFilter ? (
          <>
            {loadingFilter && !isMobile ? (
              <>
                {renderFilters()}
                <Table
                  openTutorialModal={openTutorialModal}
                  openTutorialCustomTooltip={openTutorialCustomTooltip}
                  onConfirmTutorial={onConfirmTutorial}
                  loadingFilter={loadingFilter}
                  theme={theme}
                  classesHead={classes1}
                  selected={selected}
                  setSelected={setSelected}
                  classes={classes}
                  order={order}
                  loading={loading}
                  orderBy={orderBy}
                  rows={payments}
                  rowAnchorEl={rowAnchorEl}
                  isItemSelected={isItemSelected}
                  handleRequestSort={handleRequestSort}
                  pageSize={pageSize}
                  totalSize={totalSize}
                  currentPage={currentPage}
                  employeesPayments={payments}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  TablePaginationActions={TablePaginationActions}
                  stableSort={stableSort}
                  handleRowMenuClick={handleRowMenuClick}
                  rowAnchorElWaiting={rowAnchorElWaiting}
                  handleRowAnchorElWaiting={handleRowAnchorElWaiting}
                  handleRowAnchorElWaitingClose={handleRowAnchorElWaitingClose}
                  rowAnchorElInsuficientFounds={rowAnchorElInsuficientFounds}
                  handleRowAnchorElInsuficientFounds={
                    handleRowAnchorElInsuficientFounds
                  }
                  handleRowAnchorElInsuficientFoundsClose={
                    handleRowAnchorElInsuficientFoundsClose
                  }
                  rowAnchorElCancelPayroll={rowAnchorElCancelPayroll}
                  handleRowAnchorElCancelPayroll={
                    handleRowAnchorElCancelPayroll
                  }
                  handleRowAnchorElCancelPayrollClose={
                    handleRowAnchorElCancelPayrollClose
                  }
                  rowHandleClose={handleRowMenuClose}
                  onDetailsClicked={onDetailsClicked}
                  getComparator={getComparator(order, orderBy)}
                  onBatchPaymentClick={onPaymentClick}
                  onCancelClicked={onCancelClicked}
                  onRescheduleClicked={onRescheduleClicked}
                  onAlterDateAndAuthorizePayrollModalClicked={
                    onAlterDateAndAuthorizePayrollModalClicked
                  }
                  immediateReleasePayroll={immediateReleasePayroll}
                  setErrorAuthorization={setErrorAuthorization}
                />
                <BottomBar
                  hasAuthorization={
                    !hasAuthorization(ACCESS_PROFILES, [profiles.AUTHORIZER])
                  }
                  selected={selected}
                  onBatchPaymentClick={onBatchPaymentClick}
                  multiplePaymentsSelected={multiplePaymentsSelected}
                />
              </>
            ) : (
              <>
                {renderFilters()}

                <ResultNotFound
                  text="Nenhum Resultado Encontrado"
                  alternativeText="Verifique os dados pesquisados e tente novamente."
                  buttonText="Limpar Filtros"
                  onClick={handleClearFilters}
                />
              </>
            )}
          </>
        ) : payments.length === 0 ? (
          renderPayRollView()
        ) : (
          <>
            {renderFilters()}
            <Table
              openTutorialModal={openTutorialModal}
              openTutorialCustomTooltip={openTutorialCustomTooltip}
              onConfirmTutorial={onConfirmTutorial}
              loadingFilter={loadingFilter}
              theme={theme}
              classesHead={classes1}
              selected={selected}
              setSelected={setSelected}
              classes={classes}
              order={order}
              loading={loading}
              orderBy={orderBy}
              rows={payments}
              rowAnchorEl={rowAnchorEl}
              isItemSelected={isItemSelected}
              handleRequestSort={handleRequestSort}
              pageSize={pageSize}
              totalSize={totalSize}
              currentPage={currentPage}
              employeesPayments={payments}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              TablePaginationActions={TablePaginationActions}
              stableSort={stableSort}
              handleRowMenuClick={handleRowMenuClick}
              rowAnchorElWaiting={rowAnchorElWaiting}
              handleRowAnchorElWaiting={handleRowAnchorElWaiting}
              handleRowAnchorElWaitingClose={handleRowAnchorElWaitingClose}
              rowAnchorElInsuficientFounds={rowAnchorElInsuficientFounds}
              handleRowAnchorElInsuficientFounds={
                handleRowAnchorElInsuficientFounds
              }
              handleRowAnchorElInsuficientFoundsClose={
                handleRowAnchorElInsuficientFoundsClose
              }
              rowAnchorElCancelPayroll={rowAnchorElCancelPayroll}
              handleRowAnchorElCancelPayroll={handleRowAnchorElCancelPayroll}
              handleRowAnchorElCancelPayrollClose={
                handleRowAnchorElCancelPayrollClose
              }
              rowHandleClose={handleRowMenuClose}
              onDetailsClicked={onDetailsClicked}
              getComparator={getComparator(order, orderBy)}
              onBatchPaymentClick={onPaymentClick}
              onCancelClicked={onCancelClicked}
              onRescheduleClicked={onRescheduleClicked}
              onAlterDateAndAuthorizePayrollModalClicked={
                onAlterDateAndAuthorizePayrollModalClicked
              }
              immediateReleasePayroll={immediateReleasePayroll}
              setErrorAuthorization={setErrorAuthorization}
              regeneratePayment={regeneratePayment}
            />
            <BottomBar
              hasAuthorization={
                !hasAuthorization(ACCESS_PROFILES, [profiles.AUTHORIZER])
              }
              selected={selected}
              onBatchPaymentClick={onBatchPaymentClick}
              multiplePaymentsSelected={multiplePaymentsSelected}
            />
          </>
        )}
      </>
    );
  };

  const renderPayRollView = () => {
    const { buttonBodyTextStyleClasses } = classes;
    return (
      <div>
        <div style={isMobile ? styles.mobileContentStyle : styles.contentStyle}>
          <ResultNotFound
            text="Gerencie aqui as folhas de pagamento."
            alternativeText="Todas as informações de folhas de pagamento aparecerão nesta página."
            buttonText="Novo pagamento"
            onClick={handleClick}
            icon={<DollarSign />}
          />
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        className={style.headerPayments}
      >
        <Grid item container direction="column">
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography className={style.titleStyle}>Pagamentos</Typography>
            </Grid>

            {isMobile &&
              ['right'].map(anchor => (
                <Grid item key={anchor}>
                  <Button
                    startIcon={<FilterIcon />}
                    className={style.filterButton}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <p className={style.filterLabel}>Filtrar</p>
                  </Button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    <ContentFilterDrawer
                      setLoading={setLoading}
                      filterPaymentType={filterPaymentType}
                      setFilterPaymentType={setFilterPaymentType}
                      situation={situation}
                      setSituation={setSituation}
                      initialDate={initialDate}
                      setInitialDate={setInitialDate}
                      finalDate={finalDate}
                      setFinalDate={setFinalDate}
                      setCurrentPage={setCurrentPage}
                      handleClearFilters={handleClearFilters}
                      setIsFilteredType={setIsFilteredType}
                      setIsFilteredSituation={setIsFilteredSituation}
                      setIsFilteredFinalDate={setIsFilteredFinalDate}
                      payRollList={payRollList}
                      setState={setState}
                      type={type}
                      setType={setType}
                      situation2={situation2}
                      setSituation2={setSituation2}
                      date1={date1}
                      date2={date2}
                      setDate1={setDate1}
                      setDate2={setDate2}
                      payrollTypes={payrollTypes}
                    />
                  </Drawer>
                </Grid>
              ))}
          </Grid>

          <Grid item>{isMobile ? renderNSUFilter() : ''}</Grid>
        </Grid>

        <Hidden only="xs">
          <div style={styles.buttonPadding}>
            <Button
              ref={anchorRef}
              variant="contained"
              color="primary"
              style={styles.buttonStyle}
              startIcon={<AddIcon />}
              onClick={handleClick}
              aria-controls="simple-menu"
              aria-haspopup="true"
              disabled={accountSelected?.status === 'CLOSED'}
            >
              <Typography style={styles.buttonTextStyle} display="inline">
                Novo pagamento
              </Typography>
            </Button>
          </div>

          <div style={styles.menuList}>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.menuList}
            >
              <MenuItem onClick={() => history.push('/payroll/manual-payroll')}>
                <span className={classes.menuListItem}>
                  Cadastrar manualmente
                </span>
              </MenuItem>

              <MenuItem onClick={registerFilePayroll}>
                <span className={classes.menuListItem}>
                  Cadastrar a partir de um arquivo
                </span>
              </MenuItem>

              <MenuItem
                onClick={goToMultipleFileScreen}
                disabled={
                  !hasAuthorization(ACCESS_PROFILES, [
                    profiles.AUTHORIZER,
                    profiles.ADMIN,
                    profiles.PERSONAL_DEPARTMENT
                  ])
                }
              >
                <span className={classes.menuListItem}>
                  Cadastrar a partir de vários arquivos
                </span>
              </MenuItem>
            </Menu>
          </div>
        </Hidden>
      </Grid>
    );
  };

  const renderExpressPaymentSelection = () => {
    return (
      <Form.Checkbox
        name="expressPayment"
        checked={expressPayment}
        onChange={() => setExpressPayment(!expressPayment)}
        label="Autorizar pagamento com liberação imediata"
      />
    );
  };

  if (loading) {
    return <LogoLoading />;
  }

  return (
    <div className={style.paymentsContainer}>
      {payments?.length === 0 && changedFilter ? (
        <>
          {renderHeader()}
          {renderFilteredChips()}
          {loadingList && !isMobile ? (
            renderTableOrList()
          ) : loadingList ? (
            renderMobileList()
          ) : (
            <>
              <Hidden smDown>{renderFilters()}</Hidden>
              <ResultNotFound
                text="Nenhum Resultado Encontrado"
                alternativeText="Verifique os dados pesquisados e tente novamente."
                buttonText="Limpar Filtros"
                onClick={handleClearFilters}
              />
            </>
          )}
        </>
      ) : (
        <>
          {renderHeader()}
          {renderFilteredChips()}
          {isMobile ? renderMobileList() : renderTableOrList()}
          {/* {openTutorialCustomTooltip && !openTutorialModal && payments.length > 0 && (
            <CustomTooltip onClickButton={() => onConfirmTutorial('BATCH_AUTHORIZATION_CUSTOMTOOLTIP')} />
          )} */}
        </>
      )}

      <AuthorizePayrollModal
        pinOnConfirmPayroll={pinOnConfirmPayroll}
        codeVerification={channelData?.status !== PROCESSED_PAYROLL_SUCCESS}
        codeValue={pinValue}
        handleChangeCodeValue={handleChangePinValue}
        errorMessage={errorMessage}
        emailLabel={EMAIL_MASKED}
        open={authorizePayrollModal}
        title={
          channelData?.status === PROCESSED_PAYROLL_ERROR
            ? 'Problema na autorização'
            : channelData?.status === PROCESSING
            ? 'Autorização em processamento...'
            : channelData?.status === PROCESSED_PAYROLL_SUCCESS
            ? multiplePaymentsSelected
              ? 'Pagamentos Autorizados!'
              : 'Pagamento Autorizado!'
            : errorAuthorization
            ? 'Um outro usuário já realizou está ação.'
            : multiplePaymentsSelected
            ? 'Autorizar estes pagamentos?'
            : 'Autorizar este pagamento?'
        }
        text={
          channelData?.status === PROCESSING
            ? 'O processamento pode levar alguns minutos. Após a conclusão, você pode voltar a usar a plataforma normalmente.'
            : channelData?.status === PROCESSED_PAYROLL_SUCCESS
            ? currentPaymentSchedule
            : channelData?.status === PROCESSED_PAYROLL_ERROR
            ? channelData.statusDescription
            : errorAuthorization
            ? 'A autorização para o pagamento desta folha já foi dada e será executada na data agendada.'
            : multiplePaymentsSelected
            ? 'Ao autorizar, o pagamento dessas folhas será executado na data agendada.'
            : 'Ao autorizar, o pagamento será executado na data agendada.'
        }
        confirmButtonText={
          channelData?.status === PROCESSING
            ? `${channelData?.percentage || '0'} %`
            : channelData?.status === PROCESSED_PAYROLL_SUCCESS
            ? 'Fechar'
            : channelData?.status === PROCESSED_PAYROLL_ERROR
            ? 'Continuar'
            : errorAuthorization
            ? 'Entendi'
            : multiplePaymentsSelected
            ? 'Autorizar Pagamentos'
            : 'Autorizar Pagamento'
        }
        onConfirmClicked={
          channelData?.status === PROCESSED_PAYROLL_SUCCESS
            ? () => {
                onCloseProcessedPayrollSuccessModal();
              }
            : errorAuthorization ||
              channelData?.status === PROCESSED_PAYROLL_ERROR
            ? () => setAuthorizePayrollModal(false)
            : confirmAuthorization
        }
        cancelButtonText={
          !(
            channelData?.status === PROCESSED_PAYROLL_SUCCESS ||
            errorAuthorization ||
            channelData?.status === PROCESSED_PAYROLL_ERROR
          ) && 'Cancelar'
        }
        onCancelButtonClicked={() => setAuthorizePayrollModal(false)}
        progressLoading={channelData?.status === PROCESSING}
        progress={channelData?.percentage}
        sendPinStatus={sendPinStatus}
        countdownTime={currentTime}
        countdownHasStarted={countdownHasStarted}
        handleTrySendCodeBySms={() => preAuthorizePayroll(true, nsuList)}
        expressPayment={renderExpressPaymentSelection()}
      />

      <CancelPayrollDialog
        cancelButton={cancelButton}
        circularLoading={circularLoading}
        confirButtonStyle={confirButtonStyle}
        open={openCancelModal}
        onCloseDialog={() => closeModalSuccess()}
        title={
          openModalSuccess === false
            ? 'Cancelar Pagamento?'
            : 'Pagamento Cancelado!'
        }
        text={
          openModalSuccess === false
            ? 'Ao fazer isso, o agendamento deste pagamento será cancelado.'
            : 'Pronto, seu pagamento está cancelado!'
        }
        confirmText={openModalSuccess === false ? 'Desfazer' : 'Fechar'}
        style={{ backgrounColor: '#000' }}
        onConfirmClicked={
          openModalSuccess === false
            ? () => {
                confirmCancel();
              }
            : () => closeModalSuccess()
        }
        cancelText="Cancelar"
      />

      <RescheduleDialog
        zeroProduct={zeroProduct}
        paymentType={paymentType}
        cancelButton={cancelButton}
        circularLoading={circularLoading}
        open={openRescheduleModal}
        onCloseDialog={() => closeRescheduleModal()}
        title={
          openModalSuccess === false
            ? 'Escolha a data e hora de agendamento'
            : 'Data e hora de pagamento alterada!'
        }
        text={
          openModalSuccess === false
            ? 'Ao prosseguir, a data e hora será alterada de acordo com a sua escolha.'
            : 'Em alguns minutos a data e hora de pagamento será alterada!'
        }
        errorMessage={messageError || ''}
        confirmText={openModalSuccess === false ? 'Alterar data' : 'Fechar'}
        onChangeDate={onChangeDate}
        date={scheduleDate}
        selectedAvailablePaymentHour={selectedAvailablePaymentHour}
        setSelectedAvailablePaymentHour={setSelectedAvailablePaymentHour}
        availablePaymentHours={availablePaymentHours}
        style={{ backgrounColor: '#000' }}
        onConfirmClicked={
          openModalSuccess === false
            ? () => {
                alterDatePayroll();
              }
            : () => {
                payRollList();
                closeRescheduleModal();
              }
        }
        cancelText={!openModalSuccess ? 'Cancelar' : null}
        openModalSuccess={openModalSuccess}
      />

      <AlterDateAndAuthorizePayrollModal
        codeVerification={pinOnConfirmPayroll}
        codeValue={pinValue}
        handleChangeCodeValue={handleChangePinValue}
        errorMessage={errorMessage}
        emailLabel={EMAIL_MASKED}
        paymentType={paymentType}
        cancelButton={cancelButton}
        circularLoading={circularLoading}
        open={openAlterDateAndAuthorizePayrollModal}
        onCloseDialog={() => {
          setOpenAlterDateAndAuthorizePayrollModal(false);
          setMessageError('');
        }}
        openModalSuccess={openModalSuccess}
        title={
          !openModalSuccess
            ? 'Escolha uma nova data de agendamento para continuar.'
            : 'Obrigado! Pagamento aprovado!'
        }
        text={
          !openModalSuccess
            ? 'Este pagamento está agendado para uma data anterior. Por favor, escolha uma nova data para reagendar e autorizar este pagamento.'
            : 'A transição será realizada no prazo de acordo com o tipo de pagamento.'
        }
        confirmText={
          openModalSuccess === false ? 'Reagendar e autorizar' : 'Fechar'
        }
        onChangeDate={onChangeDate}
        date={scheduleDate}
        style={{ backgrounColor: '#000' }}
        onConfirmClicked={
          openModalSuccess === false
            ? alterDateAndAuthorizePayroll
            : closeOpenAlterDateAndAuthorizePayrollModal
        }
        cancelText="Cancelar"
        today={today}
        sendPinStatus={sendPinStatus}
      />

      <BalancesModal
        pinOnConfirmPayroll={pinOnConfirmPayroll}
        codeVerification={!insufficientFunds}
        codeValue={pinValue}
        handleChangeCodeValue={handleChangePinValue}
        errorMessage={errorMessage}
        resetError={resetError}
        emailLabel={EMAIL_MASKED}
        open={openImmediateReleaseModal}
        onCloseDialog={() => setOpenImmediateReleaseModal(false)}
        title={
          insufficientFunds
            ? 'Saldo insuficiente'
            : 'Liberar este pagamento imediatamente?'
        }
        cancelButtonText="Cancelar"
        onCancelButtonClicked={() => setOpenImmediateReleaseModal(false)}
        confirmButtonText={
          insufficientFunds ? 'Fazer depósito' : 'Liberar pagamento'
        }
        onConfirmClicked={
          insufficientFunds ? goToDeposits : onConfirmImmediateRelease
        }
        loading={loadingRates}
        loadingButton={loadingButton}
        firstLabel={insufficientFunds ? 'Seu saldo' : 'Folha'}
        firstValue={insufficientFunds ? formatedBalance : formatPayrollValue}
        secondLabel="Tarifa"
        secondValue={formatFeeAmount}
        thirdLabel={insufficientFunds ? 'Diferença' : 'Total da Folha'}
        thirdValue={
          insufficientFunds ? formatedMissingBalance : formatTotalPayrollAmount
        }
        alertText={
          insufficientFunds
            ? 'Deposite o valor pendente para conseguir realizar a liberação.'
            : 'A tarifa será cobrada após a execução do pagamento.'
        }
        icon={insufficientFunds ? <MinusIcon /> : <PlusIcon />}
        disabledConfirmButton={
          loading ||
          loadingButton ||
          (insufficientFunds &&
            (!hasAuthorization(ACCESS_PROFILES, [
              profiles.ADMIN,
              profiles.FINANCIAL,
            ]) ||
              isSubsidiary))
        }
        sendPinStatus={sendPinStatus}
        isImmediateLiberation
      />

      <ConfirmImmediateReleaseModal
        open={openConfirmImmediateReleaseModal}
        onCloseDialog={onCloseConfirmImmediateReleaseModal}
        title="Confirmar liberação imediata?"
        titleDescription="Ao confirmar, o pagamento da folha não poderá ser cancelado"
        onConfirm={onConfirmImmediateRelease}
        loadingButton={loadingButton}
        ErrorMessage={errorMessage}
      />

      <SuccessModal
        open={openImmediateReleaseSuccessModal}
        onCloseDialog={onCloseImmediateReleaseSuccessModal}
        title={
          statusPayroll === 'Aguardando autorização'
            ? 'Pagamento autorizado e liberado com sucesso!'
            : 'Pagamento liberado com sucesso!'
        }
        closeButtonText="Fechar"
        text="Em até 1 hora todos os pagamentos serão realizados."
      />

      <AlreadyUploadModal
        open={alreadyUploadModal}
        onConfirmClicked={() =>
          history.push('/payroll/menu/info-payroll/register-batch')
        }
        onCancelButtonClicked={onCancelAlreadyModal}
        confirmButtonText="Sim"
        cancelButtonText="Não"
        title="Já existe um arquivo em processamento. Deseja acompanhar seu status?"
        text="Você só poderá iniciar um novo cadastro, quando o que está em andamento for concluído."
      />

      <BalancesModal
        codeVerification={!AUTHORIZE_WITH_INSUFFICIENT_BALANCE}
        codeValue={pinValue}
        handleChangeCodeValue={handleChangePinValue}
        errorMessage={errorMessage}
        emailLabel={EMAIL_MASKED}
        title={
          AUTHORIZE_WITH_INSUFFICIENT_BALANCE
            ? 'Saldo insuficiente'
            : 'Autorizar pagamento?'
        }
        text={
          avaliableBalance?.insufficientBalance && multiplePaymentsSelected
            ? 'Você não possui saldo suficiente para autorizar o pagamento dessas folhas.'
            : 'Você não possui saldo suficiente para autorizar o pagamento.'
        }
        open={insufficientBalanceModal}
        confirmButtonText={
          AUTHORIZE_WITH_INSUFFICIENT_BALANCE
            ? 'Fazer depósito'
            : 'Autorizar Pagamento'
        }
        cancelButtonText="Cancelar"
        onCancelButtonClicked={() => setInsufficientBalanceModal(false)}
        firstLabel="Seu Saldo"
        firstValue={currencyFormatter(avaliableBalance.balance)}
        secondLabel={
          multiplePaymentsSelected ? 'Valor das folhas' : 'Valor da Folha'
        }
        secondValue={currencyFormatter(avaliableBalance.fileAmount)}
        thirdLabel="Diferença"
        thirdValue={currencyFormatter(avaliableBalance.difference)?.replace(
          '-',
          '',
        )}
        feeAmount={currencyFormatter(avaliableBalance?.feeAmount) || 'R$ 0,00'}
        signatureCharge={
          currencyFormatter(avaliableBalance?.signatureCharge) || 'R$ 0,00'
        }
        alertText={
          avaliableBalance?.insufficientBalance && multiplePaymentsSelected
            ? 'Deposite o valor pendente para conseguir realizar o pagamento das folhas.'
            : 'Deposite o valor pendente para conseguir realizar o pagamento.'
        }
        onConfirmClicked={
          avaliableBalance?.insufficientBalance ||
          (avaliableBalance?.insufficientBalance && multiplePaymentsSelected)
            ? () => history.push('/cash-in')
            : confirmAuthorization
        }
        icon={<MinusIcon />}
        loadingButton={loadingButton}
        disabledConfirmButton={
          loading ||
          loadingButton ||
          (insufficientFunds &&
            (!hasAuthorization(ACCESS_PROFILES, [
              profiles.ADMIN,
              profiles.FINANCIAL,
            ]) ||
              isSubsidiary))
        }
      />

      <ErrorModal
        title="Não foi possivel desfazer o agendamento"
        text={
          errorMessageModal !== null &&
          errorMessageModal[0].errorsDescription !== null &&
          errorMessageModal[0].errorReason ===
            'HAS_FILES_TO_CANCEL_BEFORE_THAT_FILE' ? (
            <span>
              Para seguir com o cancelamento é necessário que as folhas com os{' '}
              <span className={style.boldSpan}>NSU&apos;'s</span> abaixo sejam
              canceladas:
              <br />
              <br />
              {errorMessageModal[0]?.errorsDescription?.nsus.map(item => (
                <Typography className={style.errorListLabel}>
                  • {item}
                </Typography>
              ))}
            </span>
          ) : errorMessageModal !== null &&
            errorMessageModal[0].errorReason === 'FILE_IS_BLOCKED_TO_CANCEL' ? (
            <span>
              Essa folha influência no cálculo da tarifa de outra(s) folha(s)
              com status <span className={style.boldSpan}>PAGO</span>, entre em
              contato com o nosso atendimento.
            </span>
          ) : (
            <span>Tente novamente!</span>
          )
        }
        onConfirmClicked={() => setOpenModalError(false)}
        open={openModalError}
        confirmButtonText="Entendi"
      />

      <LoadingModal
        open={loadingModal}
        title="Aguarde alguns instantes..."
        image={<Lottie options={animationOptions} height={120} width={120} />}
      />

      <Hidden smUp>
        <FloatButton actions={actions} />
      </Hidden>

      <CustomSnackbar
        open={showSnackbar}
        severity={severity}
        close={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
    </div>
  );
};

export default ConsultPayroll;
