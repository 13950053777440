import React from 'react';

import {
    IconButton, Menu, MenuItem, Paper,
    Table,
    TableBody, TableCell,
    TableContainer, TableFooter,
    TableHead, TablePagination,
    TableRow, Tooltip
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';

import FakeRowsTable from './FakeRowsTable';

import convertBrazilianDate from '../../../../utils/convertBrazilianDate';
import brazilianCurrencyFormatter from '../../../../utils/brazilianCurrencyFormatter';

import styles from './styles.module.css';

import { element } from 'prop-types';

export default({
    data,
	loading,
	totalSize,
	pageSize,
	currentPage,
	handlePageChange,
	handleRowsPerPageChange,
	TablePaginationActions,
    handleDetailCharge,
	anchorEl,
	handleClickOption,
	handleClickMenu,
	handleClose,
	charge
}) => {

	const disableNfeDownload = element => !element?.invoicePdfUrl;
	const disableBankTicketDownload = element => !element?.bankTicketUrl;

    return (
        <TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className={styles.tableHeader}>Situação</TableCell>
						<TableCell className={styles.tableHeader}>Criada em</TableCell>
						<TableCell className={styles.tableHeader}>Competência</TableCell>
						<TableCell className={styles.tableHeader}>Quant. funcionários</TableCell>
						<TableCell className={styles.tableHeader}>Vencimento</TableCell>
						<TableCell className={styles.tableHeader}>Valor</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>

				<TableBody>
					{loading ? (
						FakeRowsTable()
					) : data?.map(element => (

						<TableRow hover key={element.id}>
							<TableCell component="th" scope="row" align="left">
								{element.status}
							</TableCell>
							<TableCell align="left">
								{convertBrazilianDate(element.createdAt)}
							</TableCell>
							<TableCell align="left">
								{element.referenceMonth}
							</TableCell>
							<TableCell align="left">
								{element.employeeQuantity}
							</TableCell>
							<TableCell align="left">
								{convertBrazilianDate(element.dueDate)}
							</TableCell>
							<TableCell align="left">
								{element.amount === 0 ? "R$ 0,00" : brazilianCurrencyFormatter(element.amount)}
							</TableCell>
							<TableCell align="right">
                                <Tooltip
									title="Visualizar detalhes"
									placement="top"
								>
									<IconButton
										className={styles.iconButton}
										onClick={_ => handleDetailCharge(element.id)}
									>
										<VisibilityRoundedIcon />
									</IconButton>
								</Tooltip>

								<Tooltip title="Mais opções">
									<IconButton
										onClick={e => handleClickMenu(e, element)}
									>
										<MoreVertIcon className={styles.fileIcon} />
									</IconButton>
								</Tooltip>
							</TableCell>							
						</TableRow>
					))}

					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={anchorEl}
						onClose={handleClose}
						MenuListProps={{
						'aria-labelledby': 'basic-button',
						}}
					>
						<MenuItem
							disabled={disableBankTicketDownload(charge)}
							onClick={() => handleClickOption(1)}
						>
							Baixar boleto
						</MenuItem>

						<MenuItem
							disabled={disableNfeDownload(charge)}
							onClick={() => handleClickOption(2)}
						>
							Baixar NFe
						</MenuItem>
					</Menu>
				</TableBody>

				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							count={totalSize}
							rowsPerPage={pageSize}
							page={currentPage}
							labelRowsPerPage="linhas por página"
							labelDisplayedRows={
								function defaultLabelDisplayedRows({ from, to, count }) {
									return `${from}–${to} de ${count !== -1 ? count : `mais que ${to}`}`;
								}}
							onChangePage={handlePageChange}
							onRowsPerPageChange={handleRowsPerPageChange}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
    );
}