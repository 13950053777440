import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import { hasAuthorization, profiles } from '../../utils/hasAuthorization';
import { isSubsidiaryCompanie } from '../../utils/isSubsidiaryCompanie';

const useStyles = makeStyles(theme => ({
  root: {
    whiteSpace: 'nowrap',
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  staticTooltipLabel: {
    display: 'inline',
    fontSize: 12,
    color: '#52197F',
    textAlign: 'right',
  },
  backdrop: {
    zIndex: 1000,
    color: '#FAFAFA',
    minHeight: '100vh',
    height: '100%',
  },
}));

export default function FloatButton({ actions, hidden, customName }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const currentCompanyIndex = sessionStorage.getItem('currentCompanyIndex');
  const { payrollProduct } = companies[currentCompanyIndex || 0];
  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));

  const isSubsidiary = isSubsidiaryCompanie();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function menuValidate(value) {
    if (
      (value === 'FILE_REGISTRATION' || value === 'INDIVIDUAL_REGISTRATION') &&
      hasAuthorization(ACCESS_PROFILES, [
        profiles.ADMIN,
        profiles.PERSONAL_DEPARTMENT,
      ]) &&
      payrollProduct
    ) {
      return true;
    }

    if (
      (value === 'TRANSFER' ||
        value === 'BILLET_DEPOSIT' ||
        value === 'PIX_DEPOSIT') &&
      hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN, profiles.FINANCIAL]) &&
      !isSubsidiary
    ) {
      return true;
    }

    if (
      (value === 'FILE_PAYMENT' || value === 'MANUAL_PAYMENT') &&
      hasAuthorization(ACCESS_PROFILES, [
        profiles.ADMIN,
        profiles.PERSONAL_DEPARTMENT,
        profiles.FINANCIAL,
      ]) &&
      payrollProduct
    ) {
      return true;
    }

    if (
      value === 'USER_REGISTRATION' &&
      hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN])
    ) {
      return true;
    }

    if (value === '') {
      return true;
    }

    return false;
  }

  function setStepTopBar(name) {
    const findStr = /arquivo/i;

    if (findStr.test(name)) return 0;

    return 1;
  }

  const onClick = (url, name) => {
    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: customName || name,
        step: setStepTopBar(name),
        route: history.location.pathname,
      }),
    );

    if (name === 'Registro de transferência') {
      history.push(url?.[0], url?.[1]);
    } else {
      history.push(url);
    }
  };

  return (
    <div className={classes.root} style={hidden ? { display: 'none' } : {}}>
      <Backdrop
        open={open}
        className={classes.backdrop}
        onClick={handleClose}
      />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions?.map(action => {
          const validate = menuValidate(action.value);

          return (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              classes={classes}
              style={validate ? {} : { display: 'none' }}
              onClick={() => onClick(action.onClick, action.name)}
            />
          );
        })}
      </SpeedDial>
    </div>
  );
}
