const styles = {
  title: {
    fontWeight: 'bold',
    marginBottom: '-8px',
    lineHeight: '24px',
    padding: 20,
    fontSize: 20
  },
  confirmButtonStyle: {
    color: '#FFFFFF',
    backgroundColor: '#E54217',
    padding: '10px auto',
    marginRight: '10px',
    marginBottom: '3px',
  },
  closeButtonStyle: {
    color: '#FFFFFF',
    backgroundColor: '#E54217',
    marginRight: '10px',
    marginBottom: '3px'
  },
  circularLoadingStyle: {
    color: '#FFFFFF',
    width: '20px',
    height: '20px',
    marginLeft: '25px',
    marginRight: '25px',
  },
  errorMessage: {
    color: 'red'
  },
  cancelText: {
    color: 'rgb(92, 36, 131)',
    marginRight: '6px'
  }
};

export default styles;
