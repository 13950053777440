import React from 'react';

import Title from '../../../../components/Title';
import { isMobile } from '../../../../utils/breakpoints';

import styles from './styles.module.css';

function Header() {
  return (
    <>
      {!isMobile && (
        <div className={styles.header}>
          <Title>Faturas</Title>
        </div>
      )}
    </>
  );
}

export default Header;
