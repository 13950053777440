import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MobileStepper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { ChipStatus } from '../../../ChipStatus';
import Buttons from '../../../Buttons';

export function StepModal({
  open = false,
  onClose = () => {},
  title = 'Title',
  chip = '',
  chipColor = 'primary',
  animateHeight = false,
  resetSteps = false,
  stepsQtt = 0,
  isLoading = false,
  isPrimaryButtonDisabled = false,
  onPrimaryButtonClick = () => {},
  sx = {},
  children = [],
  ...rest
}) {
  const themeQuery = useTheme();
  const fullScreen = useMediaQuery(themeQuery.breakpoints.down('md'));

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setCurrentStep(0);
      }, 2000);
    }
  }, [open]);

  return (
    <Dialog
      {...rest}
      sx={{
        ...sx,
        '& .MuiDialog-paper': {
          padding: '1.5rem',
          borderRadius: '8px',
        },
      }}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      elevation={2}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0rem 0rem 0.5rem 0rem',
          fontWeight: 700,
        }}
      >
        {title}
        {chip && (
          <ChipStatus
            label={chip}
            color={chipColor}
            size="small"
            variant="soft"
          />
        )}
      </DialogTitle>

      <Box direction="column" spacing={2}>
        <DialogContent sx={{ padding: '0rem' }}>
          <SwipeableViews
            animateHeight={animateHeight}
            autoPlay={false}
            index={currentStep}
            containerStyle={{
              height: 'fit-content',
            }}
          >
            {children}
          </SwipeableViews>
        </DialogContent>
      </Box>

      <DialogActions style={{ width: '100%' }}>
        <MobileStepper
          variant="dots"
          position="static"
          steps={stepsQtt}
          activeStep={currentStep}
          backButton={
            <Buttons.SecondaryButton
              color="primary"
              title="Voltar"
              disabled={currentStep === 0}
              onClick={() => setCurrentStep(prev => prev - 1)}
            />
          }
          nextButton={
            <Buttons.PrimaryButton
              title={currentStep === stepsQtt - 1 ? 'Concluir' : 'Próximo'}
              disabled={isPrimaryButtonDisabled}
              onClick={() =>
                currentStep === stepsQtt - 1
                  ? onPrimaryButtonClick()
                  : setCurrentStep(prev => prev + 1)
              }
            />
          }
          style={{ background: 'transparent', width: '100%' }}
        />
      </DialogActions>
    </Dialog>
  );
}
