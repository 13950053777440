/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Form from '../../../components/Form';

import { ResetPasswordActions } from '../../../redux/store/ResetPassword';

import { isMobile } from '../../../utils/breakpoints';
import { encrypt } from '../../../utils/security';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

import Indicator from './Indicator';

import validations, { validationMessages } from './validations';
import styles from './styles.module.css';

import { ReactComponent as LogoEmpresa } from '../../../assets/somapay_digital_bank.svg';
import { ReactComponent as ArrowBack } from '../../../assets/arrow_back.svg';

const ValidadePassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { error } = handleSnackbarStack();

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    trigger,
    getValues,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validations),
    criteriaMode: 'all',
    initialValues: {
      password: '',
      repeatPassword: '',
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { isLoading } = useSelector(state => state.resetPassword);

  const sendPassword = async ({ password }) => {
    const actionResult = await dispatch(
      ResetPasswordActions.setNewPassword({
        password: encrypt(password),
      }),
    );
    try {
      unwrapResult(actionResult);
      history.push('/reset-password/success-message');
    } catch (err) {
      error(err);
    }
  };

  const goBackStep = () => {
    history.push('/reset-password/validate-token');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const checkError = message => {
    if (!getValues('password')) {
      return true;
    }

    if (!errors?.password) {
      return false;
    }

    if (message === validationMessages.min && errors?.password?.types?.min) {
      return true;
    }

    if (Array.isArray(errors?.password?.types?.matches)) {
      return errors?.password?.types?.matches.includes(message);
    }

    if (typeof errors?.password?.types?.matches === 'string') {
      return errors?.password?.types?.matches === message;
    }
  };

  useEffect(() => {
    trigger();
  }, []);

  return (
    <div className={styles.pageContainer}>
      <MuiThemeProvider>
        <Container>
          <CssBaseline />
          <div className={styles.paper}>
            <IconButton className={styles.arrowBack} onClick={goBackStep}>
              <ArrowBack />
            </IconButton>
            <div>
              <LogoEmpresa width={240} height={65} />
            </div>
            <form className={styles.form} onSubmit={handleSubmit(sendPassword)}>
              <div className={styles.content}>
                <Typography className={styles.textBigger}>
                  Crie uma nova senha:
                </Typography>
              </div>
              <div className={styles.containerInputs}>
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <Form.TextField
                      size="medium"
                      variant="outlined"
                      className={styles.inputPassword}
                      fullWidth
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      label="Digite uma nova senha"
                      error={!!errors?.password}
                      {...field}
                      disabled={isLoading}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="repeatPassword"
                  render={({ field }) => (
                    <Form.TextField
                      size="medium"
                      variant="outlined"
                      className={styles.inputPassword}
                      fullWidth
                      id="outlined-adornment-password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      label={
                        isMobile
                          ? 'Digite novamente'
                          : 'Digite novamente a senha'
                      }
                      error={!!errors?.repeatPassword}
                      helperText={errors?.repeatPassword?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...field}
                      disabled={isLoading}
                    />
                  )}
                />

                <div className={styles.validationPoints}>
                  <Indicator error={checkError(validationMessages.min)}>
                    {validationMessages.min}
                  </Indicator>
                  <Indicator error={checkError(validationMessages.special)}>
                    {validationMessages.special}
                  </Indicator>
                  <Indicator error={checkError(validationMessages.upperCase)}>
                    {validationMessages.upperCase}
                  </Indicator>
                  <Indicator error={checkError(validationMessages.lowerCase)}>
                    {validationMessages.lowerCase}
                  </Indicator>
                  <Indicator error={checkError(validationMessages.number)}>
                    {validationMessages.number}
                  </Indicator>
                </div>
              </div>
              <div className={styles.submitArea}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={styles.submit}
                  type="submit"
                  disabled={!isValid}
                >
                  {isLoading ? (
                    <div className={styles.circularProgress}>
                      <CircularProgress
                        className={styles.spinner}
                        size={styles.spinner}
                      />
                    </div>
                  ) : (
                    'Alterar senha'
                  )}
                </Button>
              </div>
            </form>
          </div>
        </Container>
      </MuiThemeProvider>
    </div>
  );
};

export default ValidadePassword;
