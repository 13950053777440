import React from 'react';

import { Typography, Tooltip } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import moment from 'moment';

import currencyFormatter from '../../../../utils/currencyFormatter';
import styles from './styles.module.css';

function ResumePlan({
  situationPlan,
  datePlan,
  valuePlanPayment,
  billingMethod,
}) {
  return (
    <div className={styles.resume}>
      <Typography
        className={
          situationPlan ? styles.titleResumeActive : styles.titleResumeInactive
        }
      >
        Resumo detalhado do ponto
      </Typography>
      <div className={styles.containerCards}>
        <div
          className={
            situationPlan
              ? styles.cardSituationPlan
              : styles.cardSituationPlanDisabled
          }
        >
          <Typography className={styles.titleSituation}>
            Situação do plano
          </Typography>
          <Typography
            className={
              situationPlan
                ? styles.subTitleSituation
                : styles.subTitleSituationInactive
            }
          >
            {situationPlan ? 'Ativo' : 'Inativo'}
          </Typography>
        </div>

        <div
          className={
            situationPlan
              ? styles.cardResumeInformation
              : styles.cardResumeInformationDisabled
          }
        >
          <Tooltip
            title="Essa informção é referente a data que foi autorizado
            a contratação do ponto online."
            placement="top"
            arrow
            enterTouchDelay
          >
            <ErrorOutlineIcon className={styles.infoIcon} />
          </Tooltip>
          <Typography
            className={
              situationPlan
                ? styles.titleInformation
                : styles.titleInformationDisabled
            }
          >
            Data da contratação
          </Typography>
          <Typography
            className={
              situationPlan
                ? styles.subTitleInformation
                : styles.subTitleInformationDisabled
            }
          >
            {situationPlan && datePlan
              ? moment(datePlan).format('L')
              : '*nenhuma até o momento'}
          </Typography>
        </div>

        <div className={styles.cardResumeInformation}>
          <Tooltip
            title="Tarifa cobrada por funcionário excedente a
            franquia. Essa tarifa é cobrada apenas uma
            vez dentro do período de vigência do plano."
            placement="top"
            arrow
            enterTouchDelay
          >
            <ErrorOutlineIcon className={styles.infoIcon} />
          </Tooltip>
          <Typography className={styles.titleInformation}>
            Valor cobrado por funcionário
          </Typography>
          <Typography className={styles.subTitleInformation}>
            {valuePlanPayment
              ? currencyFormatter(valuePlanPayment)
              : '*nenhuma até o momento'}
          </Typography>
        </div>

        <div className={styles.cardResumeInformation}>
          <Tooltip
            title="Essa informação é referente a forma de cobrança
            feita dentro do período de vigência do plano."
            placement="top"
            arrow
            enterTouchDelay
          >
            <ErrorOutlineIcon className={styles.infoIcon} />
          </Tooltip>
          <Typography className={styles.titleInformation}>
            Forma de cobrança
          </Typography>
          <Typography className={styles.subTitleInformation}>
            {billingMethod || '*nenhuma até o momento'}
          </Typography>
        </div>
      </div>

      <div className={styles.divider} />
    </div>
  );
}

export default ResumePlan;
