import React from 'react';

import { Button } from '@material-ui/core';

import Title from '../../../components/Title';

import { isMobile } from '../../../utils/breakpoints';

import styles from '../styles.module.css';

function Header({ setOpenModal, chargingActive }) {

  function clickableButton() {
    setOpenModal(true)
  }

  return (
    <>
    {!isMobile && (
      <div className={styles.header}>
        <Title>Meu plano Somapay</Title>
        <Button
          onClick={clickableButton}
          className={styles.headerButton}
          disabled={!chargingActive}
        >
          <span>Fazer upgrade do plano</span>
        </Button>
      </div>
    )}
    </>
  );
}

export default Header;
